import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dis-strain-barcode',
  templateUrl: './dis-strain-barcode.component.html',
  styleUrls: ['./dis-strain-barcode.component.css']
})
export class DisStrainBarcodeComponent implements OnInit {
  public license_no: string;
  public datas: any;
  public for_barcode_data: any;
  public get_data_for_barcode: any;
  public product_title: any;
  public strain_sell_type: any;
  public printQuantity: any;
  public barcodeList = [];
  // public quantity: number = 1;
  public generateBarCode: any;
  public barcodeLabel:any;

  constructor(private productsService: ProductsService,private toastr: ToastrService,) { }

  ngOnInit() {
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    //this.getRandomNumberForBarcode();
    this.datas = localStorage.getItem("for_barcode");
    if (this.datas.length > 0) {
      this.for_barcode_data = JSON.parse(this.datas);
      this.product_title = this.for_barcode_data.product_title;//for show only on ui product title send orginal title to db to fetch data
      this.strain_sell_type = this.for_barcode_data.strain_sell_type;
      console.log("get data from existing page for barcode:", this.for_barcode_data);
      console.log(this.product_title, this.strain_sell_type);
      this.getDataForBarcode();
    }
  }
  getDataForBarcode() {
    let data = {
      license_number: this.license_no,
      title: this.for_barcode_data.title,
      strain_sell_type: this.for_barcode_data.strain_sell_type
    };
    console.log(data);
    this.productsService.getDataForBarcodeGeneration(data).subscribe(res => {
      //console.log(res);
      this.get_data_for_barcode = res;
      console.log("get_unit_from_database_for_barcode", this.get_data_for_barcode);
    });
  }

  generateBarcode(unit, quantity): void {
    console.log(unit, quantity.value);
    this.barcodeList = [];
    if (quantity.value < 1) {
      //console.log("value less than 1");
      this.toastr.error("Please enter quantity at least one");
    } else {
      this.barcodeLabel = this.license_no + "-" + this.product_title + "-" + this.strain_sell_type + "-" + unit;
      this.generateBarCode = Math.floor(Math.random() * 1000000000000);
      for (var i = 0; i < quantity.value; i++) {
        this.barcodeList.push(i);
      }
    }
  }

  printBarcode() {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    console.log(printContents);
    popupWin = window.open('', '_blank');
    // , 'top=0,left=0,height=100%,width=auto'
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
  clearBarcode(){
    this.barcodeList = [];
  }
}
