import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-cus-header',
  templateUrl: './cus-header.component.html',
  styleUrls: ['./cus-header.component.css']
})
export class CusHeaderComponent implements OnInit {

  constructor(private commonService:CommonService,private router: Router) { }

  ngOnInit() {
  }

  logout(){
    this.commonService.destroyToken();
    this.router.navigate(['home']);
  }

}
