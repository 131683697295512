import { Component, OnInit } from '@angular/core';
import { GrayGmapStyleModel } from '../models/gray-gmap-style-model';
import { ContactUsModel } from '../models/contact-us-model';
import { ContactUsService } from '../services/contact-us.service';
import { ToastrService } from 'ngx-toastr';
import { Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  lat: number = 34.2481645;
  lng: number = -118.2866204;
  styles: any[] = GrayGmapStyleModel.grayStyles;
  icon = {
    url: 'assets/img/gmap-img/weedbuys-gmarker.png',
    scaledSize: {
      width: 50,
      height: 50
    }
  };
  previous:any;
  contactUsModel:ContactUsModel={
    username:'',
    email:'',
    subject:'',
    message:''
  }
  constructor(private contactUsService:ContactUsService,private toastrService:ToastrService,private meta: Meta) { 
    this.meta.updateTag({ name: 'keywords', content: 'weedbuys contact,weed,weed buy contact,buy weed,cannabis contact,ganja' });
  }

  ngOnInit() {
  }
  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
  onContactFormSubmit(contactForm){
    console.log(contactForm);
    this.contactUsService.onContactUsDataSubmit(this.contactUsModel).subscribe(data=>{
      console.log(data);
      this.toastrService.success(data['msg']);
      contactForm.resetForm();
    })
  }

}
