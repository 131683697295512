import { Component, OnInit } from '@angular/core';
import { RfqsService } from '../services/rfqs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cus-rfqs',
  templateUrl: './cus-rfqs.component.html',
  styleUrls: ['./cus-rfqs.component.css']
})
export class CusRfqsComponent implements OnInit {
  public check_status:boolean=false;
  public check_winner:boolean=false;
  public cust_id:string;
  public allRfqs:any;
  public winnerChoosen:any;
  public activeRfqsTab = true;
  public endRfqsTab = false;
  public highlightTab: number;
  
  constructor(private rfqsService:RfqsService,private router: Router) { }

  ngOnInit() {
    this.highlightTab = 0;
    this.cust_id=localStorage.getItem('CurrentUserLicense');
    console.log("customer_id",this.cust_id);
    this.getrfqs(this.cust_id);
    this.getWineerChoosen(this.cust_id);

  }

  getrfqs(cust_id){
    this.rfqsService.getCustomerPendingRfqs(cust_id).subscribe(res =>{
      console.log("All_rfqs_for_customer",res);
      this.allRfqs=res;
      //console.log(this.allRfqs[0]);

      if(this.allRfqs.length > 0){
        this.check_status=false;//if array length >0 then show result
        this.allRfqs = res;
      }else{
        this.check_status=true;//otherwise no data found on ui
      }
    });
  }
  getWineerChoosen(cust_id){
    this.rfqsService.getwinnerChoosenForCustomer(cust_id).subscribe(res =>{
      //console.log(res);
      this.winnerChoosen=res;
      console.log("winners_choosen_array",this.winnerChoosen);

      if(this.winnerChoosen.length > 0){
        this.check_winner=false;//if array length >0 then show result
        this.winnerChoosen = res;
      }else{
        this.check_winner=true;//otherwise no data found on ui
      }
    });
  }
  getRemainingTime(database_expire_time) {
    var current_epoch_time = Math.floor(Date.now() / 1000);
    var timeleft = database_expire_time - current_epoch_time;
    return timeleft;
  }

  ActiveTabClicked(a) {
    this.highlightTab = a;
    this.activeRfqsTab = true;
    this.endRfqsTab = false;
  }
  EndTabClicked(b) {
    this.highlightTab = b;
    this.activeRfqsTab = false;
    this.endRfqsTab = true;
  }
  rfqBid(contest_id,title,product_title,quantity,unit,quote_price,rfq_product_image){
    //console.log(product_title);
    var datas = {contest_id: contest_id, title: title,product_title: product_title,quantity: quantity,unit: unit,quote_price: quote_price,rfq_product_image: rfq_product_image}​​​​​​​;
    localStorage.setItem("rfqCotestIdAndTitle", JSON.stringify(datas));
    this.router.navigate(['customer-bid-rfqs']);
  }

}
