import { Component, OnInit, ViewChild } from '@angular/core';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RfqsService } from '../services/rfqs.service';
import { CustomerService } from '../services/customer.service';
import { ProductsService } from '../services/products.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { google } from '@agm/core/services/google-maps-types';
import { GoogleMapService } from '../services/google-map.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Ng2ImgMaxService } from 'ng2-img-max';

declare var $: any;

@Component({
  selector: 'app-rfq-participation',
  templateUrl: './rfq-participation.component.html',
  styleUrls: ['./rfq-participation.component.css']
})
export class RfqParticipationComponent implements OnInit {
 // @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  public sub: any;
  public title: any;
  public product_title: any;
  public quantity: any;
  public unit: any;
  public quote_price: any;
  public rfq_product_image: any;
  public distributor_product_image: any;
  public imageSrc2: any;
  public datas: any;
  public rfq_data: any;
  public timestamp: any;
  public deleted_index: any;
  public diffInHours: any;
  public submitButtonDisabled = false;
  public id: any;
  public remaing_time: any;
  public newDate: any;
  public databaseTime: any;
  public expire_at: any;
  public del_addr1: any;
  public del_addr2: any;
  public del_addr3: any;
  public del_addr4: any;
  public license_no: string;
  public store_details: any;
  public store_name: string;
  public store_address: string;
  public toastMsg: string;
  public deliveryAddress = [];
  public selectedDeliveryAddress: any;
  public rfq_images_array = [];
  public send_distributor_image: any;
  public deliveryOption: any = { value: true };
  public deliveryInformation: any;
  public sendDeliveryAddress: any;
  public coordinatesObj: any = {};
  public storeLat: string;
  public storeLng: string;
  public deliveryAddressLat: string;
  public deliveryAddressLng: string;
  public store_coOrdinates: any;
  public deliveryAddressDistance: any;

  public rfqParticipant: any;
  public individualParticipant: any;
  public imgurl = environment.imageUrl;

  public participantStoreName: any;
  public participantquantity: any;
  public participantunit: any;
  public participantquotePrice: any;
  public participantDeliveyDistance: any;
  public participantProductImage: any;

  public imgDimensions: any;
  public convertDimensions: any;
  uploadedImage: Blob;
  idStoreNameOnSubbmissionTime:boolean=false;
  idStoreNameExceptSubbmissionTime:boolean=true;

  public hideDeliveryDistance: boolean = true;
  public customer_id:string;
  public participantNumberResponse:any;
  public participant_id:any;
  public getContestWinnerResponse:any;
  public winnerDistributorName:any;
  public winnerProductTitle:any;
  public winnerQuantity:any;
  public winnerUnit:any;
  public winnerCustomerQuotePrice:any;
  public winnerDistributorQuotePrice:any;
  public winnerDistributorProductImage:any;
  public imgurl1 = environment.imageUrl;
  public winnerDetails=[];
  public winnerDiv:boolean=true;
  imageSrc1: any = '../assets/winner_cup.png';//by default image
  public contest_id:any;

  constructor(private googleMapService: GoogleMapService, private route: Router, private activatedRoute: ActivatedRoute, private productsService: ProductsService, private modalService: NgbModal, private rfqsService: RfqsService, private customerService: CustomerService, private toastr: ToastrService,private ng2ImgMax: Ng2ImgMaxService) { }

  ngOnInit() {
    this.license_no = localStorage.getItem('CurrentUserLicense');
    this.getStoreDetails(this.license_no);//get store details
    this.deliveryInformation = 'Delivey Available';//by default delivery option
    //this.rfq_product_image = '../assets/pro-image-default.png';//by default image
    this.distributor_product_image = '../assets/pro-image-default.png';//by default image
    this.datas = localStorage.getItem("rfq_product_details");

    if (this.datas.length > 0) {
      this.rfq_data = JSON.parse(this.datas);
      console.log(this.rfq_data);
      //console.log(this.rfq_data.distributor_product_image);
      this.getLastRfqParticipantNumber(this.rfq_data.id);//get last partcipant number
      this.getRfqContestWinner(this.rfq_data.contest_id);//find cotest winner from db if applied
      this.id = this.rfq_data.id;
      this.rfq_product_image = this.imgurl + this.rfq_data.rfq_product_image;
      console.log("test_image_link",this.rfq_product_image);
      if (this.rfq_data.distributor_product_image == "") {
        this.distributor_product_image = 'assets/pro-image-default.png';//by default image
        this.send_distributor_image = "";
        this.rfq_images_array.push("");
      } else {
        this.distributor_product_image = this.imgurl + this.rfq_data.distributor_product_image;
        console.log("test_image_link1",this.distributor_product_image);
        this.rfq_images_array.push(this.rfq_data.distributor_product_image);
        this.send_distributor_image = this.rfq_data.distributor_product_image;
        console.log("test_image_link2",this.send_distributor_image);
      }
      //console.log(this.rfq_images_array);
      this.customer_id=this.rfq_data.customer_id;
      this.contest_id=this.rfq_data.contest_id;
      this.title = this.rfq_data.title;
      this.product_title = this.rfq_data.product_title;
      this.quantity = this.rfq_data.quantity;
      this.unit = this.rfq_data.unit;
      this.quote_price = this.rfq_data.quote_price;
      this.expire_at = this.rfq_data.rfq_expire_at;
      this.del_addr1 = this.rfq_data.del_addr1;
      this.del_addr2 = this.rfq_data.del_addr2;
      this.del_addr3 = this.rfq_data.del_addr3;
      this.del_addr4 = this.rfq_data.del_addr4;
      this.deliveryAddress.push(this.del_addr1, this.del_addr2);
      console.log("title", this.title);
      this.getParicipateDetails();
      //console.log(this.id, this.rfq_product_image, this.rfq_data.distributor_product_image, this.product_title, this.quantity, this.unit, this.quote_price, this.del_addr1, this.del_addr2, this.del_addr3, this.del_addr4, this.expire_at);
      if (this.expire_at == 'Participation time over') {
        this.submitButtonDisabled = true;
      }

    } else {
      console.log("No rfq data");
    }
    this.getLeafDownAnimation();
    //   this.sub = this.activatedRoute.params.subscribe(params => {
    //     // this.id = +params['id']; // (+) converts string 'id' to a number
    //     this.imageSrc1 = "http://192.168.1.72/weedslimapi/public/" + params['image'];
    //     this.product_title = params['product_title'];
    //     this.quantity = params['quantity'];
    //     this.unit = params['unit'];
    //     this.quote_price = params['quote_price'];
    //     console.log(this.imageSrc1, this.product_title,this.quantity,this.unit,this.quote_price);
    //  });  
  }

  // public handleAddressChange(address: Address) {
  //   console.log(address);
  // }
  getLastRfqParticipantNumber(contest_id){
    this.rfqsService.getRfqParicipantNumber(contest_id).subscribe(res=>{
      console.log("participant_id_response",res);
      this.participantNumberResponse=res;
      if(this.participantNumberResponse.status == 400){
        this.participant_id=1;
      }else if(this.participantNumberResponse.status == 200){
       // console.log(this.participantNumberResponse.result);
        let temp=+this.participantNumberResponse.result;
        this.participant_id=temp + 1;
      }else{
        console.log("participation number error occured");
      }
      console.log("participant_id_which_is_send",this.participant_id);
    });
  }
  getRfqContestWinner(contest_id){
    console.log("contest_id",contest_id);
    this.rfqsService.getRfqWinnerDetailsForDistributor(contest_id).subscribe(res=>{
      console.log("Winner_details:",res);
      this.getContestWinnerResponse=res;
      if(this.getContestWinnerResponse.status == 200){
        this.winnerDetails=this.getContestWinnerResponse.data;
        this.winnerDistributorName=this.winnerDetails[0].store_name;
        this.winnerProductTitle=this.winnerDetails[0].product_title;
        this.winnerQuantity=this.winnerDetails[0].quantity;
        this.winnerUnit=this.winnerDetails[0].unit;
        this.winnerCustomerQuotePrice=this.winnerDetails[0].quote_price;
        this.winnerDistributorQuotePrice=this.winnerDetails[0].distributor_quote_price;
        this.winnerDistributorProductImage= this.imgurl1 +  this.winnerDetails[0].distributor_product_image; 
        console.log(this.winnerDetails);
        this.winnerDiv=false;
       }else if(this.getContestWinnerResponse.status == 400){
        this.winnerDiv=true;
      }
    });
  }
  openModal(i) {
    //console.log("modal number", i);
    $("#viewMoreModal").modal('show');
    this.individualParticipant = this.rfqParticipant[i];
    //console.log(this.individualParticipant);
    this.participantProductImage = this.imgurl + this.individualParticipant.distributor_product_image;
    this.participantStoreName = this.individualParticipant.store_name;
    this.participantquantity = this.individualParticipant.quantity;
    this.participantunit = this.individualParticipant.unit;
    this.participantquotePrice = this.individualParticipant.distributor_quote_price;
    if (this.individualParticipant.delivery_address_distance == "") {
      this.hideDeliveryDistance = true;
    } else {
      this.participantDeliveyDistance = this.individualParticipant.delivery_address_distance;
      this.hideDeliveryDistance = false;
    }
    //console.log(this.participantProductImage);
  }

  getParicipateDetails() {
    this.rfqsService.getRfqParicipateDetails(this.contest_id).subscribe(res => {
      console.log("Partcipation details",res);
      this.rfqParticipant = res;
    });
  }

  currentDeliveryInformation() {
    console.log(this.deliveryOption.value);
    if (this.deliveryOption.value == true) {
      this.deliveryInformation = 'Delivey Available';
    } else if (this.deliveryOption.value == false) {
      this.deliveryInformation = 'Delivey Not Available';
    }
  }

  // readImage1(event): void {
  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];

  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.distributor_product_image = reader.result;
  //       this.rfq_images_array.splice(0, 1, e.target.result);
  //       this.send_distributor_image = this.rfq_images_array[0];
  //     }
  //     reader.readAsDataURL(file);
  //   }
  //   console.log("New Image", this.rfq_images_array);

  // }

  getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image()
      i.onload = function () {
        resolved({ w: i.width, h: i.height })
      };
      i.src = file
    })
  }
  imageDimensionValidation(imgDimensions) {
    if (this.imgDimensions.h < 1000) {
      return false;
    } else if (this.imgDimensions.w < 1000) {
      return false;
    } else {
      return true;
    }
  }
  convertImageDimension(imgDimensions) {
    //console.log(Math.min(this.imgDimensions.h, this.imgDimensions.w));
    var minVlaue = Math.min(this.imgDimensions.h, this.imgDimensions.w);
    if (this.imgDimensions.h == minVlaue) {
      this.imgDimensions.h = 1000;
      this.imgDimensions.w = this.imgDimensions.w;
    } else {
      this.imgDimensions.h = this.imgDimensions.h;
      this.imgDimensions.w = 1000;
    }
    //console.log("height:", this.imgDimensions.h, "width:", this.imgDimensions.w);
    return ({ w: this.imgDimensions.w , h: this.imgDimensions.h });
  }
  showImageAcceptError(){
    this.toastr.error("Image height and width must be equal or greater than 1000 * 1000 pixels");
  }
  readImage1(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes",file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:",this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:",dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions=this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:",this.convertDimensions);
          
          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes",this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.distributor_product_image = uploadedImagereader.result;
                this.rfq_images_array.splice(0, 1, uploadedImagereader.result);
                console.log(this.rfq_images_array);
                this.send_distributor_image = this.rfq_images_array[0];
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  removeImage(i) {
    //console.log(i);
    if (i == 0) {
      this.distributor_product_image = '../assets/pro-image-default.png';
      this.rfq_images_array.splice(i, 1, "");
      this.send_distributor_image = this.rfq_images_array[0];
    }
    console.log("Remove Image", this.rfq_images_array);
  }

  getStoreDetails(license_no) {
    this.productsService.getStoreName(license_no).subscribe(res => {
      console.log("store_details", res);
      this.store_details = res;
      this.store_name = this.store_details[0].store_name;
      this.store_address = this.store_details[0].store_address;
      this.store_coOrdinates = JSON.parse(this.store_details[0].store_coordinates);
      console.log("store_address:-", this.store_address);
    });
  }

  ondeliveryAddressChange(event) {
    this.selectedDeliveryAddress = event;
    if (this.store_address == null) {
      console.log("No store address found");
    } else {
      this.getLocation(this.selectedDeliveryAddress);
    }
    //console.log("selectedDeliveryAddress",this.selectedDeliveryAddress);
  }

  getLocation(addr) {
    console.log(addr);//get value from get location button
    this.googleMapService.getCoordinateByname(addr).subscribe(result => {
      this.coordinatesObj = result;
      //console.log(this.coordinatesObj);
      let coordinates = this.coordinatesObj.results[0].geometry.location;
      this.deliveryAddressLat = coordinates.lat;
      this.deliveryAddressLng = coordinates.lng;
      console.log(this.deliveryAddressLat, this.deliveryAddressLng);
      this.deliveryAddressDistance = this.distance(this.store_coOrdinates.lat, this.store_coOrdinates.lng, this.deliveryAddressLat, this.deliveryAddressLng, "M");
      //console.log(Math.floor(this.deliveryAddressDistance));
      this.deliveryAddressDistance = Math.floor(this.deliveryAddressDistance);
    });
  }

  distance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var radlon1 = Math.PI * lon1 / 180
    var radlon2 = Math.PI * lon2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }//kilometers
    if (unit == "N") { dist = dist * 0.8684  }//nautical miles
    if (unit == "M") { dist = dist * 0.8684 * 1.15078}//miles
    return dist;
  }

  refreshParticipationTime() {
    this.rfqsService.getRfqExpireTime(this.id).subscribe(res => {
      console.log(res[0].rfq_expires_at);
      this.expire_at = this.getLeftParicipateTime(res[0].rfq_expires_at);
    });
  }

  getLeftParicipateTime(database_expire_time) {
    var current_epoch_time = Math.floor(Date.now() / 1000);
    var timeleft = database_expire_time - current_epoch_time;
    var leftParticipateTime = this.secondsToHms(timeleft);
    return leftParticipateTime;
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  rfqSort(event) {
    console.log(event.target.value);
    if (event.target.value == "subssiontime") {
      this.idStoreNameOnSubbmissionTime=false;
      this.idStoreNameExceptSubbmissionTime=true;
      this.rfqParticipant.sort(function (obj1, obj2) {
        return obj2.participation_epoch_time - obj1.participation_epoch_time;
      });
      
    } else if (event.target.value == "lowestprice") {
      this.idStoreNameOnSubbmissionTime=true;
      this.idStoreNameExceptSubbmissionTime=false;
      this.rfqParticipant.sort(function (obj1, obj2) {
        return obj1.distributor_quote_price - obj2.distributor_quote_price;
      });
    } else if (event.target.value == "shortestdistance") {
      this.idStoreNameOnSubbmissionTime=true;
      this.idStoreNameExceptSubbmissionTime=false;
      this.rfqParticipant.sort(function (obj1, obj2) {
        return obj1.delivery_address_distance - obj2.delivery_address_distance;
      });
    }
    console.log(this.rfqParticipant);
  }

  routeTodisStoreContent(){
    console.log("here");
    $("#myModal").modal('hide');
    this.route.navigate(['dis-store-content']);
    //  setTimeout(() => {
    //      this.route.navigate(['dis-store-content']);
    //   },
    //      6000);
  }


  rfqParticipate(productTitle, productQuantity, productUnit, productQuotePrice, distributorAddress, deliveryaddressdistance, myPrice) {
    // if (this.store_address == null) {
    //   this.toastr.info('Please add your store address to participate Rfq', 'Info', {
    //     timeOut: 5000
    //   });
    //   setTimeout(() => {
    //     this.route.navigate(['dis-store-content']);
    //   },
    //     6000);
    // } else {
      var current_epoch_time = Math.floor(Date.now() / 1000);
      if (this.deliveryOption.value == false) {
        this.sendDeliveryAddress = "";
        this.deliveryAddressDistance = "";
      } else if (this.deliveryOption.value == true) {
        this.sendDeliveryAddress = this.selectedDeliveryAddress;
      }
      if (myPrice.value == "") {
        this.toastr.error("Please fill all the field");
      } else {
        console.log(this.title, this.send_distributor_image, productTitle.value, productQuantity.value, productUnit.value, productQuotePrice.value, distributorAddress.value, deliveryaddressdistance.value, this.deliveryInformation, this.selectedDeliveryAddress, myPrice.value, current_epoch_time);

        let data = {
          contest_id:this.rfq_data.id,
          participant_id:this.participant_id,
          customer_id:this.customer_id,
          license_number:this.license_no,
          store_name: this.store_name,
          title: this.title,
          product_title: productTitle.value,
          distributor_product_image: this.send_distributor_image,
          quantity: productQuantity.value,
          unit: productUnit.value,
          quote_price: productQuotePrice.value,
          distributor_address: distributorAddress.value,
          delivery_information: this.deliveryInformation,
          delivery_address: this.sendDeliveryAddress,
          delivery_address_distance: deliveryaddressdistance.value,
          distributor_quote_price: myPrice.value,
          participation_epoch_time: current_epoch_time
        };
        console.log(data);

        this.rfqsService.ParicipateRfq(data).subscribe(res => {
          console.log(res);
          this.toastMsg = res['msg'];
          if (res['status'] == "200") {
            this.toastr.success(this.toastMsg);
            //this.getLastRfqParticipantNumber(this.rfq_data.id);//get last partcipant number
            this.getParicipateDetails();
            this.getLastRfqParticipantNumber(this.rfq_data.id);//get last partcipant number
            // setTimeout(() => {
            //   this.route.navigate(['existing']);
            // },
            //   4000);
          }
          else if (res['status'] == "400") {
            this.toastr.error(this.toastMsg);
          }
        });
      }
    //}
  }
  getLeafDownAnimation(){
    var HeartsBackground = {
      heartHeight: 60,
      heartWidth: 64,
      hearts: [],
      heartImage: 'assets/weed_leaf.png',
      maxHearts: 8,
      minScale: 0.4,
      draw: function() {
        this.setCanvasSize();
        this.ctx.clearRect(0, 0, this.w, this.h);
        for (var i = 0; i < this.hearts.length; i++) {
          var heart = this.hearts[i];
          heart.image = new Image();
          heart.image.style.height = heart.height;
          heart.image.src = this.heartImage;
          this.ctx.globalAlpha = heart.opacity;
          this.ctx.drawImage (heart.image, heart.x, heart.y, heart.width, heart.height);
        }
        this.move();
      },
      move: function() {
        for(var b = 0; b < this.hearts.length; b++) {
          var heart = this.hearts[b];
          heart.y += heart.ys;
          if(heart.y > this.h) {
            heart.x = Math.random() * this.w;
            heart.y = -1 * this.heartHeight;
          }
        }
      },
      setCanvasSize: function() {
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
        this.w = this.canvas.width;
        this.h = this.canvas.height;
      },
      initialize: function() {
        this.canvas = $('#canvas')[0];
    
        if(!this.canvas.getContext)
          return;
    
        this.setCanvasSize();
        this.ctx = this.canvas.getContext('2d');
    
        for(var a = 0; a < this.maxHearts; a++) {
          var scale = (Math.random() * (1 - this.minScale)) + this.minScale;
          this.hearts.push({
            x: Math.random() * this.w,
            y: Math.random() * this.h,
            ys: Math.random() + 1,
            height: scale * this.heartHeight,
            width: scale * this.heartWidth,
            opacity: scale
          });
        }
    
        setInterval($.proxy(this.draw, this), 30);
      }
    };
    
    $(document).ready(function(){
      HeartsBackground.initialize();
    });
  }
}
