import { Routes } from "@angular/router";
import { LoginComponent } from "../../login/login.component";
import { ContactComponent } from "src/app/contact/contact.component";
import { ExploreComponent } from "src/app/explore/explore.component";
import { PlacesComponent } from "src/app/places/places.component";
import { PubStrainsComponent } from "src/app/explore/pub-strains/pub-strains.component";
import { PubSingleStrainDetailsComponent } from "src/app/explore/pub-single-strain-details/pub-single-strain-details.component";
import { PubSingleStoreViewComponent } from "src/app/places/pub-single-store-view/pub-single-store-view.component";
import { SearchStoresComponent } from "src/app/search-stores/search-stores.component";
import { PubStrainProdAvailibilityComponent } from "src/app/explore/pub-strain-prod-availibility/pub-strain-prod-availibility.component";
import { PubProductViewComponent } from "src/app/places/pub-product-view/pub-product-view.component";

export const PUBLIC_ROUTES:Routes=[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: LoginComponent},
    { path: 'contact', component: ContactComponent},
    { path: 'explore', component: ExploreComponent},
    { path: 'explore/strains', component: PubStrainsComponent},
    { path: 'explore/:category/:name', component: PubSingleStrainDetailsComponent},
    { path: 'explore/strains/:name/:availability', component: PubStrainProdAvailibilityComponent},
    { path: 'places/:name', component: PlacesComponent},
    { path: 'places/:city/:store', component: PubSingleStoreViewComponent},
    { path: 'places/:category/:id/:productname', component: PubProductViewComponent},
    { path: 'search/:key', component: SearchStoresComponent},
    // { path: '**', component: LoginComponent }
]