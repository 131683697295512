import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Http, Headers, RequestOptions } from "@angular/http";

import {ChangeDetectorRef} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-dis-existing-suppressed',
  templateUrl: './dis-existing-suppressed.component.html',
  styleUrls: ['./dis-existing-suppressed.component.css']
})
export class DisExistingSuppressedComponent implements OnInit {
  public get_suppressed_strains:any;
  public get_suppress_res:any;
  public toastMsg:string;
  public license_no:string;
  public check_status:boolean=false;

  constructor(private http: Http,private changeDetectorRef: ChangeDetectorRef,private toastr: ToastrService,private productsService: ProductsService) { }

  ngOnInit() {
    this.license_no=localStorage.getItem('CurrentUserLicense');
    this.getsuppressedrow();
  }
  getsuppressedrow(){
    this.productsService.getSuppressedRow(this.license_no).subscribe(res => {
      console.log("response_from_db",res);
      if(res['status']!="400"){
        this.check_status=false;
        this.get_suppressed_strains = res;
      }
      else{
        this.check_status=true;
      }
    });   
  }
  getidtitle(i,id,title,productTitle){
    //console.log(this.get_suppressed_strains.length);
    if(this.get_suppressed_strains.length == 1){
      this.check_status=true;
    }
    //console.log(i,id,title);
    this.get_suppressed_strains.splice(i, 1);//remove id from array
    //this.changeDetectorRef.detectChanges();//reload table
    let data = {
      // id:id,
      //title: title,
      product_title:productTitle,
      license_number:this.license_no
    };
    //console.log(data);
    this.productsService.showSuppressRow(data).subscribe(res => {
      this.get_suppress_res = res;
      this.toastMsg=res['msg'];
      this.toastr.info(this.toastMsg);
    });
  }
}
