import { Component, OnInit } from '@angular/core';
import { IntentsService } from '../services/intents.service';


@Component({
  selector: 'app-cus-purchase-history',
  templateUrl: './cus-purchase-history.component.html',
  styleUrls: ['./cus-purchase-history.component.css']
})
export class CusPurchaseHistoryComponent implements OnInit {
  public check_status:boolean=false;
  public cust_id:string;
  public allOrders:any;


  constructor(private intentsService:IntentsService) { }

  ngOnInit() {
    this.cust_id=localStorage.getItem('CurrentUserLicense');
    console.log("customer_id",this.cust_id);
    this.getOrders(this.cust_id);
  }

  getOrders(cust_id){
    this.intentsService.getCustomerPendingOrders(cust_id).subscribe(res =>{
      console.log(res);
      this.allOrders=res;
      //console.log(this.allRfqs[0]);

      if(this.allOrders.length > 0){
        this.check_status=false;//if array length >0 then show result
        this.allOrders = res;
      }else{
        this.check_status=true;//otherwise no data found on ui
      }
    });
  }
  

}
