import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStoreEdible'
})
export class FilterStoreStrainSellType1Pipe implements PipeTransform {
  filteredStoreDetails:any[]=[];
  transform(relatedStoreDetailsArr: any[],edible:string): any {
    this.filteredStoreDetails=[];
    for (var i = 0; i < relatedStoreDetailsArr.length; i++) {
      if (relatedStoreDetailsArr[i].strain_sell_type === edible) {
        this.filteredStoreDetails.push(relatedStoreDetailsArr[i]);
      }
    }
    return this.filteredStoreDetails;
  }

}
