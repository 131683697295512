import { Routes } from "@angular/router";
import { PublicComponent } from "./layouts/public/public.component";
import { SecureComponent } from "./layouts/secure/secure.component";
import { PUBLIC_ROUTES } from "./layouts/public/public-routes";
import { SECURE_ROUTES } from "./layouts/secure/secure-routes";
import { Custom1Component } from "./layouts/custom1/custom1.component";
import { CUSTOM1_ROUTES } from "./layouts/custom1/custom1-routes";




export const APP_ROUTES: Routes = [
    { path: '', component: PublicComponent, data: { title: 'Public Views' }, children: PUBLIC_ROUTES},
    { path: '', component: SecureComponent, data: { title: 'Secure Views' }, children: SECURE_ROUTES },
    { path: '', component: Custom1Component, data: { title: 'Customer Views' }, children: CUSTOM1_ROUTES },
];