import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxPaginationModule } from 'ngx-pagination';
import { ImageZoomModule } from 'angular2-image-zoom';

import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { ExistingComponent } from './existing/existing.component';
import { AddproductComponent } from './addproduct/addproduct.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { FormsModule } from '@angular/forms';
import { ImageUploadModule } from "angular2-image-upload";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ngfModule, ngf } from "angular-file";
import { JwtModule } from '@auth0/angular-jwt';
import { AgmCoreModule } from '@agm/core';

import { CommonModule } from "@angular/common";
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { RecaptchaModule } from 'ng-recaptcha';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './login/login.component';
import { PublicComponent } from './layouts/public/public.component';
import { SecureComponent } from './layouts/secure/secure.component';
import { APP_ROUTES } from './app-routes';
import { DisExistingSuppressedComponent } from './dis-existing-suppressed/dis-existing-suppressed.component';
import { DisHeaderComponent } from './dis-header/dis-header.component';
import { DisEditStrainComponent } from './dis-edit-strain/dis-edit-strain.component';
import { ConfirmValCheckDirective } from './sharedDirectives/confirm-val-check.directive';

import { ValueExistsCheckDirective } from './sharedDirectives/value-exists-check.directive';
import { DisStoreContentComponent } from './dis-store-content/dis-store-content.component';
import { WorkInProgressComponent } from './work-in-progress/work-in-progress.component';
import { PlacesComponent } from './places/places.component';
import { DisAccountComponent } from './dis-account/dis-account.component';
import { ContactComponent } from './contact/contact.component';
import { ExploreComponent } from './explore/explore.component';
import { PubStrainsComponent } from './explore/pub-strains/pub-strains.component';
import { PubSingleStrainDetailsComponent } from './explore/pub-single-strain-details/pub-single-strain-details.component';
import { DisOfficeHoursComponent } from './dis-office-hours/dis-office-hours.component';
import { PubSingleStoreViewComponent } from './places/pub-single-store-view/pub-single-store-view.component';
import { DisFacilitiesComponent } from './dis-facilities/dis-facilities.component';
import { DisStoreLocationComponent } from './dis-store-location/dis-store-location.component';

import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { SearchStoresComponent } from './search-stores/search-stores.component';
import { PubStrainProdAvailibilityComponent } from './explore/pub-strain-prod-availibility/pub-strain-prod-availibility.component';

import { BreadcrumbsModule } from "ng2-breadcrumbs";
import { RouteManagementComponent } from './route-management/route-management.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

import { AgmDirectionModule } from 'agm-direction';   // agm-direction
import { FilterStoreStrainSellTypePipe } from './sharedPipes/filter-store-strain-sell-type.pipe';
import { FilterStoreStrainSellType1Pipe } from './sharedPipes/filter-store-strain-sell-type1.pipe';
import { FilterStoreStrainSellType2Pipe } from './sharedPipes/filter-store-strain-sell-type2.pipe';
import { FilterStoreStrainSellType3Pipe } from './sharedPipes/filter-store-strain-sell-type3.pipe';
import { FilterStoreStrainSellType4Pipe } from './sharedPipes/filter-store-strain-sell-type4.pipe';
import { PubProductViewComponent } from './places/pub-product-view/pub-product-view.component';
import { FilterStrainsByCategoryPipe } from './sharedPipes/filter-strains-by-category.pipe';
import { MondayRouteMapComponent } from './monday-route-map/monday-route-map.component';
import { TuesdayRouteMapComponent } from './tuesday-route-map/tuesday-route-map.component';
import { WednesdayRouteMapComponent } from './wednesday-route-map/wednesday-route-map.component';
import { ThursdayRouteMapComponent } from './thursday-route-map/thursday-route-map.component';
import { FridayRouteMapComponent } from './friday-route-map/friday-route-map.component';
import { SaturdayRouteMapComponent } from './saturday-route-map/saturday-route-map.component';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { CusHeaderComponent } from './cus-header/cus-header.component';
import { CusSidebarComponent } from './cus-sidebar/cus-sidebar.component';
import { CusPurchaseHistoryComponent } from './cus-purchase-history/cus-purchase-history.component';
import { CusRfqsComponent } from './cus-rfqs/cus-rfqs.component';
import { CusDashboardComponent } from './cus-dashboard/cus-dashboard.component';
import { Custom1Component } from './layouts/custom1/custom1.component';
import { DistributorAllRfqsComponent } from './distributor-all-rfqs/distributor-all-rfqs.component';
import { DistributorOrderPendingComponent } from './distributor-order-pending/distributor-order-pending.component';
import { RfqParticipationComponent } from './rfq-participation/rfq-participation.component';
import { CusProfileManagementComponent } from './cus-profile-management/cus-profile-management.component';
import { CusDeliveryAddressComponent } from './cus-delivery-address/cus-delivery-address.component';
import { CountdownTimerModule } from 'ngx-countdown-timer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Error403Component } from './error-layout/error403/error403.component';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';

import { Ng2ImgMaxModule } from 'ng2-img-max';

import {ShareButtonsModule} from 'ngx-sharebuttons';
import { CustomerBidRfqsComponent } from './customer-bid-rfqs/customer-bid-rfqs.component';
import { DisStrainBarcodeComponent } from './dis-strain-barcode/dis-strain-barcode.component';

import { NgxBarcodeModule } from 'ngx-barcode';
import { LandingFooterComponent } from './landing-footer/landing-footer.component';
import { Ng2CompleterModule } from "ng2-completer";
import { DisMyRfqsComponent } from './dis-my-rfqs/dis-my-rfqs.component';
import { DisWonRfqsComponent } from './dis-won-rfqs/dis-won-rfqs.component';
import { DisRfqsStatsComponent } from './dis-rfqs-stats/dis-rfqs-stats.component';
import { CookieService } from 'ngx-cookie-service';

import { ChartsModule } from 'ng2-charts';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    ExistingComponent,
    AddproductComponent,
    LoginComponent,
    PublicComponent,
    SecureComponent,
    DisExistingSuppressedComponent,
    DisHeaderComponent,
    DisEditStrainComponent,
    ConfirmValCheckDirective,
    ValueExistsCheckDirective,
    DisStoreContentComponent,
    WorkInProgressComponent,
    PlacesComponent,
    DisAccountComponent,
    ContactComponent,
    ExploreComponent,
    PubStrainsComponent,
    PubSingleStrainDetailsComponent,
    DisOfficeHoursComponent,
    PubSingleStoreViewComponent,
    DisFacilitiesComponent,
    DisStoreLocationComponent,
    DisFacilitiesComponent,
    SearchStoresComponent,
    PubStrainProdAvailibilityComponent,
    RouteManagementComponent,
    FilterStoreStrainSellTypePipe,
    FilterStoreStrainSellType1Pipe,
    FilterStoreStrainSellType2Pipe,
    FilterStoreStrainSellType3Pipe,
    FilterStoreStrainSellType4Pipe,
    PubProductViewComponent,
    FilterStrainsByCategoryPipe,
    MondayRouteMapComponent,
    TuesdayRouteMapComponent,
    WednesdayRouteMapComponent,
    ThursdayRouteMapComponent,
    FridayRouteMapComponent,
    SaturdayRouteMapComponent,
    CusHeaderComponent,
    CusSidebarComponent,
    CusPurchaseHistoryComponent,
    CusRfqsComponent,
    CusDashboardComponent,
    Custom1Component,
    DistributorAllRfqsComponent,
    DistributorOrderPendingComponent,
    RfqParticipationComponent,
    CusProfileManagementComponent,
    CusDeliveryAddressComponent,
    Error403Component,
    CustomerBidRfqsComponent,
    DisStrainBarcodeComponent,
    LandingFooterComponent,
    DisMyRfqsComponent,
    DisWonRfqsComponent,
    DisRfqsStatsComponent

  ],
  imports: [
    RouterModule.forRoot(APP_ROUTES,{scrollPositionRestoration: 'enabled'}),//scrollPositionRestoration is load a component html page at top position of the page
    Ng2ImgMaxModule,
    NgxBarcodeModule,
    ShareButtonsModule.forRoot(),
    HttpModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    CommonModule,
    NguiAutoCompleteModule,
    GooglePlaceModule,
    BreadcrumbsModule,
    ngfModule,
    RecaptchaModule,
    NgxPaginationModule,
    ImageZoomModule,
    ImageUploadModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ScrollToModule.forRoot(),
    SweetAlert2Module.forRoot(),
    AgmDirectionModule,
    Ng2AutoCompleteModule,
    Ng4LoadingSpinnerModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    JwtModule.forRoot({}),
    AutocompleteLibModule,
    Ng2CompleterModule,
    ChartsModule,
    CountdownTimerModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      timeOut: 3000,
      progressBar: true,
      positionClass: 'toast-top-right',

    }), // ToastrModule added 
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBq9LXuKxmY7uoVoLhr8rnvD_Z6cINcjyY'
    }), ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })  // Angular Google Map API registration
  ],
  providers: [SidebarComponent,CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
