import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { google } from '@agm/core/services/google-maps-types';
import { GoogleMapService } from '../services/google-map.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ProductsService } from '../services/products.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dis-store-location',
  templateUrl: './dis-store-location.component.html',
  styleUrls: ['./dis-store-location.component.css']
})
export class DisStoreLocationComponent implements OnInit {
  public latitude:string;
  public longitude:string;
  coordinatesObj: any = {};
  storeLat: string;
  storeLng: string;
  public selected_address:string;
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  coOrdinates: any = {};
  public license_no: string;
  public toastMsg: string;
  

  constructor(private googleMapService:GoogleMapService,private productsService: ProductsService,private toastr: ToastrService,) { }

  ngOnInit() {
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    this.coOrdinates={"lat":"00.000000", "lng":"00.000000"};
    console.log(this.coOrdinates.lat);
    this.storeLat="00.000000";
    this.storeLng="00.000000";
  //   if(navigator.geolocation){
  //     navigator.geolocation.getCurrentPosition(position => {
  //       //this.location = position.coords;
  //       console.log(position.coords); 
  //     });
  //  }
  }

  public handleAddressChange(address: Address) {
    console.log(address);
  }

  getLocation(addr) {
    console.log(addr.value);//get value from get location button
    this.googleMapService.getCoordinateByname(addr.value).subscribe(result => {
    this.coordinatesObj = result;
    console.log(this.coordinatesObj);
    let coordinates = this.coordinatesObj.results[0].geometry.location;
      this.storeLat = coordinates.lat;
      this.storeLng = coordinates.lng;
      console.log(this.storeLat,this.storeLng);
  });
  }

  submitLocation(){
    this.coOrdinates.lat=this.storeLat;
    this.coOrdinates.lng=this.storeLng;
    console.log(this.coOrdinates);

    let data = {
      coordinates: this.coOrdinates,
      license_number: this.license_no
    };

    console.log("send_datas",data);

    // this.productsService.setStoreLocation(data).subscribe(res => {
    //   console.log(res);
    //   this.toastMsg = res['msg'];
    //   if (res['status'] == "200") {
    //     this.toastr.success(this.toastMsg);
    //   }
    //   else if (res['status'] == "400") {
    //     this.toastr.error(this.toastMsg);
    //   }
    // });
  }
  
  
}


