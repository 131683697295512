import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { ToastrService } from 'ngx-toastr';
//import { google } from '@agm/core/services/google-maps-types';
import { google } from '@google/maps';

declare var google: any;//to get address from lat long  use google variable

@Component({
    selector: 'app-route-management',
    templateUrl: './route-management.component.html',
    styleUrls: ['./route-management.component.css']
})
export class RouteManagementComponent implements OnInit {
    public title: string;
    public lat: any;
    public lng: any;
    public markers = [];
    public license_no: string;
    public store_array: any;
    public toastMsg: string;
    public store_content = {};
    public zoom_level: any;
    public op_close:boolean;

    strainLimitPerView = 9;
    totalStrains: number;
    loadMoreBtnHide: boolean = true;
    LocationDetailsHeadingHide: boolean = true;
    //public formatted_address: string;
    // public days=[];



    icon = {
        url: 'assets/img/gmap-img/flag.png',
        scaledSize: {
            width: 30,
            height: 30
        }
    };

    options = {
        markerOptions: {
            icon: 'assets/img/gmap-img/direction_icon.png',
            scaledSize: {
                width: 50,
                height: 50
            }
        },
    };

    constructor(private productsService: ProductsService, private toastr: ToastrService) { }

    ngOnInit() {
        // (<HTMLInputElement>document.getElementById("LoadMore")).hidden = true;
        this.lat = 34.0201613;
        this.lng = -118.6919308;
        this.zoom_level = 8;
        this.markers = []
        console.log(this.markers);
        this.license_no = localStorage.getItem('CurrentUserLicense');
        console.log("license_no", this.license_no);
        this.getcoOrdinates(this.license_no);
        // this.days=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        this.getSavedLocation();
    }

    getcoOrdinates(license_number) {
        this.productsService.getdisStoreContent(license_number).subscribe(data => {
            console.log("Store_details_to_zoom_on_map",data);
            this.store_array = data;
            if (this.store_array != []) {
                this.store_content = this.store_array[0];

                if (this.store_array[0].store_coordinates != null) {
                    let before_parse = this.store_array[0].store_coordinates;
                    let co_ordinates = JSON.parse(before_parse);
                    //console.log(co_ordinates);
                    this.lat = co_ordinates.lat;
                    this.lng = co_ordinates.lng;
                    this.zoom_level = 14;
                }
                else {
                    this.lat = 34.0201613;
                    this.lng = -118.6919308;
                    this.zoom_level = 8;
                }
            } else {
                console.log("No data found");
            }
        });
    }

    getSavedLocation() {
        let data = {
            day:'drop_location',
            license_number: this.license_no
        };
        this.productsService.getDropLocation(data).subscribe(res => {
            // console.log(res[0].drop_location);
            if (res[0].drop_location != null) {
                this.LocationDetailsHeadingHide=false;
                this.markers = JSON.parse(res[0].drop_location);
                console.log(this.markers);
                
            } else {
                console.log("No drop location saved");  
            }
        });
    }
    // onMouseOverMap(Mapinfo){
    //     Mapinfo.open();
    // }

    mapClicked($event) {
        var lat = $event.coords.lat;
        var lng = $event.coords.lng;
        //console.log($event);
/////////////////get address from lat long and set formatted address
        if (navigator.geolocation) {
            let geocoder = new google.maps.Geocoder();
            let latlng = new google.maps.LatLng(lat, lng);
            let request = { latLng: latlng };

            geocoder.geocode(request, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    let result = results[0];
                    if (result != null) {
                        //console.log(result);
                        this.productsService.for_addr = result.formatted_address;//set value to product service in for_addr varable
                        console.log(this.productsService.for_addr);
                    } else {
                        alert("No address available!");
                    }
                }
            });
        }
////////////////////////this function will called after formatted address is saved
        setTimeout(() => {
            this.markers.splice(this.markers.length ,0,
                {lat: lat,
                lng: lng,
                // label: this.markers.length + 1 + " " + "Drop location",
                formatted_address: this.productsService.for_addr,
                animation: 'DROP'}
            );
            console.log(this.markers);
        },
            800);
            this.LocationDetailsHeadingHide=false;

        this.addRemove(this.markers.length + 1);
    }

    removeMarker(mark,markers_length) {
        console.log(mark);
        this.markers.splice(this.markers.indexOf(mark), 1);//remove from array with index

        if(this.markers.length == 0){
            this.LocationDetailsHeadingHide=true;
        }

        this.addRemove(this.markers.length);
    }

    addRemove(arrLength){
        console.log(arrLength);
        if(arrLength > 8){
            this.totalStrains=arrLength;
            this.loadMoreBtnHide = false;
        }else{
            this.totalStrains=arrLength;
            this.loadMoreBtnHide = true;
        }
    }
    

    onMouseOver(infoWindow, gm, m,i) {
        infoWindow.open();
        //m.animation = 'BOUNCE';
    }

    // markerOver(m) {
    //   m.animation = 'BOUNCE';
    // }

    markerOut(m) {
        m.animation = 'DROP';
    }

    // markerDragEnd(m, $event) {
    //   console.log('dragEnd', m, $event);
    // }

    getValues() {
        let data = {
            day:'drop_location',
            drop_location: this.markers,
            license_number: this.license_no
        };

        console.log("send_datas", data);

        this.productsService.setDropLocation(data).subscribe(res => {
            console.log(res);
            this.toastMsg = res['msg'];
            if (res['status'] == "200") {
                this.toastr.success(this.toastMsg);
            }
            else if (res['status'] == "400") {
                this.toastr.error(this.toastMsg);
            }
        });
    }

    loadMoreAddressDetails() {
        if (this.strainLimitPerView < this.totalStrains) {
          if(this.strainLimitPerView + 5 < this.totalStrains){
            this.strainLimitPerView = this.totalStrains;
            this.loadMoreBtnHide = true;
          }else{
            this.strainLimitPerView = this.strainLimitPerView + 5;
            this.loadMoreBtnHide = false;
          }
        } else if (this.strainLimitPerView == this.totalStrains) {
          this.loadMoreBtnHide = true;
        } else {
          this.strainLimitPerView = this.totalStrains;
          this.loadMoreBtnHide = true;
        }
      }
}



