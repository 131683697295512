import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../services/products.service';
import { GoogleMapService } from '../services/google-map.service';
import { environment } from 'src/environments/environment';
import { GrayGmapStyleModel } from '../models/gray-gmap-style-model';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CompleterItem } from 'ng2-completer';
import { UsersService } from '../services/users.service';
declare var $: any;

@Component({
    selector: 'app-places',
    templateUrl: './places.component.html',
    styleUrls: ['./places.component.css']
})
export class PlacesComponent implements OnInit {
    previous: any;
    lat: number = 51.678418;
    lng: number = 7.809007;
    private sub: any;
    name: string;
    categoryName: string;
    proximityRange:string;
    storeDetails: any = [{}];
    coordinatesObj: any = {};
    storeLat: number;
    storeLng: number;
    imgUrl: string = environment.imageUrl;
    icon = {
        url: 'assets/img/gmap-img/weedbuys-gmarker.png',
        scaledSize: {
            width: 50,
            height: 50
        }
    };
    styles: any[] = GrayGmapStyleModel.grayStyles;
    ph = "Your destinations";
    sc:string = "Select a category";
    sp:string = "Proximity";
    citiesObjArr: any = [];
    citiesObjArr2: any = [];
    selectedPlace: string = "";
    protected searchStr: string;
    searchCategory: string;
    searchProximity: string;
    selectedCategory: any;
    selectedProximity: any;
    categories = [
        // { name: 'All Categories' },
        { name: 'Cannabis Flower' },
        { name: 'Concentrates' },
        { name: 'Cannabis Pre-rolls' },
        { name: 'Cannabis Clones' },
        { name: 'Cannabis Edibles' }
    ];
    proximities = [
        { name: '10 miles' },
        { name: '25 miles' },
        { name: '50 miles' },
        { name: '75 miles' },
        { name: '100 miles' }
    ];

    constructor(private route: ActivatedRoute, private productsService: ProductsService, private googleMapService: GoogleMapService, private router: Router, private spinnerService: Ng4LoadingSpinnerService, private usersService: UsersService) { }

    ngOnInit() {
        this.spinnerService.show();
        this.selectedCategory = this.categories[0];
        this.selectedProximity = this.categories[0];
        this.sub = this.route.params.subscribe(params => {
            this.name = params['name'];
            this.categoryName = sessionStorage.getItem("Category");
            sessionStorage.removeItem("Category");
            this.proximityRange = sessionStorage.getItem("Proximity");
            sessionStorage.removeItem("Proximity");
            this.getAllStoreDetails();
            this.findStoreLocationInMap(this.name);
            this.searchStr = params['name'];
            if (this.categoryName != null) {
                this.sc = this.categoryName;
            }else{
                this.sc = "Select a category";
            }
            console.log(this.sc);
            this.allUsersLocation();
        });
    }

    getAllStoreDetails() {
        this.productsService.getStoreDetailsByCityName(this.name).subscribe(data => {
            console.log(data);
            this.storeDetails = data;
            this.spinnerService.hide();
            console.log(this.storeDetails);
            //setTimeout(function(){
            //   },3000);
        })
    }

    parseJsonLat(str): number {
        let strParse = str;
        if (str != null) {
            var x = JSON.parse(strParse).lat;
            return +x;
        }
    }
    parseJsonLng(str) {
        let strParse = str;
        if (str != null) {
            var y = JSON.parse(strParse).lng;
            return +y;
        }
    }

    // google map customization
    findStoreLocationInMap(location) {
        this.googleMapService.getCoordinateByname(location).subscribe(data => {
            console.log("mapdetails", data);
            this.coordinatesObj = data;
            let coordinates = this.coordinatesObj.results[0].geometry.location;
            this.storeLat = coordinates.lat;
            this.storeLng = coordinates.lng;
        })
    }
    clickedMarker(infowindow) {
        if (this.previous) {
            this.previous.close();
        }
        this.previous = infowindow;
    }
    // End of google map customization
    goToSingleStoreViewPage(place, store_name) {
        this.router.navigate(['/places', place.replace(/\s+/g, '_'), store_name.replace(/\s+/g, '_')]);
    }

    allUsersLocation() {
        console.log(this.searchStr);
        console.log(this.categoryName);
        console.log(this.proximityRange);
        this.usersService.getAllUserCity().subscribe(data => {
            this.citiesObjArr = data;
            this.citiesObjArr2 = [];
            for (let i = 0; i < this.citiesObjArr.length; i++) {
                this.citiesObjArr2.push(this.citiesObjArr[i].cities)
            }
            this.searchStr=this.name;
            // console.log(this.searchStr);
        })
    }

    itemSelected(selected: CompleterItem) {
        let item = selected == null ? "" : selected.title;
        this.searchStr = item;
        console.log(item);
        // let val = this.citiesObjArr2.some(x => x.toLowerCase() === item.toLowerCase())
        // this.selectedPlace = item;
        // if (val == true) {
        //     this.disabled = false;
        // } else {
        //     this.disabled = true;
        // }
    }

    onSelectCategory(selectedCategory) {
        $('#dropdownMenuButton').html(selectedCategory.toUpperCase());
        this.sc = selectedCategory.toUpperCase();
        console.log(this.sc);
    }
    onSelectProximity(selectedProximity) {
        $('#proxyDropdownMenuButton').html(selectedProximity.toUpperCase());
        this.sp = selectedProximity.toUpperCase();
        console.log(this.sp);
    }

    onSearchBtnClick(){
        // console.log(this.searchStr);
        sessionStorage.setItem("Category", this.sc);
        sessionStorage.setItem("Proximity", this.sp);
        this.router.navigate(['/places', this.searchStr]);
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
    // ngAfterViewInit() {
    //     this.spinnerService.hide();
    // }
}
