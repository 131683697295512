import { Component, OnInit } from '@angular/core';
import { RfqsService } from '../services/rfqs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dis-my-rfqs',
  templateUrl: './dis-my-rfqs.component.html',
  styleUrls: ['./dis-my-rfqs.component.css']
})
export class DisMyRfqsComponent implements OnInit {
  public ActiveRfqResult: boolean = true;//by default no data on ui
  public license_no: string;
  public myRfqsResponse: any;
  public myRfqsArray: any = [];
  public activeRfqs: any = [];
  public highlightTab: number;
  public activeRfqsTab = true;
  public endRfqsTab = false;


  public EndRfqResult: boolean = false;
  public allRfqs: any;
  public diffInMs: any;
  public diffInHours: any;
  public endRfqs: any = [];


  constructor(private route: Router, private rfqsService: RfqsService) { }

  ngOnInit() {
    this.highlightTab = 0;
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    this.getDismyRfqs();
  }

  // getDismyRfqs() {
  //   this.rfqsService.getmyRfqsForDistributor(this.license_no).subscribe(res => {
  //     //console.log("myRfqs Response",res);
  //     this.myRfqsResponse = res;
  //     //console.log("myRfqs Array",this.myRfqsResponse.data);
  //     if (this.myRfqsResponse.status == 200) {
  //       if (this.myRfqsResponse.data.length > 0) {
  //         // this.ActiveRfqResult = false;//show data on ui
  //         for (var i = 0; i < this.myRfqsResponse.data.length; i++) {
  //           var diff = this.getRemainingTime(this.myRfqsResponse.data[i].rfq_expires_at);//sort all rfq by expiry time check
  //           // if (diff > 0 && this.myRfqsResponse.data[i].contest_status != "over") {//diff check 3 days and contest status if not over
  //           if (diff > 0 ) {//diff check 3 days and contest status if not over
  //             this.ActiveRfqResult = false;//show data on ui
  //             this.activeRfqs.push(this.myRfqsResponse.data[i]);
  //           }
  //           else {
  //             this.ActiveRfqResult = true;
  //           }
  //         }
  //         console.log("myRfqs Array", this.activeRfqs);
  //       } else {
  //         console.log("myRfqsResponse array length 0");
  //       }
  //     } else if (this.myRfqsResponse.status == 400) {
  //       console.log("some error occured");
  //     }
  //   });
  // }

  getDismyRfqs() {
    this.rfqsService.getmyRfqsForDistributor(this.license_no).subscribe(res => {
      this.myRfqsResponse = res;
      console.log(this.myRfqsResponse);
      if(this.myRfqsResponse.status ==200){
      if (this.myRfqsResponse.data.length > 0) {
        this.allRfqs = this.myRfqsResponse.data;
        console.log("all_rfqs", this.allRfqs);
        for (var i = 0; i < this.allRfqs.length; i++) {
          var diff = this.getRemainingTime(this.allRfqs[i].rfq_expires_at);//sort all rfq by expiry time check
          //console.log(diff);
          if (diff <= 0 || this.allRfqs[i].contest_status == "over") {
            this.endRfqs.push(this.allRfqs[i]);
          } else if (diff > 0 ) {
            this.activeRfqs.push(this.allRfqs[i]);   
          }
        }
        if(this.activeRfqs.length > 0){
          this.ActiveRfqResult = false;//if array length >0 then show result
        }else{
          this.ActiveRfqResult = true;//otherwise no data found on active tab
        }
        //console.log(this.activeRfqs.length);
        if(this.endRfqs.length > 0){
          this.EndRfqResult = false;//if array length >0 then show result
        }else{
          this.EndRfqResult = true;//otherwise no data found on end tab
        }
        //console.log(this.endRfqs.length);
        console.log("active_rfqs", this.activeRfqs);
        console.log("end_rfqs", this.endRfqs);
      } 
      else {
        this.ActiveRfqResult = true;//otherwise no data found on active tab
        this.EndRfqResult = true;//otherwise no data found end tab
      }
    }else if(this.myRfqsResponse.status ==400){
      console.log("some error occured");
      this.ActiveRfqResult = true;//otherwise no data found on active tab
        this.EndRfqResult = true;//otherwise no data found end tab
    }
    });
  }
  ActiveTabClicked(a) {
    this.highlightTab = a;
    this.activeRfqsTab = true;
    this.endRfqsTab = false;
  }
  EndTabClicked(b) {
    this.highlightTab = b;
    this.activeRfqsTab = false;
    this.endRfqsTab = true;
  }

  getRemainingTime(database_expire_time) {
    var current_epoch_time = Math.floor(Date.now() / 1000);
    var timeleft = database_expire_time - current_epoch_time;
    return timeleft;
  }

  getLeftParicipateTime(database_expire_time) {//get remaining time call from ui
    var current_epoch_time = Math.floor(Date.now() / 1000);
    var timeleft = database_expire_time - current_epoch_time;
    var leftParticipateTime = this.secondsToHms(timeleft);//convert remaining time to hour second minute format
    return leftParticipateTime;
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  setValuetoLocalStorage(id, customer_id, distributor_product_image, rfq_product_image, title, product_title, quantity, unit, quote_price, del_addr1, del_addr2, del_addr3, del_addr4, rfq_expire_time) {//save value to local storage call from ui
    if (rfq_expire_time == 0) {
      var patricipationMsg = 'Participation time over';
    } else {
      patricipationMsg = rfq_expire_time;
    }
    var datas = { id: id, contest_id: id, customer_id: customer_id, distributor_product_image: distributor_product_image, rfq_product_image: rfq_product_image, title: title, product_title: product_title, quantity: quantity, unit: unit, quote_price: quote_price, del_addr1: del_addr1, del_addr2: del_addr2, del_addr3: del_addr3, del_addr4: del_addr4, rfq_expire_at: patricipationMsg }​​​​​​​;
    localStorage.setItem("rfq_product_details", JSON.stringify(datas));
    console.log(datas);
    this.route.navigate(['rfq-participation']);
  }

}
