import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cus-sidebar',
  templateUrl: './cus-sidebar.component.html',
  styleUrls: ['./cus-sidebar.component.css']
})
export class CusSidebarComponent implements OnInit {
  public currrentUser:string;
  public dropdownBtnClicked:boolean;

  constructor(private route: Router) { }

  ngOnInit() {
    this.currrentUser = localStorage.getItem("currentUser");
    console.log("Usename:-",this.currrentUser);
    this.dropdownBtnClicked=false;
  }

  showArrowDash(){
    this.dropdownBtnClicked=!this.dropdownBtnClicked;
  }
  routeToProfileManagement(){
    this.route.navigate(['cus-profile-management']);
  }

}
