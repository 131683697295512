import { Component, OnInit } from '@angular/core';
import { RfqsService } from '../services/rfqs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dis-won-rfqs',
  templateUrl: './dis-won-rfqs.component.html',
  styleUrls: ['./dis-won-rfqs.component.css']
})
export class DisWonRfqsComponent implements OnInit {
  public license_no: string;
  public endRfqs: any = [];
  public myRfqsResponse: any;
  public EndRfqResult: boolean = false;

  constructor(private route: Router,private rfqsService: RfqsService) { }

  ngOnInit() {
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    this.getWonRfqForDistributor();
  }
  getWonRfqForDistributor(){
    this.rfqsService.getWonRfqForDistributor(this.license_no).subscribe(res => {
      // console.log(res);
      this.myRfqsResponse = res;
      console.log("won_rfq_response:",this.myRfqsResponse);
      if(this.myRfqsResponse.status ==200){
        if (this.myRfqsResponse.data.length > 0) {
          this.endRfqs=this.myRfqsResponse.data;
        }else if(this.myRfqsResponse.status ==400){
          console.log(this.myRfqsResponse.msg);
          this.EndRfqResult = true;//otherwise no data found on end tab
        }
      }else{
        console.log("some error occured");
        this.EndRfqResult = true;//otherwise no data found on end tab
      }
    });
  }
  setValuetoLocalStorage(id, customer_id, distributor_product_image, rfq_product_image, title, product_title, quantity, unit, quote_price, del_addr1, del_addr2, del_addr3, del_addr4, rfq_expire_time) {//save value to local storage call from ui
    if (rfq_expire_time == 0) {
      var patricipationMsg = 'Participation time over';
    } else {
      patricipationMsg = rfq_expire_time;
    }
    var datas = { id: id, contest_id: id, customer_id: customer_id, distributor_product_image: distributor_product_image, rfq_product_image: rfq_product_image, title: title, product_title: product_title, quantity: quantity, unit: unit, quote_price: quote_price, del_addr1: del_addr1, del_addr2: del_addr2, del_addr3: del_addr3, del_addr4: del_addr4, rfq_expire_at: patricipationMsg }​​​​​​​;
    localStorage.setItem("rfq_product_details", JSON.stringify(datas));
    console.log(datas);
    this.route.navigate(['rfq-participation']);
  }

}
