import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RfqsService } from '../services/rfqs.service';

import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-dis-rfqs-stats',
  templateUrl: './dis-rfqs-stats.component.html',
  styleUrls: ['./dis-rfqs-stats.component.css']
})
export class DisRfqsStatsComponent implements OnInit {
  public ActiveRfqResult: boolean = true;//by default no data on ui
  public license_no: string;
  public partcipateRfqsResponse: any;
  public wonRfqsResponse: any;
  public wonRfqs: any;
  public myRfqsArray: any = [];
  public activeRfqs: any = [];
  public highlightTab: number;
  public activeRfqsTab = true;
  public endRfqsTab = false;
  public EndRfqResult: boolean = false;
  public allRfqs: any;
  public diffInMs: any;
  public diffInHours: any;
  public endRfqs: any = [];
  public chartPrepare:boolean=false;

  public partcipateWonArray = [{ data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Participate' },
  { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Won' }];

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels :Label[]= ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartArray = [];
  //public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Participate' },
    { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Won' }
  ];

  constructor(private route: Router, private rfqsService: RfqsService) { }

  ngOnInit() {
    var winLossChart = document.getElementById('winLossChart');
    //console.log(this.partcipateArray[1].data[0]);
    //console.log(this.graphArray[1].won);
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    this.getDismyRfqs();
    this.getWonRfqForDistributor();
  }
  getDismyRfqs() {
    this.rfqsService.getmyRfqsForDistributor(this.license_no).subscribe(res => {
      this.partcipateRfqsResponse = res;
      console.log("partcipateRfqsResponse", this.partcipateRfqsResponse);
      if (this.partcipateRfqsResponse.status == 200) {
        if (this.partcipateRfqsResponse.data.length > 0) {
          this.allRfqs = this.partcipateRfqsResponse.data;
          console.log("all_rfqs", this.allRfqs);
          for (var i = 0; i < this.allRfqs.length; i++) {
            var d = new Date(this.allRfqs[i].timestamp * 1000);
            // var dt=d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
            var dt = d.getDate() + '/' + d.getMonth() + '/' + d.getFullYear();
            //console.log("date month 0 means jan and 11 means dec",dt);
            if (d.getMonth() == 0) {//0 for jan and 11 for dec
              this.partcipateWonArray[0].data[0] += 1;
            } else if (d.getMonth() == 1) {
              this.partcipateWonArray[0].data[1] += 1;
            } else if (d.getMonth() == 2) {
              this.partcipateWonArray[0].data[2] += 1;
            } else if (d.getMonth() == 3) {
              this.partcipateWonArray[0].data[3] += 1;
            } else if (d.getMonth() == 4) {
              this.partcipateWonArray[0].data[4] += 1;
            } else if (d.getMonth() == 5) {
              this.partcipateWonArray[0].data[5] += 1;
            } else if (d.getMonth() == 6) {
              this.partcipateWonArray[0].data[6] += 1;
            } else if (d.getMonth() == 7) {
              this.partcipateWonArray[0].data[7] += 1;
            } else if (d.getMonth() == 8) {
              this.partcipateWonArray[0].data[8] += 1;
            } else if (d.getMonth() == 9) {
              this.partcipateWonArray[0].data[9] += 1;
            } else if (d.getMonth() == 10) {
              this.partcipateWonArray[0].data[10] += 1;
            } else if (d.getMonth() == 11) {
              this.partcipateWonArray[0].data[11] += 1;
            }
          }
          console.log("partcipateWonArray after partcipation",this.partcipateWonArray);
          const clone = JSON.parse(JSON.stringify(this.partcipateWonArray));
          this.barChartData = clone;//show final participate and own data on ui
          this.chartPrepare=true;
        }
      } else if (this.partcipateRfqsResponse.status == 400) {
        console.log("some error occured");
        this.ActiveRfqResult = true;//otherwise no data found on active tab
        this.EndRfqResult = true;//otherwise no data found end tab
      }
    });
  }


  // getRemainingTime(database_expire_time) {
  //   var current_epoch_time = Math.floor(Date.now() / 1000);
  //   var timeleft = database_expire_time - current_epoch_time;
  //   return timeleft;
  // }


  getWonRfqForDistributor() {
    this.rfqsService.getWonRfqForDistributor(this.license_no).subscribe(res => {
      // console.log(res);
      this.wonRfqsResponse = res;
      console.log("won_rfq_response:", this.wonRfqsResponse);
      if (this.wonRfqsResponse.status == 200) {
        if (this.wonRfqsResponse.data.length > 0) {
          this.wonRfqs = this.wonRfqsResponse.data;
          for (var i = 0; i < this.wonRfqs.length; i++) {
            var d = new Date(this.wonRfqs[i].contest_end_date * 1000);
            var dt = d.getDate() + '/' + d.getMonth() + '/' + d.getFullYear();
            //console.log(dt);
            if (d.getMonth() == 0) {
              this.partcipateWonArray[1].data[0] += 1;
            } else if (d.getMonth() == 1) {
              this.partcipateWonArray[1].data[1] += 1;
            } else if (d.getMonth() == 2) {
              this.partcipateWonArray[1].data[2] += 1;
            } else if (d.getMonth() == 3) {
              this.partcipateWonArray[1].data[3] += 1;
            } else if (d.getMonth() == 4) {
              this.partcipateWonArray[1].data[4] += 1;
            } else if (d.getMonth() == 5) {
              this.partcipateWonArray[1].data[5] += 1;
            } else if (d.getMonth() == 6) {
              this.partcipateWonArray[1].data[6] += 1;
            } else if (d.getMonth() == 7) {
              this.partcipateWonArray[1].data[7] += 1;
            } else if (d.getMonth() == 8) {
              this.partcipateWonArray[1].data[8] += 1;
            } else if (d.getMonth() == 9) {
              this.partcipateWonArray[1].data[9] += 1;
            } else if (d.getMonth() == 10) {
              this.partcipateWonArray[1].data[10] += 1;
            } else if (d.getMonth() == 11) {
              this.partcipateWonArray[1].data[11] += 1;
            }
          }
          console.log("partcipateWonArray after won",this.partcipateWonArray);
          const clone = JSON.parse(JSON.stringify(this.partcipateWonArray));
          this.barChartData = clone;//show final participate and own data on ui
          this.chartPrepare=true;

        } else if (this.wonRfqsResponse.status == 400) {
          console.log(this.wonRfqsResponse.msg);
          this.EndRfqResult = true;//otherwise no data found on end tab
        }
      } else {
        // /console.log("some error occured");
        this.EndRfqResult = true;//otherwise no data found on end tab
      }
    });
  }
}
