import { Injectable } from '@angular/core';
import { RequestOptions,Headers, Http } from '@angular/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private http: Http) { }
  public onContactUsDataSubmitUrl= environment.apiBaseUrl + "/contact_us";
  
  onContactUsDataSubmit(contactUsData){
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(contactUsData);
    return this.http.post(this.onContactUsDataSubmitUrl, body, options).pipe(map((res: Response) => res.json()));
  }

}
