import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom1',
  templateUrl: './custom1.component.html',
  styleUrls: ['./custom1.component.css']
})
export class Custom1Component implements OnInit {
  // template: string = `<img src="assets/loader.gif">`;
  constructor() { }

  ngOnInit() {
  }
  getUserType() {
    return atob(localStorage.getItem('UD'));
  };

}
