import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleMapService } from '../services/google-map.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-search-stores',
  templateUrl: './search-stores.component.html',
  styleUrls: ['./search-stores.component.css'],
})
export class SearchStoresComponent implements OnInit {

  searchedKey: string;
  private sub: any;
  pageNumber: number = 1;
  allSearchedItems: any = {};
  searchedItems: any = [];
  finalSearchedItems: any = [];
  p: number = 1;
  
  constructor(private route: ActivatedRoute, private googleMapService: GoogleMapService, private router: Router,private spinnerService:Ng4LoadingSpinnerService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    // this.spinnerService.show();
    this.sub = this.route.params.subscribe(params => {
      this.searchedKey = params['key'];
      this.getResultByQueryString(this.searchedKey);
      this.spinnerService.hide();
    });

  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getResultByQueryString(s) {
    for (let i = 1; i <= 10; i++) {
      this.googleMapService.getSearchResult(s, i).subscribe(data => {
        this.allSearchedItems = data;
        this.searchedItems = this.allSearchedItems.items;
        if (this.searchedItems) {
          for (let j = 0; j < this.searchedItems.length; j++) {
            this.finalSearchedItems.push(this.searchedItems[j]);
          }
        }
      });
    }
    console.log(this.finalSearchedItems);
    
  }
}
