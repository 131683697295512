import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-secure',
  templateUrl: './secure.component.html',
  styleUrls: ['./secure.component.css']
})
export class SecureComponent implements OnInit {
  template: string = `<img src="assets/loader.gif">`;
  constructor() { }

  ngOnInit() {
    
  }
  getUserType() {
    return atob(localStorage.getItem('UD'));
  };

}
