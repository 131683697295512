import { Component, OnInit, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, ɵConsole, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { RaiseIntentModel } from 'src/app/models/raise-intent-model';
import { RfqsService } from 'src/app/services/rfqs.service';
import { RaiseRfqModel } from 'src/app/models/raise-rfq-model';
import { IntentsService } from 'src/app/services/intents.service';
import { CustomerService } from 'src/app/services/customer.service';
import { GrayGmapStyleModel } from 'src/app/models/gray-gmap-style-model';
import { Meta, Title } from '@angular/platform-browser';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { environment } from 'src/environments/environment';
import { RfqParticipationModel } from 'src/app/models/rfq-participation-model';

declare var $: any;

@Component({
  selector: 'app-pub-product-view',
  templateUrl: './pub-product-view.component.html',
  styleUrls: ['./pub-product-view.component.css']
})
export class PubProductViewComponent implements OnInit {
  storeId: number;
  category: string;
  productTitle: string;
  private sub: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  numericRegex = "^[0-9]*$";
  lat: number = 40.6976701;
  lng: number = -74.2598737;
  products: any = {};
  storeInfo: any = [];
  storeName: string = '';
  placeName: string = '';
  licenceNo: string = '';
  productImgs = [];
  selectedItem: boolean;
  firstStoreId: string = '';
  reletedGroupedProducts: string[] = [];
  selectedProd: any = {};
  width: number = 300;
  height: number = 400;
  imgUrl = environment.imageUrl;
  myThumbnail = "";
  selectedProductImg = "";
  storeFacilities: any = [];
  scrollTopBtnEnable: boolean = false;
  raiseRFQ: RaiseRfqModel = {
    license: '',
    store_name: '',
    user_id: '',
    username: '',
    email: '',
    address: '',
    phone: null,
    prod_img: '',
    product_id: '',
    title: '',
    prodTitle: '',
    primaryDelAddr: '',
    secondaryDelAddr: '',
    quantity: '',
    unit: '',
    price: '',
    createdAt: 0,
    expiresAt: 0
  }
  raiseIntent: RaiseIntentModel = {
    store_license: '',
    store_name: '',
    user_id: '',
    username: '',
    email: '',
    phone: '',
    title: '',
    prodTitle: '',
    unit: '',
    price: '',
    timestamp: null
  }
  rfqParticipationModel: RfqParticipationModel = {
    customer_id: '',
    product_id: ''
  }
  zoomWindowPosition: number = 2;
  windowWidth: any;
  onRFQBtnClicked: boolean = false;
  responseRFQ: any = {};
  raiseIntentBtnClicked: boolean = false;
  rfqBtnDisable: boolean = false;
  intentBtnDisable: boolean = false;
  intentSubmitted: any = {};
  customerdetails: any = [];
  customerdetails2: any = [];
  title: string = '';
  dropLocationsData: any = [{}];
  days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]
  dropDays = [];
  selectedDay: number = 0;
  selectedDropDayCoordinates: any = [];
  icon = {
    url: 'assets/img/gmap-img/weedbuys-gmarker.png',
    scaledSize: {
      width: 50,
      height: 50
    }
  };
  previous;
  styles: any = GrayGmapStyleModel.grayStyles;
  similarProducts: any = [];
  public repoUrl = 'http://weedbuys.com/places/';
  storeDetails: any = [{}];
  timings: any = [{}];
  storeCoordinates: any = {};
  deliveryAddressArr = [];
  containsVal: boolean = false;
  containsValSecondary: boolean = false;
  customerParticipationDetails: any = [];
  intentStatus: any = {};
  tickSymbol: any = {};

  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (window.pageYOffset > 400) {
      this.scrollTopBtnEnable = true;
    }
    else {
      this.scrollTopBtnEnable = false;
    }
  }

  @HostListener('window:resize', ['$event']) //Detect window screen size 
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    // console.log(event.target.innerWidth);
    if (this.windowWidth === 991 || this.windowWidth < 991) { // 991px window width
      this.zoomWindowPosition = 7;
    } else {
      this.zoomWindowPosition = 1;
    }
  }
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  constructor(private route: ActivatedRoute, private productsService: ProductsService, private router: Router, private _scrollToService: ScrollToService, private commonService: CommonService, private toastrService: ToastrService, private rfqsService: RfqsService,
    private intentsService: IntentsService, private customerService: CustomerService, private meta: Meta, public metaTitle: Title) {
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.storeId = +params['id'];
      this.category = params['category'];
      this.productTitle = params['productname'];
      this.productTitle = this.productTitle.replace(/_/g, ' ');
      // console.log(this.storeId, this.category, this.productTitle.replace(/\s+/g, '-'));
      console.log(this.productTitle);
      console.log("raiseIntent model", this.raiseIntent);

      this.productImgs = [];
      this.reletedGroupedProducts = [];
      this.getALLRelatedProduct();
      this.singleStoreInfo();
      if (atob(sessionStorage.getItem('UD')) == "customer") {
        this.getCustomerInfoById(localStorage.getItem('CurrentUserLicense'));
        this.customerInfo2(localStorage.getItem('CurrentUserLicense'))
      }
      const config: ScrollToConfigOptions = {
        target: 'singleStorePagetop1'
      };
      this._scrollToService.scrollTo(config);
      this.raiseRFQ.prodTitle = this.productTitle ? this.productTitle : "";
    });
    $('#mixedSlider').multislider({
      continuous: false,
      slideAll: false,
      interval: false,
      duration: 750,
      hoverPause: true,
      pauseAbove: null,
      pauseBelow: null
    });
  }
  getALLRelatedProduct() {
    this.productsService.getAllProductByIdName(this.storeId, this.productTitle).subscribe(data => {
      this.products = data;
      if (this.products.length > 0) {
        for (let i = 0; i < this.products.length; i++) {
          this.reletedGroupedProducts.push(this.products[i]);
          this.firstStoreId = this.products[0].store_id;
        }
        if (this.products[0].image != '') {
          this.myThumbnail = this.imgUrl + this.products[0].image;
          this.productImgs.push(this.products[0].image);
          this.productImgs.push(this.products[0].image2 != '' ? this.products[0].image2 : "strainImage/pro-image-default.png");
          this.productImgs.push(this.products[0].image3 != '' ? this.products[0].image3 : "strainImage/pro-image-default.png");
          this.productImgs.push(this.products[0].image4 != '' ? this.products[0].image4 : "strainImage/pro-image-default.png");
          this.productImgs.push(this.products[0].image5 != '' ? this.products[0].image5 : "strainImage/pro-image-default.png");
          this.productImgs.push(this.products[0].image6 != '' ? this.products[0].image6 : "strainImage/pro-image-default.png");
          this.productImgs = this.productImgs.filter(item => item != "strainImage/pro-image-default.png");
          this.selectedProductImg = this.products[0].image;
          this.fcbkMetaUpdate();
          this.twitterMetaUpdate();
        } else {
          this.myThumbnail = "assets/pro-image-default.png";
          this.selectedProductImg = this.myThumbnail;
        }
      }
      this.onSelectUnit(0, this.firstStoreId, this.products[0]);
      console.log(this.selectedProductImg);
      // console.log(this.products[0].description);
    })

  }
  onImageSelect(thumbImg) {
    this.selectedProductImg = thumbImg;
    this.myThumbnail = this.imgUrl + thumbImg;
    console.log(this.selectedProductImg);
    console.log(this.myThumbnail);
  }
  onSelectUnit(index, storeId, newValue) {
    this.selectedProd = this.reletedGroupedProducts[index];
    this.selectedItem = newValue;
    this.productsService.getDistributorInfoById(storeId).subscribe(data => {
      console.log("data", data);
      console.log("product", this.selectedProd);
      this.storeInfo = data;
      this.licenceNo = this.storeInfo[0].license_number;
      this.storeName = this.storeInfo[0].store_name;
      this.placeName = this.storeInfo[0].place;
      this.title = this.selectedProd.title;
      this.getDropLocations(this.licenceNo);
      this.raiseRFQ.title = this.title ? this.title : "";
      this.raiseRFQ.product_id = this.selectedProd.id;
      this.rfqParticipationModel.product_id = this.selectedProd.id;
      if (this.storeInfo[0].facilities != "") {
        this.storeFacilities = JSON.parse(this.storeInfo[0].facilities);
        console.log(this.storeFacilities);
      }
      this.getSimilarProducts(this.title);
      if (localStorage.getItem("CurrentToken") != null) {
        this.intentAlreadyRaisedCheckForTick();
      }
    })
  }
  onStoreNameClick() {
    this.router.navigate(['/places', this.placeName, this.storeName.replace(/\s+/g, '_')]);
  }
  public handleAddressChange(address: Address) {
    console.log(address.address_components);
    if ($('#placesRef').val() != null || $('#placesRef').val() != '') {
      this.containsVal = true;
    }
  }
  public handleAddressChange1(address: Address) {
    console.log(address.address_components);
    if ($('#placesRef1').val() != null || $('#placesRef1').val() != '') {
      this.containsValSecondary = true;
    }
  }
  focusOutFunction(e) {
    if ($('#placesRef').val() == null || $('#placesRef').val() == '') {
      this.containsVal = false;
    } else {
      this.containsVal = true;
    }
    console.log($('#placesRef').val());
  }
  focusOutFunctionSecondary(e) {
    if ($('#placesRef1').val() == null || $('#placesRef1').val() == '') {
      this.containsValSecondary = false;
    } else {
      this.containsValSecondary = true;
    }
    console.log($('#placesRef1').val());
  }
  onRaiseRFQ(rfqForm) {
    console.log(rfqForm);
    this.onRFQBtnClicked = true;
    console.log(this.raiseRFQ)
    this.raiseRFQ.license = this.licenceNo;
    this.raiseRFQ.store_name = this.storeName;
    this.raiseRFQ.prod_img = this.selectedProductImg;
    this.raiseRFQ.createdAt = Math.floor(Date.now() / 1000);
    this.raiseRFQ.expiresAt = this.raiseRFQ.createdAt + 259200;
    if (this.deliveryAddressArr.length == 0) {
      if (this.containsVal == true && this.containsValSecondary == true) {
        this.raiseRFQ.primaryDelAddr = $('#placesRef').val();
        this.raiseRFQ.secondaryDelAddr = $('#placesRef1').val();
        this.rfqsService.submitRFQ(this.raiseRFQ).subscribe(data => {
          console.log(data);
          this.responseRFQ = data;
          if (this.responseRFQ.status == 200) {
            this.onRFQBtnClicked = false;
            $(document).ready(function () {
              $('#price').val(null);
              $('#unit').val(null);
              $('#quantity').val(null);
              $('#primaryDelAddr').val(null);
              $('#secondaryDelAddr').val(null);
              $('#quantity').val(null);
              $('#unit').val(null);
              $('#price').val(null);
              $('#raiseRFQ').modal('hide');
              // rfqForm.reset();
            });
            this.toastrService.success(this.responseRFQ.msg);
            this.raiseRFQ = {
              license: '',
              store_name: '',
              user_id: '',
              username: '',
              email: '',
              address: '',
              phone: null,
              prod_img: '',
              product_id: '',
              title: this.title ? this.title : "",
              prodTitle: this.productTitle ? this.productTitle : "",
              primaryDelAddr: '',
              secondaryDelAddr: '',
              quantity: '',
              unit: '',
              price: '',
              createdAt: 0,
              expiresAt: 0
            };

          }
        });
      } else {
        this.onRFQBtnClicked = false;
      }
    } else {
      this.rfqsService.submitRFQ(this.raiseRFQ).subscribe(data => {
        console.log(data);
        this.responseRFQ = data;
        if (this.responseRFQ.status == 200) {
          this.onRFQBtnClicked = false;
          $(document).ready(function () {
            $('#price').val(null);
            $('#unit').val(null);
            $('#quantity').val(null);
            $('#raiseRFQ').modal('hide');
            // rfqForm.reset();
          });
          this.toastrService.success(this.responseRFQ.msg);
          this.raiseRFQ = {
            license: '',
            store_name: '',
            user_id: '',
            username: '',
            email: '',
            address: '',
            phone: null,
            prod_img: '',
            product_id: '',
            title: this.title ? this.title : "",
            prodTitle: this.productTitle ? this.productTitle : "",
            primaryDelAddr: '',
            secondaryDelAddr: '',
            quantity: '',
            unit: '',
            price: '',
            createdAt: 0,
            expiresAt: 0
          };

        }
      });
    }
    let deliveryAddresses = {
      id: this.raiseRFQ.user_id,
      primary: this.raiseRFQ.primaryDelAddr,
      secondary: this.raiseRFQ.secondaryDelAddr
    }
    console.log(deliveryAddresses)
    if (this.deliveryAddressArr.length == 0) {
      if (this.containsVal == true && this.containsValSecondary == true) {
        this.rfqsService.updateCustomersDeliveryAddr(deliveryAddresses).subscribe(data => {
          console.log(data);
        })
      }
    } else {
      this.rfqsService.updateCustomersDeliveryAddr(deliveryAddresses).subscribe(data => {
        console.log(data);
      })
    }
  }

  onRaiseIntentBtnClick() {
    console.log(localStorage.getItem('CurrentUserLicense'));
    console.log(this.licenceNo);
    if (this.commonService.tokenExist() == true) {
      if (atob(sessionStorage.getItem('UD')) == "customer") {
        let customerID = localStorage.getItem('CurrentUserLicense');
        this.intentsService.getIntentAlreadyRaisedCheck(customerID, this.title, this.licenceNo).subscribe(data1 => {
          this.intentStatus = data1;
          console.log(this.intentStatus);
          if (this.intentStatus == 'allow') {
            this.setIntentData();
            this.raiseIntentBtnClicked = true;
            console.log("info1", this.raiseIntent)
            setTimeout(() => {
              this.intentsService.submitIntent(this.raiseIntent).subscribe(data => {
                console.log(data);
                this.tickSymbol = "discard";
                this.intentSubmitted = data;
                if (this.intentSubmitted.status = 200) {
                  this.toastrService.success(this.intentSubmitted.msg);
                  console.log("Response", this.raiseIntent)
                }
                else {
                  this.toastrService.error(this.intentSubmitted.msg);
                }
              })
            },
              5000);
          } else {
            this.toastrService.error("You cannot raise intent for purchase on the same product twice in a day.");
          }
        })
      } else {
        this.toastrService.error('You cannot raise intent for purchase. It\'s only for customers.');
      }
    } else {
      this.toastrService.error('You have to login first!');
    }
  }
  onRaiseRFQBtnClick() {
    if (this.commonService.tokenExist() == true) {
      if (atob(sessionStorage.getItem('UD')) == "customer") {
        this.rfqsService.customerRfqParticipationCheck(this.rfqParticipationModel).subscribe(data => {
          this.customerParticipationDetails = data;
          console.log(this.customerParticipationDetails.status);
          if (this.customerParticipationDetails.status == "200") {
            var current_epoch_time = Math.floor(Date.now() / 1000);
            console.log(this.customerParticipationDetails.expireContestTime)
            var getDifference = this.customerParticipationDetails.expireContestTime - current_epoch_time;
            console.log("difference", getDifference);
            if (getDifference < 0) {
              this.getCustomerInfoById(localStorage.getItem('CurrentUserLicense'));
              $(document).ready(function () {
                $('#raiseRFQ').modal('show');
              });
              this.raiseRFQ.username = localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : "";
            } else {
              this.toastrService.error('You have already raised RFQ on this product.')
            }
          } else {
            this.getCustomerInfoById(localStorage.getItem('CurrentUserLicense'));
            $(document).ready(function () {
              $('#raiseRFQ').modal('show');
            });
            this.raiseRFQ.username = localStorage.getItem('currentUser') ? localStorage.getItem('currentUser') : "";
          }
        })

      } else {
        this.toastrService.error('You cannot raise intent for purchase. It\'s only for customers.')
      }
    } else {
      this.toastrService.error('You have to login first!');
    }
  }
  getCustomerInfoById(customerId) {
    this.customerInfo(customerId);
    console.log("customerdetails", this.customerdetails);
  }

  customerInfo(customerId) {
    this.customerService.getCustomerInfo(customerId).subscribe(data => {
      this.customerdetails = data[0];
      this.raiseRFQ.user_id = this.customerdetails.id;
      this.raiseRFQ.email = this.customerdetails.email;
      this.raiseRFQ.address = this.customerdetails.address;
      this.raiseRFQ.phone = this.customerdetails.phone_number;
      this.rfqParticipationModel.customer_id = this.customerdetails.id;
      this.deliveryAddressArr = [];
      console.log(this.customerdetails)
      if (this.customerdetails.del_addr1 != ('' || null))
        this.deliveryAddressArr.push(this.customerdetails.del_addr1);
      if (this.customerdetails.del_addr2 != ('' || null))
        this.deliveryAddressArr.push(this.customerdetails.del_addr2);
      if (this.customerdetails.del_addr3 != ('' || null))
        this.deliveryAddressArr.push(this.customerdetails.del_addr3);
      if (this.customerdetails.del_addr4 != ('' || null))
        this.deliveryAddressArr.push(this.customerdetails.del_addr4);
      console.log(this.deliveryAddressArr)
    })
  }
  async setIntentData() {
    // console.log(localStorage.getItem('CurrentUserLicense'));
    this.customerInfo2(localStorage.getItem('CurrentUserLicense'));
    setTimeout(() => {
      this.raiseIntent.store_license = this.licenceNo;
      this.raiseIntent.store_name = this.storeName;
      this.raiseIntent.username = localStorage.getItem('currentUser');
      this.raiseIntent.user_id = this.customerdetails2.id;
      this.raiseIntent.email = this.customerdetails2.email;
      this.raiseIntent.phone = this.customerdetails2.phone_number;
      this.raiseIntent.title = this.title;
      this.raiseIntent.prodTitle = this.selectedProd.product_title;
      this.raiseIntent.unit = this.selectedProd.unit;
      this.raiseIntent.price = this.selectedProd.price;
      let time =  Math.floor(Date.now() / 1000);
      this.raiseIntent.timestamp = time;
      console.log(this.raiseIntent.user_id);
    },
      2000);
  }

  customerInfo2(customerId) {
    this.customerService.getCustomerInfo(customerId).subscribe(data => {
      this.customerdetails2 = data[0];
      console.log(this.customerdetails2);
      this.intentsService.uid = this.customerdetails2.id;
      return;
    })
  }
  singleStoreInfo() {
    this.productsService.getDistributorInfoById(this.storeId).subscribe(data => {
      console.log("singleStoreInfo", data);
      this.storeDetails = data[0];
      this.storeCoordinates = JSON.parse(this.storeDetails.store_coordinates);
      if (this.storeCoordinates != null) {
        if (this.storeCoordinates.lat != null) {
          this.lat = this.storeCoordinates.lat;
          this.lng = this.storeCoordinates.lng;
        }
      }
      // console.log(this.storeCoordinates)
      if (this.storeDetails.store_timing.length > 0)
        this.timings = JSON.parse(this.storeDetails.store_timing);
      console.log("timings", this.timings)
    })
  }
  getDropLocations(license) {
    this.productsService.getDropLocationsByLicense(license).subscribe(data => {
      this.dropLocationsData = data;
      console.log(this.dropLocationsData);
      this.dropDays[0] = this.dropLocationsData[0].drop_location == null ? null : JSON.parse(this.dropLocationsData[0].drop_location);
      this.dropDays[1] = this.dropLocationsData[0].monday_drop_location == null ? null : JSON.parse(this.dropLocationsData[0].monday_drop_location);
      this.dropDays[2] = this.dropLocationsData[0].tuesday_drop_location == null ? null : JSON.parse(this.dropLocationsData[0].tuesday_drop_location);
      this.dropDays[3] = this.dropLocationsData[0].wedneseday_drop_location == null ? null : JSON.parse(this.dropLocationsData[0].wedneseday_drop_location);
      this.dropDays[4] = this.dropLocationsData[0].thursday_drop_location == null ? null : JSON.parse(this.dropLocationsData[0].thursday_drop_location);
      this.dropDays[5] = this.dropLocationsData[0].friday_drop_location == null ? null : JSON.parse(this.dropLocationsData[0].friday_drop_location);
      this.dropDays[6] = this.dropLocationsData[0].saturday_drop_location == null ? null : JSON.parse(this.dropLocationsData[0].saturday_drop_location);
      // console.log(this.dropDays);
      this.selectedDropDayCoordinates = this.dropDays[0];
      // this.mapFocus();
    })
  }
  onChange(e) {
    // console.log(e.toLowerCase() + " Clicked!");
    this.selectedDay = this.days.findIndex(x => x == e);
    console.log(this.selectedDay);
    this.selectedDropDayCoordinates = this.dropDays[this.selectedDay];
    this.previous = '';
    // this.mapFocus();
  }
  mapFocus() {
    if (this.selectedDropDayCoordinates) {
      this.lat = this.selectedDropDayCoordinates[0].lat;
      this.lng = this.selectedDropDayCoordinates[0].lng;
      // console.log(this.lat + " co " + this.lng)
    }
  }
  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
  getSimilarProducts(title) {
    this.productsService.getSimilarProductsByTitle(title).subscribe(data => {
      this.similarProducts = data;
      this.similarProducts = this.similarProducts.filter(item => item.license_no !== this.licenceNo);
      console.log("related products", this.similarProducts);
      console.log(this.licenceNo);
    })
  }

  goToProductsViewPage(category, id, productname) {
    this.router.navigate(['/places', category, id, productname.replace(/\s+/g, '_')]);
  }
  onTwitterClick() {
    // this.meta.removeTag('name="twitter:card"'); 
    // this.meta.removeTag('name="twitter:site"');
    // this.meta.removeTag('name="twitter:title"');
    // this.meta.removeTag('name="twitter:description"');
    // this.meta.removeTag('name="twitter:image"');

    // this.meta.addTag({ name: 'twitter:card', content: 'summary' });
    // this.meta.addTag({ name: 'twitter:site', content: '@ProductWeedbuys' });
    // this.meta.addTag({ name: 'twitter:title', content: this.productTitle });
    // this.meta.addTag({ name: 'twitter:description', content: this.selectedProd.description });
    // this.meta.addTag({ name: 'twitter:image', content: this.myThumbnail });
    window.open("https://www.twitter.com/intent/tweet?url=" + this.repoUrl + this.category + '/' + this.storeId + '/' + this.productTitle.replace(/\s+/g, '_'));
  }
  onFacebookClick() {
    // this.meta.removeTag('name="og:title"'); 
    // this.meta.removeTag('name="og:site"');
    // this.meta.removeTag('name="og:description"');
    // this.meta.removeTag('name="og:url"');
    // this.meta.removeTag('name="og:image"');

    // this.meta.addTag({ name: 'og:title', content: this.productTitle });
    // this.meta.addTag({ name: 'og:site', content: '@ProductWeedbuys' });
    // this.meta.addTag({ name: 'og:description', content: this.selectedProd.description });
    // this.meta.addTag({ name: 'og:image', content: this.myThumbnail });
    // this.meta.addTag({ name: 'og:url', content: this.repoUrl + this.category + '/' + this.storeId + '/' + this.productTitle.replace(/\s+/g, '_')});
    window.open("https://www.facebook.com/sharer/sharer.php?u=" + this.repoUrl + this.category + '/' + this.storeId + '/' + this.productTitle.replace(/\s+/g, '_'));
  }
  onPinterestClick() {
    // this.meta.removeTag('name="og:title"');
    // this.meta.removeTag('name="og:site"');
    // this.meta.removeTag('name="og:description"');
    // this.meta.removeTag('name="og:url"');
    // this.meta.removeTag('name="og:image"');

    // this.meta.updateTag({ name: 'og:title', content: this.productTitle });
    // this.meta.updateTag({ name: 'og:site', content: '@ProductWeedbuys' });
    // this.meta.updateTag({ name: 'og:description', content: this.selectedProd.description });
    // this.meta.updateTag({ name: 'og:image', content: this.myThumbnail });
    // this.meta.updateTag({ name: 'og:url', content: this.repoUrl + this.category + '/' + this.storeId + '/' + this.productTitle.replace(/\s+/g, '_') });
    window.open("http://pinterest.com/pin/create/button/?url=" + this.repoUrl + this.category + '/' + this.storeId + '/' + this.productTitle.replace(/\s+/g, '_') + "&media=" + this.myThumbnail +
      "&description=" + this.selectedProd.description);
  }

  fcbkMetaUpdate() {
    this.meta.removeTag('name="og:title"');
    this.meta.removeTag('name="og:site"');
    this.meta.removeTag('name="og:description"');
    this.meta.removeTag('name="og:url"');
    this.meta.removeTag('name="og:image"');

    this.meta.addTag({ name: 'og:title', content: this.productTitle });
    this.meta.addTag({ name: 'og:site', content: '@ProductWeedbuys' });
    this.meta.addTag({ name: 'og:description', content: this.products[0].description });
    this.meta.addTag({ name: 'og:image', content: this.imgUrl + this.selectedProductImg });
    this.meta.addTag({ name: 'og:url', content: this.repoUrl + this.category + '/' + this.storeId + '/' + this.productTitle.replace(/\s+/g, '_') });

    console.log(this.meta.getTag('name="og:url"'));
    console.log(this.meta.getTag('name="og:site"'));
    console.log(this.meta.getTag('name="og:title"'));
    console.log(this.meta.getTag('name="og:description"'));
    console.log(this.meta.getTag('name="og:image"'));
  }
  twitterMetaUpdate() {
    this.meta.removeTag('name="twitter:card"');
    this.meta.removeTag('name="twitter:site"');
    this.meta.removeTag('name="twitter:title"');
    this.meta.removeTag('name="twitter:description"');
    this.meta.removeTag('name="twitter:image"');

    this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
    this.meta.addTag({ name: 'twitter:site', content: '@ProductWeedbuys' });
    this.meta.addTag({ name: 'twitter:title', content: this.productTitle });
    this.meta.addTag({ name: 'twitter:description', content: this.products[0].description });
    this.meta.addTag({ name: 'twitter:image', content: this.imgUrl + this.selectedProductImg });

    console.log(this.meta.getTag('name="twitter:card"'));
    console.log(this.meta.getTag('name="twitter:site"'));
    console.log(this.meta.getTag('name="twitter:title"'));
    console.log(this.meta.getTag('name="twitter:description"'));
    console.log(this.meta.getTag('name="twitter:image"'));
  }
  intentAlreadyRaisedCheckForTick() {
    let customerID = localStorage.getItem('CurrentUserLicense');
    this.intentsService.getIntentAlreadyRaisedCheck(customerID, this.title, this.licenceNo).subscribe(data => {
      this.tickSymbol = data;
      console.log(this.tickSymbol);
    })
  }
  // onSelectCategory(selectedCategory) {
  //   $('#dropdownMenuButton').html(selectedCategory);
  // }
  ngAfterViewInit() {
    this.rfqBtnDisable = false;
    console.log(this.rfqBtnDisable);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
