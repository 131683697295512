import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dis-facilities',
  templateUrl: './dis-facilities.component.html',
  styleUrls: ['./dis-facilities.component.css']
})
export class DisFacilitiesComponent implements OnInit {
  Facilities = ["Accepts Credit Cards", "Wireless Internet", "Private Parking", "Wheelchair Accessible", "Bike Parking", "Reservation", "Smoking Area", "Coupons"];
  public seletedFacilities = [];
  public license_no: string;
  public toastMsg: string;
  prefferedFacilities:any;
  preFacilities=[];

  constructor(private productsService: ProductsService,private toastr: ToastrService) { }

  ngOnInit() {
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    //console.log(this.Facilities);
    this.getStoreFacilities();
  }
  getStoreFacilities(){
    this.productsService.getStoreFacilities(this.license_no).subscribe(res => {
      
      this.prefferedFacilities=res[0].facilities;
      //console.log(this.prefferedFacilities);
      if(this.prefferedFacilities!=""){
        this.preFacilities=JSON.parse(this.prefferedFacilities);
        this.seletedFacilities=this.preFacilities;
      }
      //console.log(this.time);
      console.log("facilities",this.preFacilities);
    });
  }

  onCheckboxChange(facility,event){
    if(event.target.checked) {
      this.seletedFacilities.push(facility); 
    } else {
      for(var i=0 ; i < this.Facilities.length; i++) {
          if(this.seletedFacilities[i] == facility){
          this.seletedFacilities.splice(i,1);
        }
      }
    }
    console.log(this.seletedFacilities);
    // var sorted = this.seletedFacilities.sort(); 
    // console.log("Returned string is : " + sorted );
  }

  sendFacilities(){
    let data = {
      facilities: this.seletedFacilities.sort(),
      license_number: this.license_no
    };

    console.log("send_datas",data);

    this.productsService.setStoreFacilities(data).subscribe(res => {
      console.log(res);
      this.toastMsg = res['msg'];
      if (res['status'] == "200") {
        this.toastr.success(this.toastMsg);
      }
      else if (res['status'] == "400") {
        this.toastr.error(this.toastMsg);
      }
    });
  }

}
