import { Injectable } from '@angular/core';
import * as jwt_decode from "jwt-decode";
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private router:Router) { }

  storeToken(ctoken: any) {
    localStorage.setItem('CurrentToken', JSON.stringify({ token: ctoken }));
  }
  getDecodedAccessToken(): any {
    const cur_token = localStorage.getItem('CurrentToken');
    try {
      return jwt_decode(cur_token);
    }
    catch (Error) {
      return null;
    }
  }
  getUserLicenseExp() {
    let tokenInfo = this.getDecodedAccessToken(); // decode token
    let license = tokenInfo.iss;
    localStorage.setItem('CurrentUserLicense', license);
    let tokenExpire = tokenInfo.exp;
    localStorage.setItem('CurrentUserExpireTime', tokenExpire);
  }
  destroyToken(){
    localStorage.removeItem('CurrentToken');
    localStorage.removeItem('CurrentUserLicense');
    localStorage.removeItem('CurrentUserExpireTime');
    localStorage.removeItem('userDesignation');
    localStorage.removeItem('status');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('CurrentSessionToken');
    localStorage.clear();
    // sessionStorage.clear();
    // this.router.navigate(['home']);
  }
  tokenExist(){
    let ctokenExist= localStorage.getItem('CurrentToken');
    // let stokenExist= sessionStorage.getItem('CurrentSessionToken');
    if(ctokenExist){ // && stokenExist 
      return true;
    }
    return false;
  }
  storeSessionToken(ctoken: any) {
    localStorage.setItem('CurrentSessionToken', JSON.stringify({ token: ctoken }));
  }

}
