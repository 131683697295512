import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../services/products.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-dis-office-hours',
  templateUrl: './dis-office-hours.component.html',
  styleUrls: ['./dis-office-hours.component.css']
})
export class DisOfficeHoursComponent implements OnInit {
  submitButton=false;
  public checkedList = [];
  public hours = [];
  public mins = [];
  public openingHour: string;
  public openingMin: string;
  public closingHour: string;
  public closingMin: string;
  public license_no: string;
  public toastMsg: string;
  public store_time:any;
  public time:any=[{}];

  constructor(private toastr: ToastrService,private productsService: ProductsService) { }

  DaysList = [
    { id: 0, name: 'Sunday', opening_hour:"--", opening_min:"--", closing_hour:"--", closing_min:"--", checked: false, closed:true}, 
    { id: 1, name: 'Monday', opening_hour:"--", opening_min:"--", closing_hour:"--", closing_min:"--", checked: false, closed:true}, 
    { id: 2, name: 'Tuesday', opening_hour:"--", opening_min:"--", closing_hour:"--", closing_min:"--", checked: false, closed:true},
    { id: 3, name: 'Wednesday', opening_hour:"--", opening_min:"--", closing_hour:"--", closing_min:"--", checked: false, closed:true}, 
    { id: 4, name: 'Thursday', opening_hour:"--", opening_min:"--", closing_hour:"--", closing_min:"--", checked: false, closed:true}, 
    { id: 5, name: 'Friday', opening_hour:"--", opening_min:"--", closing_hour:"--", closing_min:"--", checked: false, closed:true}, 
    { id: 6, name: 'Saturday', opening_hour:"--", opening_min:"--", closing_hour:"--", closing_min:"--", checked: false, closed:true}
  ];

  ngOnInit() {
    this.checkedList = [];
    this.hours=["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23"];
    this.mins=["00","15","30","45"];
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    this.getStoreTime();//get time from database
    
  }
  getStoreTime(){
    this.checkedList = [];
    this.productsService.getStoreTimingforUpdate(this.license_no).subscribe(result =>{
      //console.log(result);
      let before_parse=result[0].store_timing;
      if(before_parse != ""){
      this.time=JSON.parse(before_parse);
      console.log(this.time);
      for(var i=0; i <this.time.length; i++){
        if(this.time[i].checked == true){
          this.checkedList.push(this.time[i].name);
          this.DaysList[i].opening_hour=this.time[i].opening_hour;
          this.DaysList[i].opening_min=this.time[i].opening_min;
          this.DaysList[i].closing_hour=this.time[i].closing_hour;
          this.DaysList[i].closing_min=this.time[i].closing_min;
          this.DaysList[i].checked=true;
          this.DaysList[i].closed=false;
        }
      }
      console.log(this.checkedList);
      console.log(this.DaysList);
      }
    });
  }

  onCheckboxChange(day, event) {
    if(event.target.checked) {
      this.checkedList.push(day.name);
      day.checked=true;
      day.closed=false;
      this.submitButton=true;
    } else {
      for(var i=0 ; i < this.DaysList.length; i++) {
          if(this.checkedList[i] == day.name){
          this.checkedList.splice(i,1);
          day.checked=false;
          day.closed=true;
          this.submitButton=false;
        }
      }
    }
    console.log(this.DaysList);
    console.log(this.checkedList);
  }
  onChangeOpeningHour(day,event: any) {
    this.openingHour = event.target.value;
    console.log(this.openingHour);
    for(var i=0 ; i < this.DaysList.length; i++) {
        if(this.checkedList[i] == day.name){
        day.opening_hour=this.openingHour;
      }
    }
    console.log(this.openingHour);
    console.log(this.DaysList);
  }

  onChangeOpeningMin(day,event: any) {
    this.openingMin = event.target.value;
    for(var i=0 ; i < this.DaysList.length; i++) {
      if(this.checkedList[i] == day.name){
      day.opening_min=this.openingMin;
    }
  }
   console.log(day.opening_min);
   console.log(this.DaysList);
  }

  onChangeClosingHour(day,event: any) {
    this.closingHour = event.target.value;
    for(var i=0 ; i < this.DaysList.length; i++) {
      if(this.checkedList[i] == day.name){
      day.closing_hour=this.closingHour;
    }
  }
   console.log(day.closing_hour);
   console.log(this.DaysList);
  }

  onChangeClosingMin(day,event: any) {
    this.closingMin = event.target.value;
    for(var i=0 ; i < this.DaysList.length; i++) {
      if(this.checkedList[i] == day.name){
      day.closing_min=this.closingMin;
    }
  }
   console.log(day.closing_min);
   console.log(this.DaysList);

  }
  getValues(){
    console.log(this.DaysList);

    let data = {
      timing: this.DaysList,
      license_number: this.license_no
    };

    console.log("send_datas",data);

    this.productsService.setStoreTiming(data).subscribe(res => {
      console.log(res);
      this.toastMsg = res['msg'];
      if (res['status'] == "200") {
        this.toastr.success(this.toastMsg);
      }
      else if (res['status'] == "400") {
        this.toastr.error(this.toastMsg);
      }
    });
  }
}
