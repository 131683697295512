import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  current_time: number;
  expireAt: number;

  constructor(private router: Router, private commonService:CommonService,private toastrService:ToastrService) { }

  isAuthentic(): boolean {
    const token = localStorage.getItem('CurrentToken');
    console.log(localStorage['CurrentToken']);
    this.current_time = new Date().getTime() / 1000;
    let expireTime = localStorage.getItem('CurrentUserExpireTime');
    this.expireAt = JSON.parse(expireTime);
    console.log(localStorage.length);

    if (this.expireAt < this.current_time || token == null || localStorage.length==0 ) { 
      this.commonService.destroyToken();
      this.toastrService.error("You have to login again first!");
      this.router.navigate(['/home']);
      return false;
    }
    return true;
  }
}
