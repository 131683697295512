import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Http, Headers, RequestOptions } from "@angular/http";
import { ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductsService } from '../services/products.service';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-existing',
  templateUrl: './existing.component.html',
  styleUrls: ['./existing.component.css']
})
export class ExistingComponent implements OnInit {
  public get_dis_strains: any;
  public get_suppress_res: any;
  public toastMsg: string;
  public license_no: string;
  public check_status: boolean = false;
  public strain = [];
  public unit = [];
  public price = [];
  public offerprice = [];
  public store_details: any;
  public store_id: string;

  constructor(private http: Http, private changeDetectorRef: ChangeDetectorRef,
    private toastr: ToastrService, private route: Router, private productsService: ProductsService,
    private router: Router) { }

  ngOnInit() {
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    this.getStoreDetails(this.license_no);
    this.getdiststrain(this.license_no);//get distributor strain from database
  }
  getStoreDetails(license_no) {
    this.productsService.getStoreName(license_no).subscribe(res => {
      this.store_details = res;
      console.log("store_details", this.store_details);
      this.store_id = this.store_details[0].id;
    });
  }
  getdiststrain(license_no) {
    this.productsService.getDistributorStrain(license_no).subscribe(res => {
      this.get_dis_strains = res;//store response in an array
      console.log("get_strain_details", this.get_dis_strains);


      if (this.get_dis_strains.length > 0) {
        this.check_status = false;//if array length >0 then show result
        this.get_dis_strains = res;
      } else {
        this.check_status = true;//otherwise no data found on ui
      }
    });
  }
  getidtitle(i, id, title, productTitle) {
    this.get_dis_strains.splice(i, 1);//remove id from array
    //this.changeDetectorRef.detectChanges();//reload table
    //console.log(this.get_dis_strains.length);
    if (this.get_dis_strains.length == 0) {
      this.check_status = true;
    }

    let data = {
      // id: id,
      //title: title,
      product_title: productTitle,
      license_number: this.license_no
    };
    console.log("send_datas", data);
    this.productsService.setSuppressrow(data).subscribe(res => {
      this.get_suppress_res = res;
      this.toastMsg = res['msg'];
      this.toastr.info(this.toastMsg);
    });
  }
  getcurrentdata(id, title, product_title, strain_sell_type) {
    var datas = { id: id, title: title, productTitle: product_title, strainSellType: strain_sell_type }​​​​​​​;
    localStorage.setItem("datas", JSON.stringify(datas));
    this.route.navigate(['dis-edit-strain']);
  }
  // routeToPlace(category,store_id,product_title){//created by gobinda da
  //   //console.log(category,store_id,product_title.replace(/\s/g, '_'));
  //   this.route.navigate(['/places',category,store_id,product_title.replace(/\s/g, '_')]);
  // }
  replaceString(product_title: string): string {
    let res = product_title.replace(/\s/g, '_');
    return res;
  }
  generateBarcode(title,product_title,strain_sell_type){
    console.log("For_barcdoe_generate",title,strain_sell_type);
    var datas = { title: title,product_title: product_title,strain_sell_type: strain_sell_type }​​​​​​​;
    localStorage.setItem("for_barcode", JSON.stringify(datas));
    this.route.navigate(['dis-strain-barcode']);
  }


}
