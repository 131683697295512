import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RfqsService {

  constructor(private http: Http) { }

  public submitRFQUrl = environment.apiBaseUrl + "/get_rfq";
  public getRfqForDistributorUrl = environment.apiBaseUrl + "/get_rfqs_for_distributor";
  public getDistributorPendingOrderUrl = environment.apiBaseUrl + "/get_distributor_pending_orders";
  public getCustomerPendinRfqsUrl = environment.apiBaseUrl + "/get_customer_pending_rfqs";
  public getRfqExpireTimeForDistributorUrl = environment.apiBaseUrl + "/get_rfq_expire_time";
  public updateCustomersDeliveryAddrUrl = environment.apiBaseUrl + "/update_customers_delivery_address";
  public ParicipateRfqUrl = environment.apiBaseUrl + "/set_rfq_paricipation";
  public getRfqParicipateDetailsUrl = environment.apiBaseUrl + "/get_rfq_participation_details";
  public getBidRfqForCustomerUrl = environment.apiBaseUrl + "/get_bid_rfq_for_customer";
  public customerRfqParticipationCheckUrl = environment.apiBaseUrl + "/customer_rfq_participation_check";
  public getRfqParicipantNumberUrl = environment.apiBaseUrl + "/get_rfq_paricipant_number";
  public setRfqContestWinnerUrl = environment.apiBaseUrl + "/set_rfq_contest_winner";
  public getRfqWinnerDetailsUrl = environment.apiBaseUrl + "/get_rfq_contest_winner_for_customer";
  public getRfqWinnerDetailsForDistributorUrl = environment.apiBaseUrl + "/get_rfq_contest_winner_for_distributor";
  public getwinnerChoosenForCustomerUrl = environment.apiBaseUrl + "/get_winner_choosen_for_customer";
  public getmyRfqsForDistributorUrl = environment.apiBaseUrl + "/get_my_rfq_for_distributor";
  public getWonRfqForDistributorUrl = environment.apiBaseUrl + "/get_won_rfq_for_distributor";
  
  
  


  getRfqParicipantNumber(contest_id) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getRfqParicipantNumberUrl, { contest_id: contest_id }, options).pipe(map((res: Response) => res.json()));
  }
  submitRFQ(raiseRFQ) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(raiseRFQ);
    return this.http.post(this.submitRFQUrl, body, options).pipe(map((res: Response) => res.json()));
  }
  ///////////////for distributors/////////////
  getRfqForDistributor(license_no) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getRfqForDistributorUrl, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  }
  getDistributorPendingOrders(license_no) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getDistributorPendingOrderUrl, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  }
  getRfqExpireTime(id) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getRfqExpireTimeForDistributorUrl, { id: id }, options).pipe(map((res: Response) => res.json()));
  }
  ///////////////for customers/////////////
  getCustomerPendingRfqs(customerId) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getCustomerPendinRfqsUrl, { customer_id: customerId }, options).pipe(map((res: Response) => res.json()));
  }

  updateCustomersDeliveryAddr(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.updateCustomersDeliveryAddrUrl, body, options).pipe(map((res: Response) => res.json()));
  }

  ParicipateRfq(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.ParicipateRfqUrl, body, options).pipe(map((res: Response) => res.json()));
  }
  getRfqParicipateDetails(contest_id) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getRfqParicipateDetailsUrl, { contest_id: contest_id }, options).pipe(map((res: Response) => res.json()));
  }
  getBidRfqForCustomer(contest_id) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getBidRfqForCustomerUrl,{ contest_id: contest_id }, options).pipe(map((res: Response) => res.json()));
  }

  customerRfqParticipationCheck(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.customerRfqParticipationCheckUrl, body, options).pipe(map((res: Response) => res.json()));
  }
  setRfqContestWinner(data){
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.setRfqContestWinnerUrl, body, options).pipe(map((res: Response) => res.json()));
  }
  getRfqWinnerDetails(contest_id) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getRfqWinnerDetailsUrl, { contest_id: contest_id }, options).pipe(map((res: Response) => res.json()));
  }
  getRfqWinnerDetailsForDistributor(contest_id) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getRfqWinnerDetailsForDistributorUrl, { contest_id: contest_id }, options).pipe(map((res: Response) => res.json()));
  }
  getwinnerChoosenForCustomer(customerId){
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getwinnerChoosenForCustomerUrl, { customer_id: customerId }, options).pipe(map((res: Response) => res.json()));
  }
  getmyRfqsForDistributor(license_no) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getmyRfqsForDistributorUrl, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  }
  getWonRfqForDistributor(license_no){
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getWonRfqForDistributorUrl, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  }
}
