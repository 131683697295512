import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStoreConcentrates'
})
export class FilterStoreStrainSellType2Pipe implements PipeTransform {
  filteredStoreDetails:any[]=[];
  transform(relatedStoreDetailsArr: any[],Concentrates:string): any {
    this.filteredStoreDetails=[];
    for (var i = 0; i < relatedStoreDetailsArr.length; i++) {
      if (relatedStoreDetailsArr[i].strain_sell_type === Concentrates) {
        this.filteredStoreDetails.push(relatedStoreDetailsArr[i]);
      }
    }
    return this.filteredStoreDetails;
  }
}
