import { Routes } from "@angular/router";
import { DashboardComponent } from "../../dashboard/dashboard.component";
import { ExistingComponent } from "../../existing/existing.component";
import { AddproductComponent } from "../../addproduct/addproduct.component";
import { DisExistingSuppressedComponent } from "../../dis-existing-suppressed/dis-existing-suppressed.component";
import { DisEditStrainComponent } from "../../dis-edit-strain/dis-edit-strain.component";
import { DisStoreContentComponent } from "../../dis-store-content/dis-store-content.component";
import { WorkInProgressComponent } from "../../work-in-progress/work-in-progress.component";
import { AuthGuardService as Auth } from '../../services/auth-guard.service';
import { DisAccountComponent } from "../../dis-account/dis-account.component";
import { DisOfficeHoursComponent } from "../../dis-office-hours/dis-office-hours.component";
import { DisFacilitiesComponent } from "../../dis-facilities/dis-facilities.component";
import { DisStoreLocationComponent } from "../../dis-store-location/dis-store-location.component";
import { RouteManagementComponent } from "../../route-management/route-management.component";
import { MondayRouteMapComponent } from "../../monday-route-map/monday-route-map.component";
import { TuesdayRouteMapComponent } from "../../tuesday-route-map/tuesday-route-map.component";
import { WednesdayRouteMapComponent } from "../../wednesday-route-map/wednesday-route-map.component";
import { ThursdayRouteMapComponent } from "../../thursday-route-map/thursday-route-map.component";
import { FridayRouteMapComponent } from "../../friday-route-map/friday-route-map.component";
import { SaturdayRouteMapComponent } from "../../saturday-route-map/saturday-route-map.component";
import { DistributorAllRfqsComponent } from "../../distributor-all-rfqs/distributor-all-rfqs.component";
import { DistributorOrderPendingComponent } from "../../distributor-order-pending/distributor-order-pending.component";
import { RfqParticipationComponent } from "../../rfq-participation/rfq-participation.component";
import { DisStrainBarcodeComponent } from "../../dis-strain-barcode/dis-strain-barcode.component";
import { DisMyRfqsComponent } from "../../dis-my-rfqs/dis-my-rfqs.component";
import { DisWonRfqsComponent } from "../../dis-won-rfqs/dis-won-rfqs.component";
import { DisRfqsStatsComponent } from "../../dis-rfqs-stats/dis-rfqs-stats.component";


export const SECURE_ROUTES: Routes = [
    // { path: 'template', component: TemplateComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [Auth] },
    { path: 'existing', component: ExistingComponent, canActivate: [Auth] },
    { path: 'addproduct', component: AddproductComponent, canActivate: [Auth] },
    { path: 'dis-existing-suppressed', component: DisExistingSuppressedComponent, canActivate: [Auth] },
    { path: 'dis-edit-strain', component: DisEditStrainComponent, canActivate: [Auth] },
    { path: 'dis-store-content', component: DisStoreContentComponent, canActivate: [Auth] },
    { path: 'work-in-progress', component: WorkInProgressComponent, canActivate: [Auth] },
    { path: 'dis-account', component: DisAccountComponent, canActivate: [Auth] },
    { path: 'dis-office-hours', component: DisOfficeHoursComponent, canActivate: [Auth] },
    { path: 'dis-facilities', component: DisFacilitiesComponent, canActivate: [Auth] },
    { path: 'dis-store-location', component: DisStoreLocationComponent, canActivate: [Auth] },
    { path: 'route-management', component: RouteManagementComponent, canActivate: [Auth] },
    { path: 'monday-route-map', component: MondayRouteMapComponent, canActivate: [Auth] },
    { path: 'tuesday-route-map', component: TuesdayRouteMapComponent, canActivate: [Auth] },
    { path: 'wednesday-route-map', component: WednesdayRouteMapComponent, canActivate: [Auth] },
    { path: 'thursday-route-map', component: ThursdayRouteMapComponent, canActivate: [Auth] },
    { path: 'friday-route-map', component: FridayRouteMapComponent, canActivate: [Auth] },
    { path: 'saturday-route-map', component: SaturdayRouteMapComponent, canActivate: [Auth] },
    { path: 'distributor-all-rfqs', component: DistributorAllRfqsComponent, canActivate: [Auth] },
    { path: 'distributor-order-pending', component: DistributorOrderPendingComponent, canActivate: [Auth] },
    // { path: 'rfq-participation/:image/:product_title/:quantity/:unit/:quote_price', component: RfqParticipationComponent, canActivate: [Auth] }
    { path: 'rfq-participation', component: RfqParticipationComponent, canActivate: [Auth] },
    { path: 'dis-strain-barcode', component: DisStrainBarcodeComponent, canActivate: [Auth] },
    { path: 'dis-my-rfqs', component: DisMyRfqsComponent, canActivate: [Auth] },
    { path: 'dis-won-rfqs', component: DisWonRfqsComponent, canActivate: [Auth] },
    { path: 'dis-rfqs-stats', component: DisRfqsStatsComponent, canActivate: [Auth] }
    



   
]