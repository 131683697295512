import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from 'src/environments/environment';
import { UsersService } from 'src/app/services/users.service';
import {Location} from '@angular/common';
@Component({
  selector: 'app-pub-strain-prod-availibility',
  templateUrl: './pub-strain-prod-availibility.component.html',
  styleUrls: ['./pub-strain-prod-availibility.component.css']
})
export class PubStrainProdAvailibilityComponent implements OnInit {

  itemFound = [];
  nameLong: string;
  availibility: string;
  private sub: any;
  scrollTopBtnEnable: boolean = false;
  relatedStoreDetailsArr: any = [{}];
  imgUrl = environment.imageUrl;
  keyword: 'name';
  selectedValue: string = '';
  filterTerm = "All";
  selectedCity: string = null;
  bannerUrl:string=environment.strainBannerUrl;
  // scroll to top
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (window.pageYOffset > 300) {
      this.scrollTopBtnEnable = true;
    }
    else {
      this.scrollTopBtnEnable = false;
    }
  }
  filterOptions = [
    'All', 'Flower', 'Concentrates', 'Edibles', 'Pre-Rolls', 'Clones'
  ]
  places: any = [];
  allPlaces: any = [{}];
  constructor(private route: ActivatedRoute, private productsService: ProductsService, private usersService: UsersService, private router: Router,private location: Location) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.nameLong = params['name'];
      this.availibility = params['availability'];
      this.nameLong = this.nameLong.replace(/_/g, ' ');
      this.getStoreDetails(this.nameLong, this.selectedCity);
      console.log(this.nameLong);
      console.log(this.imgUrl);
      this.getAllPlaces();
      this.selectedValue = this.filterOptions[0];
      this.getCurrentStrainDetails(this.nameLong);
    });
  }

  getStoreDetails(title, city) {
    this.relatedStoreDetailsArr = [];
    this.productsService.getStoreDetailsByTitle(title).subscribe(data => {
      this.relatedStoreDetailsArr = data;
      if (city == null || city == '') {
        this.relatedStoreDetailsArr = this.relatedStoreDetailsArr;
      } else {
        this.relatedStoreDetailsArr = this.relatedStoreDetailsArr.filter(element =>
          element.place.toUpperCase() == city.toUpperCase()
        )
      }
      console.log(city);
      console.log(this.relatedStoreDetailsArr);
    })
  }

  findStrainType(item) {
    this.itemFound = this.relatedStoreDetailsArr.filter(element =>
      element.strain_sell_type == item
    )
    return this.itemFound.length;
  }
  getCurrentStrainDetails(starin){
    this.productsService.getSingleStrainDetails(starin).subscribe(data=>{
      console.log("strain",data);
      let currentStrain:any=data;
      if(currentStrain.top_banner==''|| currentStrain.top_banner==null ||currentStrain.top_banner==undefined){
        this.bannerUrl="assets/pro-image-default.png";
      }else{
        this.bannerUrl=this.bannerUrl+currentStrain.top_banner;
      }
      console.log(this.bannerUrl);
    })
  }

  getAllPlaces() {
    this.usersService.getAllUserCity().subscribe(data => {
      this.allPlaces = data;
      console.log('all cities', this.allPlaces);
      console.log(this.allPlaces.length)
      for (let i = 0; i < this.allPlaces.length; i++) {
        this.places.push(this.allPlaces[i].cities)
      }
    })
  }
  selectEvent(item) {
    console.log(item);
    this.getStoreDetails(this.nameLong, item);
    // do something with selected item
  }

  onChangeSearch(search: string) {
    console.log(search)
    this.getStoreDetails(this.nameLong, search);
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // console.log(e)
    // do something
  }
  filterOnType(item) {
    this.selectedValue = item;
    console.log(this.selectedValue);
  }
  onBackBtnClick(){
    this.location.back();
  }
  onStoreSelect(place, store) {
    // console.log(place+' '+store)
    this.router.navigate(['/places', place.replace(/\s+/g, '_'), store.replace(/\s+/g, '_')]);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
