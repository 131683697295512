import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStrainsByCategory',
  pure: false
})
export class FilterStrainsByCategoryPipe implements PipeTransform {
  filteredStrainList: any[] = [];

  transform(strainList: any[], searchStr: any): any {

    if (!strainList) { return strainList; }
    else {
      if (searchStr.categoryHybrid == false && searchStr.categoryIndica == false && searchStr.categorySativa == false) {
        return strainList;
      } else if (searchStr.categoryHybrid == true && searchStr.categoryIndica == false && searchStr.categorySativa == false) {
        this.filteredStrainList = strainList.filter(d => d.category == "Hybrid")
        return this.filteredStrainList;
      } else if (searchStr.categoryHybrid == false && searchStr.categoryIndica == true && searchStr.categorySativa == false) {
        this.filteredStrainList = strainList.filter(d => d.category == "Indica")
        return this.filteredStrainList;
      } else if (searchStr.categoryHybrid == false && searchStr.categoryIndica == false && searchStr.categorySativa == true) {
        this.filteredStrainList = strainList.filter(d => d.category == "Sativa")
        return this.filteredStrainList;
      } else if (searchStr.categoryHybrid == false && searchStr.categoryIndica == true && searchStr.categorySativa == true) {
        this.filteredStrainList = strainList.filter(d => d.category == "Sativa" || d.category == "Indica")
        return this.filteredStrainList;
      } else if (searchStr.categoryHybrid == true && searchStr.categoryIndica == false && searchStr.categorySativa == true) {
        this.filteredStrainList = strainList.filter(d => d.category == "Sativa" || d.category == "Hybrid")
        return this.filteredStrainList;
      } else if (searchStr.categoryHybrid == true && searchStr.categoryIndica == true && searchStr.categorySativa == false) {
        this.filteredStrainList = strainList.filter(d => d.category == "Indica" || d.category == "Hybrid")
        return this.filteredStrainList;
      } else {
        return strainList;
      }

    }
  }
}
