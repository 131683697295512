import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-in-progress',
  templateUrl: './work-in-progress.component.html',
  styleUrls: ['./work-in-progress.component.css']
})
export class WorkInProgressComponent implements OnInit {
  public repoUrl:string;
  public image:any;
  public description:any;
  public title:any;
  
  constructor() { }

  ngOnInit() {
    this.repoUrl="http://www.rd.com/health/diet-weight-loss/speed-up-metabolism";
    this.image="http://weedbuys.com/api/public/strainImage/5c580c05a059a.png";
    this.description="I got the Product";
    this.title="Ak-47";
  }
}
