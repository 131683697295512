import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { UsersService } from 'src/app/services/users.service';
import { GoogleMapService } from 'src/app/services/google-map.service';
import { environment } from 'src/environments/environment';
import { GrayGmapStyleModel } from 'src/app/models/gray-gmap-style-model';

@Component({
  selector: 'app-pub-single-store-view',
  templateUrl: './pub-single-store-view.component.html',
  styleUrls: ['./pub-single-store-view.component.css']
})
export class PubSingleStoreViewComponent implements OnInit {
  public sub: any;
  storeName: string;
  cityName: string;
  storeDetails: any = [{}];
  topBannerUrl: string = '';
  timings: any = [{}];
  storeLicense: string;
  storeOwnerDetails: any = {};
  strainDetails: any = [{}];
  storeLocationOnMap: string;
  coordinatesObj: any = {};
  storeLat: number;
  storeLng: number;
  imgUrl: string;
  itemImg: string = environment.imageUrl;
  storeFacilities: any = [];
  icon = {
    url: 'assets/img/gmap-img/weedbuys-gmarker.png',
    scaledSize: {
      width: 50,
      height: 50
    }
  };
  scrollTopBtnEnable: boolean = false;
  previous: any;
  strainLimitPerView = 4;
  totalStrains: number;
  loadMoreBtnHide: boolean = true;
  remainingStrains: number;
  styles: any[] = GrayGmapStyleModel.grayStyles;
  // scroll to top
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (window.pageYOffset > 700) {
      this.scrollTopBtnEnable = true;
    }
    else {
      this.scrollTopBtnEnable = false;
    }
  }
  constructor(private route: ActivatedRoute, private productsService: ProductsService, private usersService: UsersService, private googleMapService: GoogleMapService, private router: Router) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.cityName = params['city'];
      this.storeName = params['store'];
      this.cityName = this.cityName.replace(/_/g, ' ');
      this.storeName = this.storeName.replace(/_/g, ' ');
      this.getSingleStoreDetails();
    });
  }
  getSingleStoreDetails() {
    this.productsService.getSingleStoreDetails(this.storeName).subscribe(data => {
      this.storeDetails = data[0];
      console.log("store", this.storeDetails);
      if (this.storeDetails.facilities != "") {
        this.storeFacilities = JSON.parse(this.storeDetails.facilities);
      }
      this.topBannerUrl = environment.imageUrl + this.storeDetails.large_image;
      if (this.storeDetails.store_timing.length > 0)
        this.timings = JSON.parse(this.storeDetails.store_timing);
      this.storeLicense = this.storeDetails.license_number;
      this.getStoreOwnerDetails(this.storeLicense);
      this.getStoreStrainDetails(this.storeLicense);
      this.findStoreLocation(this.storeDetails.store_address);
      console.log("location", this.storeDetails.store_address);
    })
  }
  getStoreStrainDetails(storeLicense_number) {
    this.productsService.getStrainDetailsByLicense(storeLicense_number).subscribe(data => {
      console.log("data", data);
      this.strainDetails = data;
      this.totalStrains = this.strainDetails.length;
      this.remainingStrains = this.totalStrains - 4;
    })
  }
  getStoreOwnerDetails(storeLicense_number) {
    this.usersService.getUserDetails(storeLicense_number).subscribe(data => {
      this.storeOwnerDetails = data;
      if (this.storeOwnerDetails.profile_image == "../assets/avatar.png")
        this.imgUrl = this.storeOwnerDetails.profile_image;
      else {
        this.imgUrl = environment.imageUrl + this.storeOwnerDetails.profile_image;
      }
      this.storeLocationOnMap = this.storeOwnerDetails.city;
      console.log("map", this.storeOwnerDetails);
    })
  }

  // google map customization
  findStoreLocation(location) {
    this.googleMapService.getCoordinateByname(location).subscribe(data => {
      console.log("mapdetails", data);
      this.coordinatesObj = data;
      let coordinates = this.coordinatesObj.results[0].geometry.location;
      this.storeLat = coordinates.lat;
      this.storeLng = coordinates.lng;
      // console.log("latlong",)
    })
  }
  // onMouseOver(infoWindow, gm) {
  //   if (gm.lastOpen != null) {
  //     gm.lastOpen.close();
  //   }
  //   gm.lastOpen = infoWindow;
  //   infoWindow.open();
  // }

  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }

  loadMoreStrains() {
    if (this.strainLimitPerView < this.totalStrains) {
      this.loadMoreBtnHide = false;
      this.strainLimitPerView = this.strainLimitPerView + 2;
      this.remainingStrains = this.remainingStrains - 2;
    } else if (this.strainLimitPerView >= this.totalStrains) {
      this.loadMoreBtnHide = true;
      this.remainingStrains = 0;
    }
  }

  goToProductsViewPage(category, id, productname) {
    this.router.navigate(['/places', category, id, productname.replace(/\s+/g, '_')]);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
