import { Component, OnInit, AfterViewInit } from '@angular/core';
// import { WOW } from 'wowjs/dist/wow.min';
import { RegistrationModel } from "../../app/models/registration-model";
import { UsersService } from '../services/users.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LoginModel } from '../models/login-model';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { CusRegistrationModel } from '../models/cus-registration-model';
import { CustomerService } from '../services/customer.service';
import { SeoUrlsService } from '../services/seo-urls.service';
import { CookieService } from 'ngx-cookie-service';

declare var $: any;


// var wow = new WOW(
//   {
//     boxClass: 'wow',      // animated element css class (default is wow)
//     animateClass: 'animated', // animation css class (default is animated)
//     offset: 0,          // distance to the element when triggering the animation (default is 0)
//     mobile: true,       // trigger animations on mobile devices (default is true)
//     live: true,       // act on asynchronously loaded content (default is true)
//     callback: function (box) {
//       // the callback is fired every time an animation is started
//       // the argument that is passed in is the DOM node being animated
//     },
//     scrollContainer: null // optional scroll container selector, otherwise use window
//   }
// );
// wow.init();

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  display = 'none';
  registrationData: RegistrationModel = {
    license_no: '',
    bname: '',
    btype: '',
    phone: '',
    username: '',
    email: '',
    password: '',
    profile_image: ''
  };
  cusRegistrationData: CusRegistrationModel = {
    username: '',
    email: '',
    address: '',
    phone: '',
    password: ''
  }

  loginData: LoginModel = { username: '', password: '', category: 1 };
  customerLoginData: LoginModel = { username: '', password: '', category: 0 };
  userData: any;
  cPassword: string;
  emailAddress: string;
  logShow: boolean = true;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  userLoggedIn: boolean;
  loggedUser: string;
  loginBtnClick: boolean = false;
  userExist: boolean = false;
  citiesObjArr: any = [{}];
  searchText: string;
  customerDataSubmitted: any = {};

  profile_image = '../assets/avatar.png';//by default image
  citiesObjArr2: any = [];
  searchBtnClick: boolean = false;
  caviarSearch: string;
  customerLoginBtnClick: boolean = false;
  customerRegBtnClick: boolean = false;
  distributorRegBtnClick: boolean = false;
  progressScale: number = 0;
  showPassword: boolean = false;
  showPassword1: boolean = false;
  showPassword2: boolean = false;
  showPassword3: boolean = false;
  showPassword4: boolean = false;
  public dis_Formdata: any = {};
  public cus_Formdata: any = {};
  rememberMe1: boolean = false;
  rememberMe2: boolean = false;

  constructor(private _cookieService: CookieService, private usersService: UsersService, private toastrService: ToastrService, private router: Router, private commonService: CommonService, private customerService: CustomerService, private seoUrlsService: SeoUrlsService) {
    if (this._cookieService.get('dis_r') == "true") {
      this.loginData.username = atob(this._cookieService.get('dis_u'));
      this.loginData.password = atob(this._cookieService.get('dis_p'));
      this.rememberMe1 = true;
    }
    if (this._cookieService.get('cus_r') == "true") {
      this.customerLoginData.username = atob(this._cookieService.get('cus_u'));
      this.customerLoginData.password = atob(this._cookieService.get('cus_p'));
      this.rememberMe2 = true;

    }
    // console.log(this.dis_Formdata);
  }

  ngOnInit() {
    this.loggedUser = localStorage.getItem('currentUser');
    this.searchBtnClick = false;
    this.allUsersLocation();
    this.onSearch();

    // for progressbar on window scroll 
    $(window).scroll(function () {
      var s = $(window).scrollTop(),
        d = $(document).height(),
        c = $(window).height();
      var scrollPercent = (s / (d - c)) * 100;
      var position = scrollPercent;
      $("#progressbar").css("width", position + "%");
      // console.log(Math.round(position));
    });
  }

  showResult() {
    this.usersService.getUserDetails(this.registrationData.license_no).subscribe(data => {
      this.userData = data;
      console.log(data);
      this.registrationData.bname = this.userData.business_name;
      this.registrationData.btype = this.userData.business_type;
      this.registrationData.phone = this.userData.business_phone;
      this.registrationData.profile_image = this.profile_image;
    });
  }
  togglePassword() {
    this.showPassword = !this.showPassword;
    // console.log(this.showPassword)
  }
  togglePassword1() {
    this.showPassword1 = !this.showPassword1;
  }
  togglePassword2() {
    this.showPassword2 = !this.showPassword2;
  }
  togglePassword3() {
    this.showPassword3 = !this.showPassword3;
  }
  togglePassword4() {
    this.showPassword4 = !this.showPassword4;
  }
  // resolved(captchaResponse: string) {
  //   console.log(`Resolved captcha with response ${captchaResponse}:`);
  // }    // for validating recaptcha for registration
  onRegister(form: NgForm) {
    this.distributorRegBtnClick = true;
    if (form.valid && !this.userExist) {
      this.usersService.registerNewUser(this.registrationData).subscribe(data => {
        if (data['status'] == 200) {
          this.toastrService.success(data['msg']);
        }
        else if (data['status'] == 400) {
          this.toastrService.error(data['msg']);
        }
        this.distributorRegBtnClick = false;
        $("#regModal").modal("hide");
        console.log(data);
        form.resetForm();
      });
    }
  };

  onLogin(form: NgForm) {
    this.loginBtnClick = true;
    if (form.valid) {
      this.usersService.loginUser(this.loginData).subscribe(data => {
        console.log(data);
        if (data['status'] == 200) {
          this.commonService.storeToken(data['token']);
          this.commonService.storeSessionToken(data['token']);
          localStorage.setItem("status", data['state']);
          this.commonService.getUserLicenseExp();
          // localStorage.setItem("userDesignation", data['designation']);
          localStorage.setItem("UD", btoa(data['designation']));
          console.log('CurrentUserLicense', localStorage.getItem('CurrentUserLicense'));
          console.log(localStorage.getItem('CurrentUserExpireTime'));
          if (data['state'] == 'approved') {
            this.userLoggedIn = true;
            this.toastrService.success(data['msg']);
            $("#signInModal").modal("hide");
            localStorage.setItem("currentUser", data['user']);
            this.loggedUser = localStorage.getItem('currentUser');
            this.seoUrlsService.allPossibleUrlsFetch();

            this._cookieService.set('dis_u', btoa(this.loginData.username));
            this._cookieService.set('dis_p', btoa(this.loginData.password));
            this._cookieService.set('dis_r', "" + this.rememberMe1);
            if (this._cookieService.get('dis_r') == "false") {
              form.resetForm();
              this._cookieService.delete('dis_u');
              this._cookieService.delete('dis_p');
              this._cookieService.delete('dis_r');
            }
          } else if (data['state'] == 'pending') {
            this.toastrService.error('You cannot login until your account is approved by the admin.');
            this.commonService.destroyToken();
            this.loginBtnClick = false;
            this.userLoggedIn = true;
            $("#signInModal").modal("hide");
            form.resetForm();
          } else if (data['state'] == 'dismissed') {
            this.toastrService.error('Sorry! Your account has been dismissed by the admin.');
            this.commonService.destroyToken();
            this.loginBtnClick = false;
            this.userLoggedIn = true;
            $("#signInModal").modal("hide");
            form.resetForm();
          }
        } else {
          this.loginBtnClick = false;
          this.toastrService.error(data['msg']);
          form.resetForm();
        }
      });
      // form.resetForm();
    }
  }
  onLogout() {
    this.commonService.destroyToken();
    this.customerLoginBtnClick = false;
    this.customerRegBtnClick = false;
    this.distributorRegBtnClick = false;
    this.loginBtnClick = false;
    // this.router.navigate(['/home']);
    this.toastrService.success('User logged out successfully.');
    this.collapseMenubar();
  }
  onCustomerLogin(form: NgForm) {
    // console.log(this.customerLoginData);
    this.customerLoginBtnClick = true;
    if (form.valid) {
      this.customerService.loginCustomer(this.customerLoginData).subscribe(data => {
        console.log(data);
        if (data['status'] == 200) {
          this.commonService.storeToken(data['token']);
          this.commonService.storeSessionToken(data['token']);
          localStorage.setItem("status", data['state']);
          this.commonService.getUserLicenseExp();
          console.log('CurrentUserLicense', localStorage.getItem('CurrentUserLicense'));
          console.log(localStorage.getItem('CurrentUserExpireTime'));
          this.toastrService.success(data['msg']);
          localStorage.setItem("currentUser", data['user']);
          this.loggedUser = localStorage.getItem('currentUser');
          if (data['designation'] == "customer") {
            // localStorage.setItem("userDesignation", data['designation']);
            localStorage.setItem("UD", btoa(data['designation']));
            // console.log(localStorage.getItem("userDesignation"));
          }

          this._cookieService.set('cus_u', btoa(this.customerLoginData.username));
          this._cookieService.set('cus_p', btoa(this.customerLoginData.password));
          this._cookieService.set('cus_r', "" + this.rememberMe2);
          if (this._cookieService.get('cus_r') == "false") {
            form.resetForm();
            this._cookieService.delete('cus_u');
            this._cookieService.delete('cus_p');
            this._cookieService.delete('cus_r');
          }
          this.seoUrlsService.allPossibleUrlsFetch();
        } else {
          this.customerLoginBtnClick = false;
          this.toastrService.error(data['msg']);
          form.resetForm();
        }
      })
      $("#signInModal").modal("hide");
      // form.resetForm();
    }
  }

  onForgetLinkClick() {
    this.logShow = false;
  }

  onSendBtnClick(form: NgForm) {
    this.usersService.recoverPassword(this.emailAddress).subscribe(data => {
      if (data['status'] == 200 && data['mailSent'] == true) {
        this.toastrService.success(data['msg']);
        $("#signInModal").modal("hide");
        form.resetForm();
      } else {
        this.toastrService.error(data['msg']);
        form.resetForm();
      }
    });
  }

  onBackBtnClick(form: NgForm) {
    this.logShow = true;
    form.resetForm();
  }

  onBlurUsername(field) {
    this.usersService.checkUserExist(this.registrationData.username).subscribe(data => {
      if (data['msg'] == 1) {
        this.userExist = true;
        // console.log(this.userExist);
        field.form.controls['username'].setErrors({ 'incorrect': true });
        console.log(field.form.controls['username']);
      } else if (data['msg'] == 0 && (field.form.controls['username'].value).length > 6) {
        this.userExist = false;
        // console.log(this.userExist);
        field.form.controls['username'].setErrors({ 'incorrect': false });
        field.form.controls['username'].status = "VALID";
        // console.log(field.form.controls['username']);
      }
    });
  }
  userActiveTokenExist() {
    return this.commonService.tokenExist();
  }
  userType() {
    return atob(localStorage.getItem('UD'));
  }

  allUsersLocation() {
    this.usersService.getAllUserCity().subscribe(data => {
      this.citiesObjArr = data;
      this.citiesObjArr2 = this.citiesObjArr;
      // console.log("citiesObjArr", data);
    })
  }
  onSearch() {
    this.citiesObjArr2 = this.citiesObjArr;
    if (this.searchText) {
      this.citiesObjArr2 = [];
      let searchTextList = this.citiesObjArr.filter(city =>
        city.cities.toLowerCase().includes(this.searchText.toLowerCase())
      )
      this.citiesObjArr2 = searchTextList;
      console.log(searchTextList);
    }
  }
  // onSelectCity(city){
  //   return city.replace(/\s+/g, '_');
  // }
  submitSearchedKey() {
    if (this.caviarSearch != undefined) {
      console.log(this.caviarSearch);
      this.router.navigate(['/search', this.caviarSearch]);
      this.searchBtnClick = false;
      this.caviarSearch = "";
    }
  }
  collapseMenubar() {
    $('#dorneNav').collapse('hide');
  }
  onCustomerRegister(cusForm) {
    console.log(this.cusRegistrationData);
    this.customerRegBtnClick = true;
    this.customerService.onCustomerRegistration(this.cusRegistrationData).subscribe(data => {
      console.log(data);
      this.customerDataSubmitted = data;
      if (this.customerDataSubmitted.status == 200) {
        this.toastrService.success(this.customerDataSubmitted.msg);
      } else {
        this.toastrService.error(this.customerDataSubmitted.msg);
      }
      this.customerRegBtnClick = false;
      $("#regModal").modal("hide");
      console.log(data);
      cusForm.resetForm();
    })
  }
  hideSignInModal(tabId) {
    $('#signInModal').modal('hide');
    $('#regModal').modal('show');
    // if (tabId == "customerLogin") {
    //   $('#dis_tab').removeClass('active');
    //   $('#distributor').removeClass('active');

    //   $('#cus_tab').addClass('active');
    //   $('#customer').addClass('active');
    // }
    // if (tabId == "userLogin") {
    //   if ($('#cus_tab').hasClass('active')) {
    //     $('#cus_tab').removeClass('active');
    //     $('#customer').removeClass('active');
    //   }
    //   if (!$('#cus_tab').hasClass('active')) {
    //     $('#dis_tab').addClass('active');
    //     $('#distributor').addClass('active');
    //   }
    // }
  }
}

