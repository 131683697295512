import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ProductsService } from '../services/products.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  public currrentUser:string;
  public currrentUsername:string;
  public response:any;
  public dropdownBtnClicked:boolean;
  public dropdownBtnClicked1:boolean;
  public dropdownBtnClicked2:boolean;
  public dropdownBtnClicked3:boolean;
  public dropdownBtnClicked4:boolean;
  public dropdownBtnClicked5:boolean;
  public dropdownBtnClicked6:boolean;
  public dropdownBtnClicked7:boolean;
  public dropdownBtnClicked8:boolean;
  //public arrow_down:boolean;
  public store_name:string;
  public store_details:any;


  profile_image = '../assets/avatar.png';//by default image
  public license_no:string;

  constructor(private usersService:UsersService,private route: Router,private productsService: ProductsService) {
    this.usersService.listen().subscribe((value:any) =>{ this.profile_image=value;});//set image to sidebar profile_image
   }

  ngOnInit() {
    this.dropdownBtnClicked=false;
    this.dropdownBtnClicked1=false;
    this.dropdownBtnClicked2=false;
    this.dropdownBtnClicked3=false;
    this.dropdownBtnClicked4=false;
    this.dropdownBtnClicked5=false;
    this.dropdownBtnClicked6=false;
    this.dropdownBtnClicked7=false;
    this.dropdownBtnClicked8=false;

    this.currrentUser = localStorage.getItem("currentUser");
    console.log("Usename:-",this.currrentUser);
    //this.currrentUsername = this.currrentUser.charAt(0).toUpperCase() + this.currrentUser.slice(1)//conver first letter in uppercase
    this.license_no=localStorage.getItem('CurrentUserLicense');
    this.getimage(this.license_no);
    this.getStoreDetails(this.license_no);
  }
  getimage(license_no) {
    this.usersService.getProfileImage(license_no).subscribe(res=>{
      this.response=res;
      //console.log(this.response[0].profile_image);
      if(this.response[0].profile_image=='../assets/avatar.png'){
        this.profile_image ='../assets/avatar.png';
      }else{
      this.profile_image = environment.imageUrl + this.response[0].profile_image;
      }
      //this.profile_image=environment.imageUrl + this.response[0].profile_image;
      //console.log(this.profile_image);
    });
  }

  getStoreDetails(license_no){
    this.productsService.getStoreName(license_no).subscribe(res=>{
       //console.log(res);
       this.store_details=res;
      this.store_name=this.store_details[0].store_name;
      console.log("Store name:-",this.store_name);
    });
  }
  showArrowDash(){
    this.dropdownBtnClicked=!this.dropdownBtnClicked;
    this.dropdownBtnClicked1=false;
    this.dropdownBtnClicked2=false;
    this.dropdownBtnClicked3=false;
    this.dropdownBtnClicked4=false;
    this.dropdownBtnClicked5=false;
    this.dropdownBtnClicked6=false;
    this.dropdownBtnClicked7=false;
    this.dropdownBtnClicked8=false;
  }
  showArrowInv(){
    this.dropdownBtnClicked1=!this.dropdownBtnClicked1;
    this.dropdownBtnClicked=false;
    this.dropdownBtnClicked2=false;
    this.dropdownBtnClicked3=false;
    this.dropdownBtnClicked4=false;
    this.dropdownBtnClicked5=false;
    this.dropdownBtnClicked6=false;
    this.dropdownBtnClicked7=false;
    this.dropdownBtnClicked8=false;
  }
  showArrowPos(){
    this.dropdownBtnClicked8=!this.dropdownBtnClicked8;
    this.dropdownBtnClicked=false;
    this.dropdownBtnClicked1=false;
    this.dropdownBtnClicked2=false;
    this.dropdownBtnClicked3=false;
    this.dropdownBtnClicked4=false;
    this.dropdownBtnClicked5=false;
    this.dropdownBtnClicked6=false;
    this.dropdownBtnClicked7=false;
  }
  showArrowOrder(){
    this.dropdownBtnClicked2=!this.dropdownBtnClicked2;
    this.dropdownBtnClicked1=false;
    this.dropdownBtnClicked=false;
    this.dropdownBtnClicked3=false;
    this.dropdownBtnClicked4=false;
    this.dropdownBtnClicked5=false;
    this.dropdownBtnClicked6=false;
    this.dropdownBtnClicked7=false;
    this.dropdownBtnClicked8=false;
  }
  showArrowRfq(){
    this.dropdownBtnClicked3=!this.dropdownBtnClicked3;
    this.dropdownBtnClicked1=false;
    this.dropdownBtnClicked2=false;
    this.dropdownBtnClicked=false;
    this.dropdownBtnClicked4=false;
    this.dropdownBtnClicked5=false;
    this.dropdownBtnClicked6=false;
    this.dropdownBtnClicked7=false;
    this.dropdownBtnClicked8=false;
  }
  showArrowMessages(){
    this.dropdownBtnClicked4=!this.dropdownBtnClicked4;
     this.dropdownBtnClicked1=false;
    this.dropdownBtnClicked2=false;
    this.dropdownBtnClicked3=false;
    this.dropdownBtnClicked=false;
    this.dropdownBtnClicked5=false;
    this.dropdownBtnClicked6=false;
    this.dropdownBtnClicked7=false;
    this.dropdownBtnClicked8=false;
  }
  showArrowHealth(){
    this.dropdownBtnClicked5=!this.dropdownBtnClicked5;
     this.dropdownBtnClicked1=false;
    this.dropdownBtnClicked2=false;
    this.dropdownBtnClicked3=false;
    this.dropdownBtnClicked4=false;
    this.dropdownBtnClicked=false;
    this.dropdownBtnClicked6=false;
    this.dropdownBtnClicked7=false;
    this.dropdownBtnClicked8=false;
  }
  showArrowDelivery(){
    this.dropdownBtnClicked6=!this.dropdownBtnClicked6;
     this.dropdownBtnClicked1=false;
    this.dropdownBtnClicked2=false;
    this.dropdownBtnClicked3=false;
    this.dropdownBtnClicked4=false;
    this.dropdownBtnClicked5=false;
    this.dropdownBtnClicked=false;
    this.dropdownBtnClicked7=false;
    this.dropdownBtnClicked8=false;
  }
  showArrowSettings(){
    this.dropdownBtnClicked7=!this.dropdownBtnClicked7;
     this.dropdownBtnClicked1=false;
    this.dropdownBtnClicked2=false;
    this.dropdownBtnClicked3=false;
    this.dropdownBtnClicked4=false;
    this.dropdownBtnClicked5=false;
    this.dropdownBtnClicked6=false;
    this.dropdownBtnClicked=false;
    this.dropdownBtnClicked8=false;
  }
  routeToAccount(){
    this.route.navigate(['dis-account']);
  }

}
