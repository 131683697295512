import { Component, OnInit } from '@angular/core';
import { RfqsService } from '../services/rfqs.service';

@Component({
  selector: 'app-distributor-order-pending',
  templateUrl: './distributor-order-pending.component.html',
  styleUrls: ['./distributor-order-pending.component.css']
})
export class DistributorOrderPendingComponent implements OnInit {

  public check_status:boolean=false;
  public license_no:string;
  public PendingOrders:any;
  public diffInMs: any;
  public diffInHours: any;
//   public now: Date = new Date();
//   //public dateString = '2018-12-21 13:19:14'
//  // public newDate = new Date(this.dateString);

  constructor(private rfqsService:RfqsService) { 
    //this.now = new Date();
    //console.log(this.newDate);
    //setInterval(() => {
    //   this.now = new Date();
    //   //console.log(this.now);
    //   let diffInMs: number = Date.parse(this.now) - Date.parse(this.newDate);
    //   let diffInHours: number = diffInMs / 1000 / 60 / 60;
    //   console.log(diffInHours);
    // }, 1);

    // //working
    // let date1: string = '2018-12-21 13:18:31';
    // let date2: string = '2018-12-21 13:27:36';
    // let diffInMs: number = Date.parse(date2) - Date.parse(date1);
    // let diffInM:number = diffInMs / 1000 / 60;//Minute Difference
    // let diffInHours: number = diffInMs / 1000 / 60 / 60;//hour diffrence
    // console.log(diffInHours, "", diffInM);

    // let date1 = new Date(this.now).getTime();
    // let date2 = new Date('2018-12-21 13:27:36').getTime();
    // let time = date1 - date2;  //msec
    // let hoursDiff = time / (3600 * 1000);
    // //let diffInM:number = time / 1000 / 60;//Minute Difference
    // console.log(hoursDiff);
  }

  ngOnInit() {
    this.license_no=localStorage.getItem('CurrentUserLicense');
    console.log("license_no",this.license_no);
    this.getPendingOrders(this.license_no);
  }

  getPendingOrders(license_no){
    this.rfqsService.getDistributorPendingOrders(license_no).subscribe(res =>{
      console.log(res);
      this.PendingOrders=res;

      if(this.PendingOrders.length > 0){
        this.check_status=false;//if array length >0 then show result
        this.PendingOrders = res;
      }else{
        this.check_status=true;//otherwise no data found on ui
      }
      //console.log(this.allRfqs[0]);
    });
  }
//modified by deborshi....
  getRfqRaiseDifferece(raiseTime){
    console.log("raise_time",raiseTime);
    var raise=+raiseTime;
    var current_epoch_time = Math.floor(Date.now() / 1000);
    var timeleft = current_epoch_time - raise ;
    var leftParticipateTime = this.ConvertSectoDay(timeleft);//approx time because of math .ceil retturn
    return leftParticipateTime;
  }
  ConvertSectoDay(n) 
    { 
        var day = n / (24 * 3600); 
        n = n % (24 * 3600); 
        var hour = n / 3600; 
        n %= 3600; 
        var minutes = n / 60 ; 
        n %= 60; 
        var seconds = n; 
        
        let d=Math.trunc(day);
        let h=Math.trunc(hour);
        let m=Math.trunc(minutes);
        let s=Math.trunc(seconds);
        
        console.log("before trunc original output",day + " " + "days " + hour  + " " + "hours " + minutes + " "+ "minutes " + seconds + " "+ "seconds "); 
        return d + " " + "days " + h + " " + "hours " + m + " "+ "minutes " + s + " "+ "seconds "
    } 

}
