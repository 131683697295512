import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { ToastrService } from 'ngx-toastr';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-cus-delivery-address',
  templateUrl: './cus-delivery-address.component.html',
  styleUrls: ['./cus-delivery-address.component.css']
})
export class CusDeliveryAddressComponent implements OnInit {
  public customerLicenseNo: string;
  //public addressArr = [];
  public toastMsg: string;
  public submitButtonToggle: boolean = false;
  public savedAddress:any;
  public delAddr1:any;
  public delAddr2:any;
  public delAddr3:any;
  public delAddr4:any;

  constructor(private customerService: CustomerService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.customerLicenseNo = localStorage.getItem('CurrentUserLicense');
    console.log('CurrentUserLicense', this.customerLicenseNo);
    this.getAddress();
  }
  public handleAddressChange(address: Address) {
    console.log(address);
  }
  getAddress() {
    this.customerService.getSaveDeliveryAddress(this.customerLicenseNo).subscribe(res => {
      console.log("CustomerSavedAddress",res);
      this.savedAddress=res;
      if(this.savedAddress[0].del_addr1 != null || "" || this.savedAddress[0].del_addr2 != null || "" || this.savedAddress[0].del_addr3 != null || "" || this.savedAddress[0].del_addr4 != null || ""){
        this.delAddr1=this.savedAddress[0].del_addr1;
        this.delAddr2=this.savedAddress[0].del_addr2;
        this.delAddr3=this.savedAddress[0].del_addr3;
        this.delAddr4=this.savedAddress[0].del_addr4;
      }
    });
  }

  sendValues(address1, address2, address3, address4) {
    console.log(address1.value, address2.value, address3.value, address4.value);
    if (address1.value == "") {
      this.toastr.error("You need to provide at least One Address");
    } else {
      let data = {
        del_addr1: address1.value,
        del_addr2: address2.value,
        del_addr3: address3.value,
        del_addr4: address4.value,
        customerId: this.customerLicenseNo
      };
      console.log("send_datas", data);
      this.customerService.updateCustomerDeliveryAddess(data).subscribe(res => {
        console.log(res);
        this.toastMsg = res['msg'];
        if (res['status'] == "200") {
          this.toastr.success(this.toastMsg);
          // setTimeout(() => {
          //   this.router.navigate(['existing']);
          // },
          //   4000);
        }
        else if (res['status'] == "400") {
          this.toastr.error(this.toastMsg);
        }

      });
    }
  }

}
