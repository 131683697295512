import { Component, OnInit } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Http, Headers, RequestOptions } from "@angular/http";
import { FileHolder } from 'angular2-image-upload';
import { Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../services/common.service';
import { ProductsService } from '../services/products.service';
import { environment } from '../../environments/environment';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css']
})
export class AddproductComponent implements OnInit {
  images_array = ["", "", "", "", "", ""];
  selectedProduct: string = '';
  selectedUnit: string = '';
  selectedCategory: string = '';
  imageSrc1: any = '../assets/pro-image-default.png';//by default image
  imageSrc2: any = '../assets/pro-image-default.png';//by default image
  imageSrc3: any = '../assets/pro-image-default.png';//by default image
  imageSrc4: any = '../assets/pro-image-default.png';//by default image
  imageSrc5: any = '../assets/pro-image-default.png';//by default image
  imageSrc6: any = '../assets/pro-image-default.png';//by default image
  selectedFile: File;
  public productlink: string;
  public productdesc: string;
  public productcategory: string;
  public setTitle: string;
  public strains: any;
  public fitered_strains: any;
  public all_strains = [];
  public distibutor_strains: any;
  public image_status = [];
  public url_file_name = [];
  public toastMsg: string;
  public upload_image_url: string;
  public license_no: string;
  public selectStrainType: string;
  public first_seleted_strain: string;
  public clearSrtain: string;
  public imgDimensions: any;
  public convertDimensions: any;
  uploadedImage: Blob;
  public cat_drop: string;

  Facilities = ["Accepts Credit Cards", "Wireless Internet", "Private Parking", "Wheelchair Accessible", "Bike Parking", "Reservation", "Smoking Area", "Coupons"];
  public seletedFacilities = [];
  prefferedFacilities: any;
  preFacilities = [];
  strain_types = [];
  unit = [];
  price = [];
  offerprice = [];
  store_array: any;
  public store_id: string;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};
  public userArray = [];
  public allLicense:any=[];

  categories = ["All", "Hybrid", "Indica", "Sativa"];
  strain_sell_type = ["Select", "Flower", "Concentrates", "Pre-Rolls", "Edibles", "Clones"];
  units = ["Select", "1g", "Gram", "Two Grams", "Eighth", "Quarter", "Half", "Ounce", "1/4g", "1/8th-3.5g", "5g", "1.75"];

  constructor(private http: Http, private router: Router, private toastr: ToastrService,
    private commonService: CommonService,
    private productsService: ProductsService,
    private ng2ImgMax: Ng2ImgMaxService,
    public sanitizer: DomSanitizer,
    private spinnerService: Ng4LoadingSpinnerService,
    private usersService: UsersService) { }

  ngOnInit() {
    this.getAllStrains();
    this.spinnerService.show();
    this.showDefaultImage();
    this.cat_drop = "All";
    // setTimeout(() => {
    //     /** spinner ends after 5 seconds */
    //     this.spinnerService.hide();
    // }, 5000);
    this.first_seleted_strain = "";
    var decoded_token = this.commonService.getDecodedAccessToken();
    //console.log("decoded_token", decoded_token);
    // this.spinner.show();
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    this.getProducts();//get all strains from database
    this.getStoreFacilities();
    this.getStoreId(this.license_no);
    this.newAttribute.strainType = "Select";//by default show select in strainType
    this.newAttribute.unit = "Select";//by default  select in unit
    this.newAttribute.price = "";//by default  blanke("") in price
    this.newAttribute.offerprice = "";//by default  blanke("") in offerprice

  }
  getAllStrains() {
    this.usersService.getAllStrainDetails().subscribe(res => {
      console.log(res);
    });
  }

  getProducts() {
    this.clearfield();
    this.all_strains = [];
    this.productsService.getAllProducts().subscribe(data => {
      this.strains = data;
      for (var j = 0; j < this.strains.length; j++) {
        this.all_strains.push(this.strains[j].name_long);
      }
      // this.spinner.hide();//hide spinner after getting starins dropdown item
      //console.log("All_strains",this.all_strains);
    })
  }
  getStoreId(license_number) {
    this.productsService.getdisStoreContent(license_number).subscribe(data => {
      console.log("Store_details_for_store_id", data);
      this.store_array = data;
      this.store_id = this.store_array[0].id;
    });
    this.spinnerService.hide();
  }
  getStoreFacilities() {
    this.productsService.getStoreFacilities(this.license_no).subscribe(res => {
      this.prefferedFacilities = res[0].facilities;
      //console.log("check facility is blank or not", this.prefferedFacilities);
      if (this.prefferedFacilities != "") {
        this.preFacilities = JSON.parse(this.prefferedFacilities);
        this.seletedFacilities = this.preFacilities;
      }
      //console.log(this.time);
      console.log("facilities", this.preFacilities);
    });
  }
  addFieldValue(i) {
    if (this.newAttribute.strainType == "Select" || this.newAttribute.unit == "Select"
      || this.newAttribute.price == "") {
      this.toastr.error("Please select valid product details");
    } else if (this.newAttribute.offerprice > this.newAttribute.price) {
      this.toastr.error("Offer price can not be greater than price");
    } else {
      this.fieldArray.push(this.newAttribute);
      console.log(this.fieldArray);
      this.newAttribute = {};
      if (this.first_seleted_strain == "") {
        this.newAttribute.strainType = "Flower";////on new row add  select strainType from strainChange
      } else {
        this.newAttribute.strainType = this.first_seleted_strain;
      }
      (<HTMLInputElement>document.getElementById("newAttributeStrainType")).disabled = true;
      this.newAttribute.unit = "Select";////on new row add select in unit
      this.newAttribute.price = "";////on new row add blank("") select in price
      this.newAttribute.offerprice = "";////on new row add blank("") select in offerprice
      console.log(this.fieldArray);
    }
  }
  deleteFieldValue(index) {
    this.fieldArray.splice(index, 1);
    console.log(this.fieldArray);
  }
  strainChange(event: any) {
    this.fieldArray = [];
    console.log(this.fieldArray);
    console.log(event.target.value);
    this.first_seleted_strain = event.target.value;
    this.newAttribute.strainType = this.first_seleted_strain;
    if (this.first_seleted_strain == "Flower") {
      this.units = ["Select", "1g", "Gram", "Two Grams", "Eighth", "Quarter", "Half", "Ounce", "1/4g", "1/8th-3.5g", "5g", "1.75"];
    }
    if (this.first_seleted_strain == "Concentrates") {
      this.units = ["Select", "1g", "Gram", "1/2Gram", "250mg", "300mg", "500mg", ".3"];
    }
    if (this.first_seleted_strain == "Pre-Rolls") {
      this.units = ["Select", "One"];
    }
    if (this.first_seleted_strain == "Edibles") {
      this.units = ["Select", "One", "1Bar"];
    }
    if (this.first_seleted_strain == "Clones") {
      this.units = ["Select", "One"];
    }
  }
  selectedStrainType = this.strain_sell_type[0];
  onChangeStrainType(newObj) {
    this.newAttribute.unit = "Select";//on change strain type show select on unit
    console.log(newObj);
    this.selectedStrainType = newObj;
    if (this.selectedStrainType == "Flower") {
      this.units = ["Select", "1g", "Gram", "Two Grams", "Eighth", "Quarter", "Half", "Ounce", "1/4g", "1/8th-3.5g", "5g", "1.75"];
    }
    if (this.selectedStrainType == "Concentrates") {
      this.units = ["Select", "1g", "Gram", "1/2Gram", "250mg", "300mg", "500mg", ".3"];
    }
    if (this.selectedStrainType == "Pre-Rolls") {
      this.units = ["Select", "One"];
    }
    if (this.selectedStrainType == "Edibles") {
      this.units = ["Select", "One", "1Bar"];
    }
    if (this.selectedStrainType == "Clones") {
      this.units = ["Select", "One"];
    }
  }
  selecteddUnit = this.units[0];
  onChangeUnit(newUnit) {
    this.selecteddUnit = newUnit;
  }
  selectCategory(event: any) {
    (<HTMLInputElement>document.getElementById("select_product")).disabled = false;
    this.selectedCategory = event.target.value;//store selected category on change
    if (this.selectedCategory == "All") {
      this.getProducts();
    } else {
      this.clearfield();//clear all fields if category is changed
      this.all_strains = [];
      //console.log(this.selectedCategory);
      this.productsService.getFilterdStrains(this.selectedCategory).subscribe(res => {
        this.fitered_strains = res;
        for (var j = 0; j < this.fitered_strains.length; j++) {
          this.all_strains.push(this.fitered_strains[j].name_long);
        }
        //console.log("Filtered_strains",this.all_strains);
      });
    }
  }
  selectProduct(event: any) {
    this.selectedProduct = event.target.value;
    if (this.all_strains.includes(this.selectedProduct)) {
      console.log(this.selectedProduct);
      (<HTMLInputElement>document.getElementById("select_product")).disabled = true;
      this.setTitle = this.selectedProduct;

      for (var i = 0; i < this.strains.length; i++) {
        if (this.strains[i].name_long === this.selectedProduct) {
          this.productdesc = this.strains[i].description;
          this.productcategory = this.strains[i].category;
          //this.url = this.urls.imageurl + this.strains[i].top_banner;//it will show product image from db
        }
      }
    } else {
      console.log("not present on array");
      this.toastr.error("Please select a valid product");
      this.clearfield();
    }
    //console.log(this.productcategory);
  }
  getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image()
      i.onload = function () {
        resolved({ w: i.width, h: i.height })
      };
      i.src = file
    })
  }
  imageDimensionValidation(imgDimensions) {
    if (this.imgDimensions.h < 1000) {
      return false;
    } else if (this.imgDimensions.w < 1000) {
      return false;
    } else {
      return true;
    }
  }
  convertImageDimension(imgDimensions) {
    //console.log(Math.min(this.imgDimensions.h, this.imgDimensions.w));
    var minVlaue = Math.min(this.imgDimensions.h, this.imgDimensions.w);
    if (this.imgDimensions.h == minVlaue) {
      this.imgDimensions.h = 1000;
      this.imgDimensions.w = this.imgDimensions.w;
    } else {
      this.imgDimensions.h = this.imgDimensions.h;
      this.imgDimensions.w = 1000;
    }
    //console.log("height:", this.imgDimensions.h, "width:", this.imgDimensions.w);
    return ({ w: this.imgDimensions.w, h: this.imgDimensions.h });
  }
  showImageAcceptError() {
    this.toastr.error("Image height and width must be equal or greater than 1000 * 1000 pixels");
  }
  showDefaultImage() {
    document.getElementById("loader").style.display = "none";
    document.getElementById("myDiv").style.display = "block";
  }
  showImageLoader() {
    document.getElementById("loader").style.display = "block";
    document.getElementById("myDiv").style.display = "none";
  }
  readImage1(event): void {
    //this.spinnerService.show();
    this.showImageLoader();
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc1 = uploadedImagereader.result;
                this.images_array.splice(0, 1, uploadedImagereader.result);
                //this.spinnerService.hide();
                this.showDefaultImage();
                console.log(this.images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
          this.showDefaultImage();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  readImage2(event): void {
    // if (event.target.files && event.target.files[0]) {
    //   const file = event.target.files[0];
    //   const reader = new FileReader();
    //   reader.onload = (e: any) => {
    //     this.imageSrc2 = reader.result;
    //     this.images_array.splice(1, 0, e.target.result);
    //   }
    //   reader.readAsDataURL(file);
    //   console.log(this.images_array);
    // }
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc2 = uploadedImagereader.result;
                this.images_array.splice(1, 1, uploadedImagereader.result);
                console.log(this.images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
          this.showDefaultImage();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  readImage3(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc3 = uploadedImagereader.result;
                this.images_array.splice(2, 1, uploadedImagereader.result);
                console.log(this.images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
          this.showDefaultImage();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  readImage4(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc4 = uploadedImagereader.result;
                this.images_array.splice(3, 1, uploadedImagereader.result);
                console.log(this.images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
          this.showDefaultImage();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  readImage5(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc5 = uploadedImagereader.result;
                this.images_array.splice(4, 1, uploadedImagereader.result);
                console.log(this.images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
          this.showDefaultImage();
        }
      }
      reader.readAsDataURL(file);
    }
  }
  readImage6(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc6 = uploadedImagereader.result;
                this.images_array.splice(5, 1, uploadedImagereader.result);
                console.log(this.images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
          this.showDefaultImage();
        }
      }
      reader.readAsDataURL(file);
    }
  }
  removeImage(i) {
    console.log("image index number", i + "is removed");
    this.images_array.splice(i, 1, "");
    if (i == 0) {
      this.imageSrc1 = '../assets/pro-image-default.png';
      this.images_array.splice(i, 1, "");
    }
    if (i == 1) {
      this.imageSrc2 = '../assets/pro-image-default.png';
      this.images_array.splice(i, 1, "");
    }
    if (i == 2) {
      this.imageSrc3 = '../assets/pro-image-default.png';
      this.images_array.splice(i, 1, "");
    }
    if (i == 3) {
      this.imageSrc4 = '../assets/pro-image-default.png';
      this.images_array.splice(i, 1, "");
    }
    if (i == 4) {
      this.imageSrc5 = '../assets/pro-image-default.png';
      this.images_array.splice(i, 1, "");
    }
    if (i == 5) {
      this.imageSrc6 = '../assets/pro-image-default.png';
      this.images_array.splice(i, 1, "");
    }
    console.log("afrter remove image new array:", this.images_array);
  }

  getValues(e, title, productTitle, desc, keywords, qty, price, offerprice) {
    console.log(productTitle.value);
    console.log("len", this.fieldArray.length);
    //console.log(title.value, desc.value, keywords.value, this.selectedUnit, qty.value, price.value, offerprice.value);
    if (desc.value == "") {
      this.toastr.error("Description is blank");
    } else if (keywords.value == "") {
      this.toastr.error("Keywords is blank");
    } else if (this.fieldArray.length == 0) {
      this.toastr.error("Please select valid product details");
    }
    else {
      let data = {
        title: title.value,
        product_title: productTitle.value,
        store_id: this.store_id,
        category: this.productcategory,
        description: desc.value,
        keyword: keywords.value,
        products: this.fieldArray,
        image_name: this.images_array,
        license_no: this.license_no
      };
      console.log("send_datas", data);//pass data to save data to database

      this.productsService.setDistributorStrain(data).subscribe(res => {
        this.distibutor_strains = res;
        console.log(this.distibutor_strains);
        this.toastMsg = res['msg'];
        if (res['status'] == "200") {
          this.toastr.success(this.toastMsg);
          setTimeout(() => {
            this.router.navigate(['existing']);
          },
            4000);
        }
        else if (res['status'] == "400") {
          this.toastr.error(this.toastMsg);
        }
      });
    }
  }
  reselectProduct() {
    //console.log("product reslected");
    this.cat_drop = "All";
    this.getProducts();//category reset so all strains are required;
    (<HTMLInputElement>document.getElementById("select_product")).disabled = false;
    this.clearfield();
  }
  clearfield() {//by default it called from getProduct();
    this.selectedProduct = "";//disabled all field except some field
    this.fieldArray = [];//clear select product array
    this.newAttribute.strainType = "Select";//set strain sell type Select using [(ngModel)]
    this.clearSrtain = "Select";//new row field value flower using [(ngModel)]
    (<HTMLInputElement>document.getElementById("select_product")).value = "";
    (<HTMLInputElement>document.getElementById("title")).value = "";
    (<HTMLInputElement>document.getElementById("productTitle")).value = "";
    (<HTMLInputElement>document.getElementById("description")).value = "";
    (<HTMLInputElement>document.getElementById("keyword")).value = "";
    this.imageSrc1 = '../assets/pro-image-default.png';
    this.imageSrc2 = '../assets/pro-image-default.png';
    this.imageSrc3 = '../assets/pro-image-default.png';
    this.imageSrc4 = '../assets/pro-image-default.png';
    this.imageSrc5 = '../assets/pro-image-default.png';
    this.imageSrc6 = '../assets/pro-image-default.png';
    this.images_array = ["", "", "", "", "", ""];
  }
}
