import { Component, OnInit } from '@angular/core';
import { RfqsService } from '../services/rfqs.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-distributor-all-rfqs',
  templateUrl: './distributor-all-rfqs.component.html',
  styleUrls: ['./distributor-all-rfqs.component.css']
})
export class DistributorAllRfqsComponent implements OnInit {
  public ActiveRfqResult: boolean = false;
  public EndRfqResult: boolean = false;
  public license_no: string;
  public allRfqs: any;
  public diffInMs: any;
  public diffInHours: any;
  public endRfqs: any = [];
  public activeRfqs: any = [];
  public activeRfqsTab = true;
  public endRfqsTab = false;
  public highlightTab: number;

  constructor(private route: Router, private rfqsService: RfqsService) { }

  ngOnInit() {
    this.highlightTab = 0;
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    this.getrfqs(this.license_no);//get all rfq from database
  }

  getrfqs(license_no) {
    this.rfqsService.getRfqForDistributor(license_no).subscribe(res => {
      this.allRfqs = res;
      if (this.allRfqs.length > 0) {
        this.allRfqs = res;
        console.log("all_rfqs", this.allRfqs);
        for (var i = 0; i < this.allRfqs.length; i++) {
          var diff = this.getRemainingTime(this.allRfqs[i].rfq_expires_at);//sort all rfq by expiry time check
          //console.log(diff);
          if (diff <= 0 || this.allRfqs[i].contest_status == "over") {
            // || this.allRfqs[i].contest_result =="over" || this.allRfqs[i].contest_result =="winner"
            this.endRfqs.push(this.allRfqs[i]);
          } else if (diff > 0 ) {
            this.activeRfqs.push(this.allRfqs[i]);   
          }
        }
        if(this.activeRfqs.length > 0){
          this.ActiveRfqResult = false;//if array length >0 then show result
        }else{
          this.ActiveRfqResult = true;//otherwise no data found on active tab
        }
        //console.log(this.activeRfqs.length);
        if(this.endRfqs.length > 0){
          this.EndRfqResult = false;//if array length >0 then show result
        }else{
          this.EndRfqResult = true;//otherwise no data found on end tab
        }
        //console.log(this.endRfqs.length);
        console.log("active_rfqs", this.activeRfqs);
        console.log("end_rfqs", this.endRfqs);
      } else {
        this.ActiveRfqResult = true;//otherwise no data found on active tab
        this.EndRfqResult = true;//otherwise no data found end tab
      }
    });
  }
  getRemainingTime(database_expire_time) {
    var current_epoch_time = Math.floor(Date.now() / 1000);
    var timeleft = database_expire_time - current_epoch_time;
    return timeleft;
  }

  getLeftParicipateTime(database_expire_time) {//get remaining time call from ui
    var current_epoch_time = Math.floor(Date.now() / 1000);
    var timeleft = database_expire_time - current_epoch_time;
    var leftParticipateTime = this.secondsToHms(timeleft);//convert remaining time to hour second minute format
    return leftParticipateTime;
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s >= 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  ActiveTabClicked(a) {
    this.highlightTab = a;
    this.activeRfqsTab = true;
    this.endRfqsTab = false;
  }
  EndTabClicked(b) {
    this.highlightTab = b;
    this.activeRfqsTab = false;
    this.endRfqsTab = true;
  }

  setValuetoLocalStorage(id, customer_id, distributor_product_image ,rfq_product_image, title, product_title, quantity, unit, quote_price,del_addr1,del_addr2,del_addr3,del_addr4,rfq_expire_time) {//save value to local storage call from ui
    if (rfq_expire_time == 0) {
      var patricipationMsg = 'Participation time over';
    } else {
      patricipationMsg = rfq_expire_time;
    }
    var datas = { id: id, contest_id: id, customer_id: customer_id, distributor_product_image: distributor_product_image,rfq_product_image: rfq_product_image, title:title, product_title: product_title, quantity: quantity, unit: unit, quote_price: quote_price,del_addr1 :del_addr1,del_addr2:del_addr2,del_addr3:del_addr3,del_addr4:del_addr4,rfq_expire_at: patricipationMsg }​​​​​​​;
    localStorage.setItem("rfq_product_details", JSON.stringify(datas));
    console.log(datas);
    this.route.navigate(['rfq-participation']); 
  }
}
