import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from '../../../environments/environment';
import { GrayGmapStyleModel } from 'src/app/models/gray-gmap-style-model';
@Component({
  selector: 'app-pub-single-strain-details',
  templateUrl: './pub-single-strain-details.component.html',
  styleUrls: ['./pub-single-strain-details.component.css']
})
export class PubSingleStrainDetailsComponent implements OnInit {
  name: string;
  category: string;
  private sub: any;
  public strainDetails: any = {};
  strainShortName: string = '';
  strainProductParent: any = [];
  totalStrainLineage: any = [];
  singleStrain: any;

  strainEffectKeys: any;
  strainEffectValues: any;
  strainMedicalKeys: any;
  strainMedicalValues: any;
  strainNegativeKeys: any;
  strainNegativeValues: any;
  bannerUrl: string = '';
  scrollTopBtnEnable: boolean = false;
  availability: string = "availability";
  mapStoresIds: any = [{}];
  mapStoresIdsArr = [];
  mapStoresDetails: any = [{}];
  lat=34.0201613;
  lng=118.6919205;

  icon = {
    url: 'assets/img/gmap-img/weedbuys-gmarker.png',
    scaledSize: {
      width: 50,
      height: 50
    }
  };
  styles: any[] = GrayGmapStyleModel.grayStyles;
  previous: any;
  totalStores:number=0;
  // scroll to top
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (window.pageYOffset > 700) {
      this.scrollTopBtnEnable = true;
    }
    else {
      this.scrollTopBtnEnable = false;
    }
  }

  constructor(private route: ActivatedRoute, private productsService: ProductsService, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.name = params['name'];
      this.name=this.name.replace(/_/g, ' ');
      this.category = params['category'];
      console.log(this.name);
    });
    this.getStrainDetails(this.name);
    this.getAllStoreIds();
  }

  getStrainDetails(name) {
    this.productsService.getSingleStrainDetails(name).subscribe(data => {
      this.strainDetails = data;
      this.strainShortName = this.strainDetails.name_short;
      this.bannerUrl = environment.strainBannerUrl + this.strainDetails.top_banner;
      this.strainEffectKeys = Object.keys(JSON.parse(this.strainDetails.effects));
      this.strainEffectValues = Object.values(JSON.parse(this.strainDetails.effects));
      this.strainMedicalKeys = Object.keys(JSON.parse(this.strainDetails.medical));
      this.strainMedicalValues = Object.values(JSON.parse(this.strainDetails.medical));
      this.strainNegativeKeys = Object.keys(JSON.parse(this.strainDetails.negatives));
      this.strainNegativeValues = Object.values(JSON.parse(this.strainDetails.negatives));
      this.strainProductParent = JSON.parse(this.strainDetails.product_parent);
      for (var i = 0; i < this.strainProductParent.length; i++) {
        this.singleStrain = [{}];
        this.productsService.getLineageByStrainShortName(this.strainProductParent[i]).subscribe(data => {
          this.singleStrain = data;
          if (this.singleStrain.length > 0)
            this.totalStrainLineage.push(this.singleStrain[0]);
        })
      }
      console.log(this.strainProductParent);
      // console.log(this.bannerUrl);
    })
  }
  toHTML(input): any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.textContent;
  }
  navigateToAvailibility(nameLong) {
    this.router.navigate(['/explore/strains', nameLong.replace(/\s+/g, '_'), this.availability])
  }
  onSingleStrainClick(category, name) {
    this.router.navigate(['/explore', category, name]);
  }
  getAllStoreIds() {
    this.productsService.getStoreIdByTitle(this.name).subscribe(data => {
      this.mapStoresIds = data;
      console.log(this.mapStoresIds);
      for (let i = 0; i < this.mapStoresIds.length; i++) {
        this.mapStoresIdsArr.push(this.mapStoresIds[i].store_id)
      }
      this.totalStores=this.mapStoresIds.length;
      this.getAllMapStores(this.mapStoresIds);
    })
  }
  getAllMapStores(mapStoresIdsArr) {
    console.log(mapStoresIdsArr)
    this.productsService.getStoreDetailById(mapStoresIdsArr).subscribe(data => {
      console.log(data);
      this.mapStoresDetails = data;
    })
  }
  parseJsonLat(str): number {
    let strParse = str;
    if (str != null) {
      var x = JSON.parse(strParse).lat;
      return +x;
    }
  }
  parseJsonLng(str) {
    let strParse = str;
    if (str != null) {
      var y = JSON.parse(strParse).lng;
      return +y;
    }
  }
  clickedMarker(infowindow) {
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
  goToSingleStoreViewPage(place,store_name){
    this.router.navigate(['/places',place.replace(/\s+/g, '_'),store_name.replace(/\s+/g, '_')]);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
