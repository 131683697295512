
import { UsersService } from '../services/users.service';
import { Http, Headers, RequestOptions } from "@angular/http";
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { google } from '@agm/core/services/google-maps-types';
import { GoogleMapService } from '../services/google-map.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ProductsService } from '../services/products.service';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  selector: 'app-dis-store-content',
  templateUrl: './dis-store-content.component.html',
  styleUrls: ['./dis-store-content.component.css']
})
export class DisStoreContentComponent implements OnInit {
  images_array = ["", "", "", "", "", ""];
  public license_number:string;
  store_array:any;
  public toastMsg: string;
  public store_content={};
  public smImg:string;
  update_images_array = [];
  public latitude:string;
  public longitude:string;
  coordinatesObj: any = {};
  storeLat: string;
  storeLng: string;
  public selected_address:string;
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  coOrdinates: any = {};
  public license_no: string;
  public store_address: string;
  public backButton:boolean=true;
  public imgDimensions: any;
  public convertDimensions: any;
  uploadedImage: Blob;

  imageSrc1 = '../assets/pro-image-default.png';//by default image
  imageSrc2 = '../assets/pro-image-default.png';//by default image

  constructor(private usersService:UsersService,private http: Http,
    private toastr: ToastrService, private route: Router,private productsService: ProductsService,
    private googleMapService:GoogleMapService,private _location: Location,
    private ng2ImgMax: Ng2ImgMaxService,) { }

  ngOnInit() {
    this.license_number=localStorage.getItem("CurrentUserLicense");
    console.log(this.license_number);
    this.getStoreContent(this.license_number);
    this.coOrdinates={"lat":"00.000000", "lng":"00.000000"};
    //console.log(this.coOrdinates.lat);
    this.storeLat="00.000000";
    this.storeLng="00.000000";
  }
  public handleAddressChange(address: Address) {
    console.log(address);
  }
  onKey(event){
    console.log(event.target.value);
    this.storeLat="00.000000";
    this.storeLng="00.000000";
  }

  getLocation(addr) {
    this.store_address=addr.value;
    //console.log(addr.value);//get value from get location button
    this.googleMapService.getCoordinateByname(addr.value).subscribe(result => {
    this.coordinatesObj = result;
    //console.log(this.coordinatesObj);
    let coordinates = this.coordinatesObj.results[0].geometry.location;
      this.storeLat = coordinates.lat;
      this.storeLng = coordinates.lng;
      //console.log(this.storeLat,this.storeLng);
      this.toastr.info('Your location is Changed,Please click update to confirm!', 'Info', {
        timeOut: 5000
      });
  });
  }

  getStoreContent(license_number){
    this.productsService.getdisStoreContent(license_number).subscribe(data =>{
          this.store_array = data;
          if(this.store_array != []){
          this.store_content=this.store_array[0];
          //console.log(this.store_content);
          //console.log("Store-CoOrintes",this.store_array[0].store_coordinates);
          if(this.store_array[0].store_coordinates!=null){
            this.store_address=this.store_array[0].store_address;
            let before_parse=this.store_array[0].store_coordinates;
            let co_ordinates=JSON.parse(before_parse);
            console.log("Store-CoOrintes",co_ordinates);
            this.storeLat=co_ordinates.lat;
            this.storeLng=co_ordinates.lng;

          }else{
            console.log("Co-ordinaes is Null");
          }
          this.imageSrc1 = environment.imageUrl + this.store_array[0].small_image;
          this.imageSrc2 = environment.imageUrl + this.store_array[0].large_image;
          }
          this.update_images_array.push(this.store_array[0].small_image, this.store_array[0].large_image);
        });
        console.log("Images from database",this.update_images_array);
        
  }

  removeImage(i) {
    if (i == 0) {
      this.imageSrc1 = '../assets/pro-image-default.png';
      this.update_images_array.splice(i, 1, "storeImage/pro-image-default.png");
      //this.smImg="storeImage";
      
    }
    if (i == 1) {
      this.imageSrc2 = '../assets/pro-image-default.png';
      this.update_images_array.splice(i, 1, "storeImage/pro-image-default.png");
    }
    console.log(this.update_images_array);
  }

  // readImage1(event): void {
  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];

  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.imageSrc1 = reader.result;
  //       //this.update_images_array.splice(0, 0, e.target.result);
  //       this.update_images_array.splice(0, 1, e.target.result);
  //     }
  //     reader.readAsDataURL(file);
  //     console.log(this.update_images_array);
  //   }
  // }
  getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image()
      i.onload = function () {
        resolved({ w: i.width, h: i.height })
      };
      i.src = file
    })
  }
  imageDimensionValidation(imgDimensions) {
    if (this.imgDimensions.h < 1000) {
      return false;
    } else if (this.imgDimensions.w < 1000) {
      return false;
    } else {
      return true;
    }
  }
  convertImageDimension(imgDimensions) {
    //console.log(Math.min(this.imgDimensions.h, this.imgDimensions.w));
    var minVlaue = Math.min(this.imgDimensions.h, this.imgDimensions.w);
    if (this.imgDimensions.h == minVlaue) {
      this.imgDimensions.h = 1000;
      this.imgDimensions.w = this.imgDimensions.w;
    } else {
      this.imgDimensions.h = this.imgDimensions.h;
      this.imgDimensions.w = 1000;
    }
    //console.log("height:", this.imgDimensions.h, "width:", this.imgDimensions.w);
    return ({ w: this.imgDimensions.w , h: this.imgDimensions.h });
  }
  showImageAcceptError(){
    this.toastr.error("Image height and width must be equal or greater than 1000 * 1000 pixels");
  }

  readImage1(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes",file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:",this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:",dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions=this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:",this.convertDimensions);
          
          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes",this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc1 = uploadedImagereader.result;
                //this.images_array.splice(0, 0, uploadedImagereader.result);
                this.update_images_array.splice(0, 1, uploadedImagereader.result);
                console.log(this.update_images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  readImage2(event): void {
    // if (event.target.files && event.target.files[0]) {
    //   const file = event.target.files[0];

    //   const reader = new FileReader();
    //   reader.onload = (e: any) => {
    //     this.imageSrc2 = reader.result;
    //     //this.update_images_array.splice(1, 0, e.target.result);
    //     this.update_images_array.splice(1, 1, e.target.result);

    //   }
    //   reader.readAsDataURL(file);
    //   console.log(this.update_images_array);

    // }
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes",file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:",this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:",dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions=this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:",this.convertDimensions);
          
          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes",this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc2 = uploadedImagereader.result;
                //this.images_array.splice(0, 0, uploadedImagereader.result);
                this.update_images_array.splice(1, 1, uploadedImagereader.result);
                console.log(this.update_images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  backClicked() {
    this._location.back();
  }

  sendValues(storename,place,about,smallimg,largeimg){
    //console.log(storename.value,place.value,about.value,smallimg.value,largeimg.value);
    this.coOrdinates.lat=this.storeLat;
    this.coOrdinates.lng=this.storeLng;
    console.log(this.coOrdinates);

    if(about.value==""){
      this.toastr.error("About field is blank");
    }else{
      let data = {
        license_number:this.license_number,
        coordinates: this.coOrdinates,
        store_address:this.store_address,
        about: about.value,
        small_image: this.update_images_array[0],
        large_image: this.update_images_array[1]
      };
      console.log("datas",data);

      this.productsService.disStoreContent(data).subscribe(res =>{
          this.toastMsg = res['msg'];
          if (res['status'] == "200") {
            this.toastr.success(this.toastMsg);
            this.backButton=false;
          }
          else if (res['status'] == "400") {
            this.toastr.error(this.toastMsg);
          }
      });
  }
  }
}
