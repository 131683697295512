import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../services/customer.service';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-cus-profile-management',
  templateUrl: './cus-profile-management.component.html',
  styleUrls: ['./cus-profile-management.component.css']
})
export class CusProfileManagementComponent implements OnInit {
  public toastMsg: string;
  public customerLicenseNo: string;

  constructor(private toastr: ToastrService,private customerService:CustomerService,private router:Router,private commonService:CommonService) { }

  ngOnInit() {
    this.customerLicenseNo = localStorage.getItem('CurrentUserLicense');
    console.log('CurrentUserLicense', this.customerLicenseNo);
  }

  sendPassword(oldPassword,newPassword){
    //console.log(oldPassword.value,newPassword.value);
    if(oldPassword.value.length == 0){
      this.toastr.error("Please provide current password");
    }
    else if(newPassword.value.length < 8){
      this.toastr.error("New password must be eight characters long");
    }else{
      let data = {
        customerId:this.customerLicenseNo,
        old_password: oldPassword.value,
        new_password: newPassword.value
      };
      console.log("send_datas",data);

      this.customerService.updateCustomerCurrentPassword(data).subscribe(res =>{
        console.log(res);
        this.toastMsg = res['msg'];
        if(res['status'] == 400){
        this.toastr.error(this.toastMsg);
        }else{
        this.toastr.success(this.toastMsg);
        this.clearfield();//clear field
        this.commonService.destroyToken();//destroy all tokens
        setTimeout(() => {
          this.router.navigate(['home']);
          this.toastr.info("Login again to Continue",'Login Again', {
            timeOut: 5000
          });
        },
          4000);
        }
        
      }); 
    }
  }
  clearfield() {
    (<HTMLInputElement>document.getElementById("oldPass")).value = "";
    (<HTMLInputElement>document.getElementById("newPass")).value = "";
  }

}
