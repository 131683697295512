import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
declare var $:any;

@Component({
  selector: 'app-cus-dashboard',
  templateUrl: './cus-dashboard.component.html',
  styleUrls: ['./cus-dashboard.component.css']
})
export class CusDashboardComponent implements OnInit {

  constructor(private spinnerService:Ng4LoadingSpinnerService) { }

  ngOnInit() {
    // this.spinnerService.show();
    // myFunction()
    var urlParams = [];//use to resolve mozzila referesh issue
    window.location.search.replace("?", "").split("&").forEach(function (e, i) {
        var p = e.split("=");
        urlParams[p[0]] = p[1];
    });
    // We have all the params now -> you can access it by name
    //console.log(urlParams["loaded"]);
    if(urlParams["loaded"]) {}else{

        let win = (window as any);
        win.location.search = '?loaded=1';
        //win.location.reload('?loaded=1');
    }
    // this.spinnerService.hide();
    // var myVar;
    // function myFunction() {
    //   myVar = setTimeout(showPage, 300000);
    // }
    
    // function showPage() {
    //   document.getElementById("loader").style.display = "none";
    //   document.getElementById("myDiv").style.display = "block";
    // }
  }

}
