import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestOptions, Http,Headers } from '@angular/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private http: Http) { }

  public onCustomerRegistrationUrl = environment.apiBaseUrl + "/register_customer";
  public loginCustomerUrl = environment.apiBaseUrl + "/customer_login";
  public getCustomerInfoUrl = environment.apiBaseUrl + "/get_customer_info_by_id";
  public updateCustomerDeliveryAddessUrl= environment.apiBaseUrl + "/update_cus_delivery_address";
  public getCustomerDeliveryAddessUrl= environment.apiBaseUrl + "/get_customer_delivery_address";
  public updateCustomerCurrentPasswordUrl= environment.apiBaseUrl + "/set_customer_update_password";
  

  onCustomerRegistration(cusData){
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(cusData);
    return this.http.post(this.onCustomerRegistrationUrl, body, options).pipe(map((res: Response) => res.json()));
  }

  loginCustomer(customerLoginInfo) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(customerLoginInfo);
    return this.http.post(this.loginCustomerUrl, body, options).pipe(map((res: Response) => res.json()));
  }

  getCustomerInfo(customerId) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getCustomerInfoUrl, { id: customerId }, options).pipe(map((res: Response) => res.json()));
  }

  updateCustomerDeliveryAddess(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.updateCustomerDeliveryAddessUrl, body, options).pipe(map((res: Response) => res.json()));
  }
  getSaveDeliveryAddress(customerId) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getCustomerDeliveryAddessUrl, { customerId: customerId }, options).pipe(map((res: Response) => res.json()));
  }
  updateCustomerCurrentPassword(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.updateCustomerCurrentPasswordUrl, body, options).pipe(map((res: Response) => res.json()));
  }
  
}
