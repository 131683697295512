import { Component, OnInit, AfterViewInit, HostListener } from '@angular/core';
import { WOW } from 'wowjs/dist/wow.min';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UsersService } from '../services/users.service';
import { CompleterItem } from 'ng2-completer';
import { Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { SeoUrlsService } from '../services/seo-urls.service';


declare var $: any;

var wow = new WOW(
  {
    boxClass: 'wow',      // animated element css class (default is wow)
    animateClass: 'animated', // animation css class (default is animated)
    offset: 0,          // distance to the element when triggering the animation (default is 0)
    mobile: true,       // trigger animations on mobile devices (default is true)
    live: true,       // act on asynchronously loaded content (default is true)
    callback: function (box) {
      // the callback is fired every time an animation is started
      // the argument that is passed in is the DOM node being animated
    },
    scrollContainer: null // optional scroll container selector, otherwise use window
  }

);
wow.init();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  items: Array<any> = [];
  stores: Array<any> = [];
  scrollTopBtnEnable: boolean = false;
  citiesObjArr: any = [];
  citiesObjArr2: any = [];
  searchText: string;
  disabled: boolean = true;
  selectedCategory: any;
  selectedPlace: string = "";
  protected searchStr: string;
  ph = "Your Destinations";
  categories = [
    // { name: 'All Categories' },
    { name: 'Cannabis Flower' },
    { name: 'Concentrates' },
    { name: 'Cannabis Pre-rolls' },
    { name: 'Cannabis Clones' },
    { name: 'Cannabis Edibles' }
  ];
  // scroll to top
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (window.pageYOffset > 700) {
      // console.log("Scroll Event", window.pageYOffset);
      this.scrollTopBtnEnable = true;
    }
    else {
      this.scrollTopBtnEnable = false;
    }
  }

  constructor(private _scrollToService: ScrollToService, private spinnerService: Ng4LoadingSpinnerService, private usersService: UsersService, private router: Router, private meta: Meta, private seoUrlsService: SeoUrlsService) {
    this.meta.updateTag({ name: 'keywords', content: 'weed,weed buy,weed buys,buy weed,marijuana,ganja,gaja,weed stores,weed market,cannabis' });
  }

  ngOnInit() {
    this.spinnerService.show();
    this.selectedCategory = this.categories[0];
    const config: ScrollToConfigOptions = {
      target: 'top'
    };
    this._scrollToService.scrollTo(config);
    // console.log(window.event);
    $('#mixedSlider').multislider({
      // endless scrolling
      continuous: false,
      // slide all visible slides, or just one at a time
      slideAll: false,
      // autoplay interval
      // 0 or 'false' prevents auto-sliding
      interval: false,
      // duration of slide animation
      duration: 750,
      // pause carousel on hover
      hoverPause: true,
      // pause above specified screen width
      pauseAbove: null,
      // pause below specified screen width
      pauseBelow: null
    });

    // method for second carousel
    $('#mixedSlider1').multislider({
      continuous: false,
      slideAll: false,
      interval: false,
      duration: 750,
      hoverPause: true,
      pauseAbove: null,
      pauseBelow: null
    });

    // method for third carousel
    $('#mixedSlider2').multislider({
      continuous: false,
      slideAll: false,
      interval: false,
      duration: 750,
      hoverPause: true,
      pauseAbove: null,
      pauseBelow: null
    });
    this.allUsersLocation();
    // this.seoUrlsService.allPossibleUrlsFetch();
    // setTimeout(function () {
    this.spinnerService.hide();
    // }, 10000);

  }
  ngAfterViewInit(): void {
    wow = new WOW({ live: false });
  }

  allUsersLocation() {
    this.usersService.getAllUserCity().subscribe(data => {
      this.citiesObjArr = data;
      // console.log("citiesObjArr", this.citiesObjArr[0].cities);
      this.citiesObjArr2 = [];
      for (let i = 0; i < this.citiesObjArr.length; i++) {
        this.citiesObjArr2.push(this.citiesObjArr[i].cities)
      }
      // console.log(this.citiesObjArr2);
    })

  }
  // onSearch() {
  //   this.citiesObjArr2 = this.citiesObjArr;
  //   if (this.searchText) {
  //     this.citiesObjArr2 = [];
  //     let searchTextList = this.citiesObjArr.filter(city =>
  //       city.cities.toLowerCase().includes(this.searchText.toLowerCase())
  //     )
  //     this.citiesObjArr2 = searchTextList;
  //     let val=this.citiesObjArr2.some(x => x.cities.toLowerCase() === this.searchText.toLowerCase())
  //     if(val==true){
  //       this.disabled=false;
  //     }else{
  //       this.disabled=true;
  //     }
  //     // console.log(val);
  //   }
  // }
  itemSelected(selected: CompleterItem) {
    let item = selected == null ? "" : selected.title;
    console.log(item);
    let val = this.citiesObjArr2.some(x => x.toLowerCase() === item.toLowerCase())
    this.selectedPlace = item;
    if (val == true) {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  }
  onSearch() {
    // console.log(this.selectedPlace);
    // console.log(this.selectedCategory.name);

    if (this.disabled == false) {
      let categoryName=$('#dropdownMenuButton').text();
      sessionStorage.setItem("Category", categoryName);
      this.router.navigate(['/places', this.selectedPlace]);
    }

  }
  onSelectCategory(selectedCategory) {
    $('#dropdownMenuButton').html(selectedCategory.toUpperCase());
  }
}
