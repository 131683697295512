import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { UsersService } from './users.service';
import { ProductsService } from './products.service';

@Injectable({
  providedIn: 'root'
})
export class SeoUrlsService {

  constructor(private http: Http, private usersService: UsersService, private productsService: ProductsService) { }
  public urlMasterArr: any = [];
  public placeUrlPrefix: string = "http://www.weedbuys.com/places/";
  public exploreUrlPrefix: string = "http://www.weedbuys.com/explore/";
  public placesNameArr: any = [];
  public placesUrlArr: any = [];
  public storesUrlArr: any = [];
  public productsUrlArr: any = [];

  public exploresUrlArr: any = [];
  public exploresAvailibilityUrlArr: any = [];

  public otherUrlArr: any = [];
  public totalUrlCount: number;
  public totalUrls: any = [];
  public seoUrls = environment.apiBaseUrl + "/get_all_urls_for_sitemap";
  public getStoresUrlByPlaceUrl = environment.apiBaseUrl + "/get_stores_url_by_place";

  allPossibleUrlsFetch() {
    this.urlMasterArr = [];
    this.totalUrls = [];
    this.allPlacesUrlFetch();
    this.allExploreUrlFetch();
    this.otherUrlFetch();
    console.log(this.urlMasterArr);
    setTimeout(() => {
      for (let i = 0; i < this.urlMasterArr.length; i++) {
        let tempArr = this.urlMasterArr[i];
        for (let j = 0; j < tempArr.length; j++) {
          this.totalUrls.push(tempArr[j]);
        }
      }
      // console.log(this.totalUrls.length);
      this.submitAllSeoUrls(this.totalUrls).subscribe(data => {
        let data1: any = data;
        console.log(data1.json());
      })
    }, 5000);
  }
  allPlacesUrlFetch() {
    this.placesUrlArr = [];
    this.storesUrlArr = [];
    this.productsUrlArr = [];
    this.usersService.getAllUserCity().subscribe(data => {
      this.placesNameArr = data;
      for (let i = 0; i < this.placesNameArr.length; i++) {
        this.placesUrlArr.push(this.placeUrlPrefix + this.placesNameArr[i].cities);
      }
    })

    this.getStoresUrlByPlace().subscribe(data => {
      let storeNames: any = data;
      for (let j = 0; j < storeNames.data.length; j++) {
        this.storesUrlArr.push(this.placeUrlPrefix + storeNames.data[j]);
      }

    })

    this.usersService.getAllStrainDetails().subscribe(data2 => {
      let productNames: any = data2;
      for (let k = 0; k < productNames.length; k++) {
        let url: string = this.placeUrlPrefix + productNames[k].category + '/' + productNames[k].store_id + '/' + productNames[k].product_title.replace(/\s+/g, '_');
        if (this.productsUrlArr.includes(url) === false) {
          this.productsUrlArr.push(url);
        }
      }
    })

    this.urlMasterArr.splice(0, 0, this.placesUrlArr);
    this.urlMasterArr.splice(1, 0, this.storesUrlArr);
    this.urlMasterArr.splice(2, 0, this.productsUrlArr);
  }

  allExploreUrlFetch() {
    this.exploresUrlArr = [];
    this.exploresAvailibilityUrlArr = [];
    let exploreUrl = "http://www.weedbuys.com/explore";
    let exploreStrainUrl = "http://www.weedbuys.com/explore/strains";
    this.exploresUrlArr.push(exploreUrl);
    this.exploresUrlArr.push(exploreStrainUrl);
    this.productsService.getStrainsList().subscribe(data => {
      let strainDetails: any = data;
      for (let i = 0; i < strainDetails.length; i++) {
        if (strainDetails[i].name_long.includes("\'") == false) {
          this.exploresUrlArr.push(this.exploreUrlPrefix + strainDetails[i].category + '/' + strainDetails[i].name_long.replace(/\s+/g, '_'));
          this.exploresAvailibilityUrlArr.push(exploreStrainUrl + '/' + strainDetails[i].name_long.replace(/\s+/g, '_') + '/availability');
        }
      }
      this.urlMasterArr.splice(3, 0, this.exploresUrlArr);
      this.urlMasterArr.splice(4, 0, this.exploresAvailibilityUrlArr);
    })
  }

  otherUrlFetch() {
    this.otherUrlArr = [];
    let contactUrl = "http://www.weedbuys.com/contact";
    // let exploreStrainUrl = "http://www.weedbuys.com/explore/strains";
    // this.exploresUrlArr.push(exploreStrainUrl);
    this.otherUrlArr.push(contactUrl);
    this.urlMasterArr.splice(5, 0, this.otherUrlArr);
  }

  submitAllSeoUrls(seoUrlsArray) {
    let url: Blob = seoUrlsArray;
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.seoUrls, { url: url }, options).pipe(map((res: Response) => res));
  }

  getStoresUrlByPlace() {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.getStoresUrlByPlaceUrl, options).pipe(map((res: Response) => res.json()));
  }
}
