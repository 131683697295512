import { Component, OnInit, HostListener } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { StrainModel } from 'src/app/models/strain-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pub-strains',
  templateUrl: './pub-strains.component.html',
  styleUrls: ['./pub-strains.component.css']
})
export class PubStrainsComponent implements OnInit {

  strainList: any[] = [];
  strainLimitPerView = 48;
  totalStrains: number;
  loadMoreBtnHide: boolean = false;
  scrollTopBtnEnable: boolean = false;
  category = {
    categoryHybrid: false,
    categorySativa: false,
    categoryIndica: false,
  }
  filteredStrainList: any[] = [];
  remainingStrains:number;
 

  // scroll to top
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (window.pageYOffset > 700) {
      // console.log("Scroll Event", window.pageYOffset);
      this.scrollTopBtnEnable = true;
    }
    else {
      this.scrollTopBtnEnable = false;
    }
  }

  constructor(private productsService: ProductsService, private router: Router) { }

  ngOnInit() {
    this.allStrains();
  }

  allStrains() {
    this.productsService.getStrainsList().subscribe(data => {
      this.strainList = data as StrainModel[];
      this.totalStrains = this.strainList.length;
      this.remainingStrains=this.totalStrains-48;
      console.log(this.strainList.length);
      console.log(this.strainList);
    });
  }
  loadMoreStrains() {
    if (this.strainLimitPerView < this.totalStrains) {
      this.loadMoreBtnHide = false;
      this.remainingStrains=this.remainingStrains-48;
      this.strainLimitPerView = this.strainLimitPerView + 48;
    } else if (this.strainLimitPerView >= this.totalStrains) {
      this.loadMoreBtnHide = true;
      this.remainingStrains=0;
    }
  }
  onSingleStrainClick(category, name) {
    this.router.navigate(['/explore', category, name.replace(/\s+/g, '_')]);
  } 

}
