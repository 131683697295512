import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { ToastrService } from 'ngx-toastr';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { Router } from '@angular/router';
import { Ng2ImgMaxService } from 'ng2-img-max';

@Component({
  selector: 'app-dis-account',
  templateUrl: './dis-account.component.html',
  styleUrls: ['./dis-account.component.css']
})
export class DisAccountComponent implements OnInit {
  images_array = [];
  imageSrc1:any = '../assets/avatar.png';//by default image
  public license_no:string;
  public response:any;
  public toastMsg: string;
  public image_to_delete:string;
  public imgDimensions: any;
  public convertDimensions: any;
  uploadedImage: Blob;

  constructor(private usersService:UsersService, 
    private toastr: ToastrService,
    public sidebarComponent:SidebarComponent,
    private commonService:CommonService,
    private router:Router,
    private ng2ImgMax: Ng2ImgMaxService,) { }

  ngOnInit() {
    this.license_no=localStorage.getItem('CurrentUserLicense');
    console.log("license_no",this.license_no);
    this.getimage(this.license_no);
    
  }
  getimage(license_no) {
    this.usersService.getProfileImage(license_no).subscribe(res=>{ 
      //console.log(res);
      this.response=res;
      //console.log(this.response[0].profile_image);
      this.image_to_delete=this.response[0].profile_image
      if(this.response[0].profile_image=='../assets/avatar.png'){
        this.imageSrc1='../assets/avatar.png';
      }else{
      this.imageSrc1=environment.imageUrl + this.response[0].profile_image;
      }
    });
  }

  readImage1(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageSrc1 = reader.result;
        this.images_array.splice(0, 0, e.target.result);
      }
      reader.readAsDataURL(file);
      console.log(this.images_array);     
    }
  }

  removeImage(i) {
    if (i == 0) {
      this.imageSrc1 = '../assets/avatar.png';
      this.images_array.splice(i, 1, '../assets/avatar.png');
    }
    this.usersService.setImage(this.images_array[0]);//call set image in userService
  }

  sendImage(){
    let data = {
      license_number:this.license_no,
      profile_image: this.images_array[0]
    };
    console.log("send_datas",data);
    this.usersService.uploadProfileImage(data).subscribe(res =>{
      this.toastMsg = res['msg'];
      this.toastr.success(this.toastMsg);
    });
    this.usersService.setImage(this.images_array[0]);//call set image in userService
  }

  sendPassword(oldPassword,newPassword){
    //console.log(oldPassword.value,newPassword.value);
    if(oldPassword.value.length == 0){
      this.toastr.error("Please provide current password");
    }
    else if(newPassword.value.length < 8){
      this.toastr.error("New password must be eight characters long");
    }else{
      let data = {
        license_number:this.license_no,
        old_password: oldPassword.value,
        new_password: newPassword.value
      };
      console.log("send_datas",data);

      this.usersService.updateDistributorPassword(data).subscribe(res =>{
        console.log(res);
        this.toastMsg = res['msg'];
        if(res['status'] == 400){
        this.toastr.error(this.toastMsg);
        }else{
        this.toastr.success(this.toastMsg);
        this.clearfield();//clear field
        this.commonService.destroyToken();//destroy all tokens
        setTimeout(() => {
          this.router.navigate(['home']);
          this.toastr.info("Login again to Continue",'Login Again', {
            timeOut: 5000
          });
        },
          4000);
        }
        
      }); 
    }
  }

  // removeFromServer(){
  //   let data = {
  //     license_number:this.license_no,
  //     profile_image: this.image_to_delete
  //   };
  //   console.log("send_datas",data);
  //   this.usersService.remProfileImage(data).subscribe(res =>{
  //     console.log(res);
  //     this.toastMsg = res['msg'];
  //     this.toastr.success(this.toastMsg);
  //   });
  //   this.usersService.setImage(this.images_array[0]);//call set image in userService
  // }

  clearfield() {
    (<HTMLInputElement>document.getElementById("oldPass")).value = "";
    (<HTMLInputElement>document.getElementById("newPass")).value = "";
  }

}
