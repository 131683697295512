import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable,Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private getUserDetailsUrl = environment.apiBaseUrl + "/get_user_details";
  private registerNewUserUrl = environment.apiBaseUrl + "/register_user";
  private loginUserUrl = environment.apiBaseUrl + "/loginUser";
  private recoverPasswordUrl = environment.apiBaseUrl + "/recover_password";
  private checkUserExistUrl = environment.apiBaseUrl + "/check_valid_username";
  private uploadProfileImageUrl = environment.apiBaseUrl + "/set_profile_image";
  private getProfileImageUrl = environment.apiBaseUrl + "/get_profile_image";
  private removeProfileImageUrl = environment.apiBaseUrl + "/remove_profile_image";
  //private getProfileImageUrl = environment.apiBaseUrl + "/get_profile_image"; 
  private getAllUserCityUrl = environment.apiBaseUrl + "/get_all_user_city";
  public updateDistributorPasswordUrl = environment.apiBaseUrl + "/set_update_password";
  public getAllStrainDetailsUrl = environment.apiBaseUrl + "/get_all_strain_details";

  constructor(private http: Http) { }

  getAllStrainDetails() {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.getAllStrainDetailsUrl, options).pipe(map((res: Response) => res.json()));
  }


  getUserDetails(license) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getUserDetailsUrl, { license_no: license }, options).pipe(map((res: Response) => res.json()));
  }

  registerNewUser(registrationInfo) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(registrationInfo);
    return this.http.post(this.registerNewUserUrl, body, options).pipe(map((res: Response) => res.json()));
  }

  loginUser(userCredentials) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(userCredentials);
    return this.http.post(this.loginUserUrl, body, options).pipe(map((res: Response) => res.json()));
  }

  recoverPassword(userEmail: string) {
    // var token = localStorage.getItem('CurrentToken');
    // let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    // headers.append('Authorization', 'Bearer ' + access_token['token']);

    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.recoverPasswordUrl, { email: userEmail }, options)
      .pipe(map((res: Response) => res.json()));
  }

  checkUserExist(username: string) {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.checkUserExistUrl, { username: username }, options)
      .pipe(map((res: Response) => res.json()));
  }

  uploadProfileImage(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.put(this.uploadProfileImageUrl, body, options).pipe(map((res: Response) => res.json()));
  }

  getProfileImage(license_no) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getProfileImageUrl, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  }

  remProfileImage(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.put(this.removeProfileImageUrl, body, options).pipe(map((res: Response) => res.json()));
  }
  //by rajat to set image
  private _listners = new Subject<any>();
  listen(): Observable<any> {return this._listners.asObservable();}
  setImage(image: any) { this._listners.next(image);}

  getAllUserCity() {
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.getAllUserCityUrl, options)
      .pipe(map((res: Response) => res.json()));
  }

  updateDistributorPassword(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.updateDistributorPasswordUrl, body, options).pipe(map((res: Response) => res.json()));
  }
}
