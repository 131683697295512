import { Component, OnInit, AfterViewInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProductsService } from '../services/products.service';
import { RfqsService } from '../services/rfqs.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  deviceInfo = null;
  public license_no: string;
  public get_dis_strains: any;
  public totalProductUploads: any;
  public myRfqsResponse: any;
  public myRfqsArray: any = [];
  public totalRfqParticipant: any;
  public wonRfqsResponse:any;
  public totalRfqsWon: any;
  public PendingOrders:any;
  public totalIntent:any;

  constructor(private deviceService: DeviceDetectorService,
    private productsService: ProductsService, private rfqsService: RfqsService) {

  }
  ngOnInit() {
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    this.totalProductUploads = 0;//by default product uploads is set to be zero
    this.totalRfqParticipant = 0;
    this.totalRfqsWon = 0;
    this.totalIntent=0;
    this.pageRefresh();
    //this.CheckDevice();//check browser mobile or etc.
    this.getTotalProductUploads(this.license_no);//get number of products uploaded by deistributor
    this.getDisRfqParticipation();
    this.getWonRfqs();
    this.getIntent();
  }

  getTotalProductUploads(license_no) {
    this.productsService.getDistributorStrain(license_no).subscribe(res => {
      this.get_dis_strains = res;//store response in an array
      console.log("get_strain_details", this.get_dis_strains);
      if (this.get_dis_strains.length > 0) {
        this.totalProductUploads = this.get_dis_strains.length;
        console.log(this.totalProductUploads);
      } else {
        console.log("no product uploaded by distributor");
        //this.check_status = true;//otherwise no data found on ui
      }
    });
  }
  getDisRfqParticipation() {
    this.rfqsService.getmyRfqsForDistributor(this.license_no).subscribe(res => {
      this.myRfqsResponse = res;
      console.log(this.myRfqsResponse);
      if (this.myRfqsResponse.status == 200) {
        if (this.myRfqsResponse.data.length > 0) {
          this.totalRfqParticipant = this.myRfqsResponse.data.length;
        }
        else {
          console.log("no rfq participant data present");
        }
      } else if (this.myRfqsResponse.status == 400) {
        console.log("some error occured");
      }
    });
  }
  getWonRfqs(){
    this.rfqsService.getWonRfqForDistributor(this.license_no).subscribe(res => {
      // console.log(res);
      this.wonRfqsResponse = res;
      console.log("won_rfq_response:",this.wonRfqsResponse);
      if(this.wonRfqsResponse.status ==200){
        if (this.wonRfqsResponse.data.length > 0) {
          this.totalRfqsWon=this.wonRfqsResponse.data.length;
        }else if(this.myRfqsResponse.status ==400){
          console.log("some error occuredto fetch  won rfqs");
        }
      }else{
        console.log("some error occured");
      }
    });
  }
  getIntent(){
    this.rfqsService.getDistributorPendingOrders(this.license_no).subscribe(res =>{
      console.log(res);
      this.PendingOrders=res;
      if(this.PendingOrders.length > 0){
        this.totalIntent=this.PendingOrders.length;//if array length >0 then show result
      }else{
        //this.check_status=true;//otherwise no data found on ui
        console.log("no data present on purchase intent");
      }
    });
  }

  pageRefresh() {
    var urlParams = [];//use to resolve mozzila referesh issue
    window.location.search.replace("?", "").split("&").forEach(function (e, i) {
      var p = e.split("=");
      urlParams[p[0]] = p[1];
    });
    // We have all the params now -> you can access it by name
    //console.log(urlParams["loaded"]);
    if (urlParams["loaded"]) { } else {

      let win = (window as any);
      win.location.search = '?loaded=1';
      //win.location.reload('?loaded=1');
    }
  }
  // CheckDevice() {
  //   this.deviceInfo = this.deviceService.getDeviceInfo();
  //   const isMobile = this.deviceService.isMobile();
  //   const isTablet = this.deviceService.isTablet();
  //   const isDesktopDevice = this.deviceService.isDesktop();

  //   console.log(this.deviceInfo);
  //   console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
  //   console.log(isTablet);  // returns if the device us a tablet (iPad etc)
  //   console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  // }


  // ngAfterViewInit(){
  //   //location.reload();
  // }


}
