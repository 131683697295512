import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterStoreFlower',
  pure: true
})
export class FilterStoreStrainSellTypePipe implements PipeTransform {
  filteredStoreDetails:any[]=[];
  transform(relatedStoreDetailsArr: any[],flower:string): any {
    this.filteredStoreDetails=[];
    for (var i = 0; i < relatedStoreDetailsArr.length; i++) {
      if (relatedStoreDetailsArr[i].strain_sell_type === flower) {
        this.filteredStoreDetails.push(relatedStoreDetailsArr[i]);
      }
    }
    return this.filteredStoreDetails;
  }

}
