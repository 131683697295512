import { Injectable } from '@angular/core';
import { RequestOptions, Http, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {
  searchEngineID: string = "016937107667831384558:nvhtm07twaq";
  customSearchAPIKey: string = "AIzaSyCJoyM3j9FOrtXqnSCYplNjwrVK0J8NEAM";
  constructor(private http: Http) { }

  getCoordinateByname(placename) {
    let headers = new Headers();
    let options = new RequestOptions({ headers: headers });
    return this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + placename + "&key=" + environment.googleMapkey, options)
      .pipe(map((res: Response) => res.json()));
  }

  // getAutoCompletePlace(placename) {
  //   let headers = new Headers();
  //   let options = new RequestOptions({ headers: headers });
  //   return this.http.get("https://maps.googleapis.com/maps/api/place/autocomplete/json?input=" + placename + "&key=" + environment.googleMapkey, options)
  //     .pipe(map((res: Response) => res.json()));
  // }
  getSearchResult(searchString,pageNumber) {
    let headers = new Headers();
    let options = new RequestOptions({ headers: headers });
    return this.http.get("https://www.googleapis.com/customsearch/v1?q=" + searchString + "&cx=" + this.searchEngineID +"&start="+pageNumber+"&key=" + this.customSearchAPIKey, options)
      .pipe(map((res: Response) => res.json()));
  }
}


