import { Component, OnInit } from '@angular/core';
import { RfqsService } from '../services/rfqs.service';
import { environment } from '../../environments/environment';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-customer-bid-rfqs',
  templateUrl: './customer-bid-rfqs.component.html',
  styleUrls: ['./customer-bid-rfqs.component.css']
})
export class CustomerBidRfqsComponent implements OnInit {
  public customerId: string;
  public BidRfq: any;
  idStoreNameOnSubbmissionTime: boolean = false;
  idStoreNameExceptSubbmissionTime: boolean = true;
  public imgurl = environment.imageUrl;
  public participantStoreName: any;
  public participantquantity: any;
  public participantunit: any;
  public participantquotePrice: any;
  public participantDeliveyDistance: any;
  public participantProductImage: any;
  public individualParticipant: any;
  public hideDeliveryDistance: boolean = true;
  public chooseBestOffer: any = { value: false };
  public choosedBestOffer: any;
  public datas: any;
  public rfq_data: any;
  public rfq_product_title: any;
  public winnerRowid:any;
  public winnercontestId:any;
  public winnerChooseResponse:any;
  public radioChooseBestOffer:boolean=false;
  public getContestWinnerResponse:any;
  public winnerDiv:boolean=true;
  public winnerDetails=[];
  public winnerDistributorName:any;
  public winnerProductTitle:any;
  public winnerQuantity:any;
  public winnerUnit:any;
  public winnerCustomerQuotePrice:any;
  public winnerDistributorQuotePrice:any;
  public winnerDistributorProductImage:any;
  public imgurl1 = environment.imageUrl;
  imageSrc1: any = '../assets/winner_cup.png';//by default image
  public title: any;
  public product_title: any;
  public quantity: any;
  public unit: any;
  public quote_price: any;
  public rfq_product_image: any;

  constructor(private rfqsService: RfqsService,private toastr: ToastrService,) { }

  ngOnInit() {
    this.customerId = localStorage.getItem('CurrentUserLicense');
    console.log("CurrentCustomerLicenseNo.", this.customerId);
    this.datas = localStorage.getItem("rfqCotestIdAndTitle");
    if (this.datas.length > 0) {
      this.rfq_data = JSON.parse(this.datas);
      console.log(this.rfq_data);
      this.title = this.rfq_data.title;
      this.product_title = this.rfq_data.product_title;
      this.quantity = this.rfq_data.quantity;
      this.unit = this.rfq_data.unit;
      this.quote_price = this.rfq_data.quote_price;
      this.rfq_product_image = this.imgurl + this.rfq_data.rfq_product_image;
      console.log(this.rfq_product_image);
    }
    this.getContestWinner();
    this.getBidRfq();
    this.leafDownAnimationWinnerSection();    
  }
    getContestWinner(){
      this.rfqsService.getRfqWinnerDetails(this.rfq_data.contest_id).subscribe(res=>{
        console.log(res);
        this.getContestWinnerResponse=res;
        if(this.getContestWinnerResponse.status == 202){
          this.winnerDiv=true;
        }else if(this.getContestWinnerResponse.status == 200){
         this.winnerDetails=this.getContestWinnerResponse.data;
         this.winnerDistributorName=this.winnerDetails[0].store_name;
         this.winnerProductTitle=this.winnerDetails[0].product_title;
         this.winnerQuantity=this.winnerDetails[0].quantity;
         this.winnerUnit=this.winnerDetails[0].unit;
         this.winnerCustomerQuotePrice=this.winnerDetails[0].quote_price;
         this.winnerDistributorQuotePrice=this.winnerDetails[0].distributor_quote_price;
         this.winnerDistributorProductImage= this.imgurl1 +  this.winnerDetails[0].distributor_product_image; 
         console.log(this.winnerDetails);
         this.winnerDiv=false;
         this.radioChooseBestOffer=true;
        }
      });
    }
    getBidRfq(){
      this.rfqsService.getBidRfqForCustomer(this.rfq_data.contest_id).subscribe(res => {
        this.BidRfq = res;
        console.log("Bid Rfqs", res);
        if(this.BidRfq.length > 0){
          this.getContestWinner();
        }
      });
    }
    rfqSort(event) {
      console.log(event.target.value);
      if (event.target.value == "subssiontime") {
        this.idStoreNameOnSubbmissionTime = false;
        this.idStoreNameExceptSubbmissionTime = true;
        this.BidRfq.sort(function (obj1, obj2) {
          return obj2.participation_epoch_time - obj1.participation_epoch_time;
        });

      } else if (event.target.value == "lowestprice") {
        this.idStoreNameOnSubbmissionTime = true;
        this.idStoreNameExceptSubbmissionTime = false;
        this.BidRfq.sort(function (obj1, obj2) {
          return obj1.distributor_quote_price - obj2.distributor_quote_price;
        });
      } else if (event.target.value == "shortestdistance") {
        this.idStoreNameOnSubbmissionTime = true;
        this.idStoreNameExceptSubbmissionTime = false;
        this.BidRfq.sort(function (obj1, obj2) {
          return obj1.delivery_address_distance - obj2.delivery_address_distance;
        });
      }
      console.log(this.BidRfq);
    }
    openModal(i) {
      //console.log("modal number", i);
      $("#viewMoreModal").modal('show');
      this.individualParticipant = this.BidRfq[i];
      //console.log(this.individualParticipant);
      this.participantProductImage = this.imgurl + this.individualParticipant.distributor_product_image;
      this.participantStoreName = this.individualParticipant.store_name;
      this.participantquantity = this.individualParticipant.quantity;
      this.participantunit = this.individualParticipant.unit;
      this.participantquotePrice = this.individualParticipant.distributor_quote_price;
      if (this.individualParticipant.delivery_address_distance == "") {
        this.hideDeliveryDistance = true;
      } else {
        this.participantDeliveyDistance = this.individualParticipant.delivery_address_distance;
        this.hideDeliveryDistance = false;
      }
      //console.log(this.participantProductImage);
    }
    currentBestOffer(event, row_id,contest_id,participant_id) {
      //console.log(event.target.value);
      //console.log("row_id",row_id + " " + "contest_id",contest_id + " " + "participant_id",participant_id);
      $("#chooseOfferModal").modal('show');
      this.winnerRowid=row_id;
      this.winnercontestId=contest_id;
      // if (this.chooseBestOffer.value == true) {
      //   //this.chooseBestOffer.value == false;
      //   this.choosedBestOffer=rfqParticipationId;
      //   console.log("bestoffer",this.choosedBestOffer);
      // } else if (this.chooseBestOffer.value == false) {
      //   //this.chooseBestOffer.value == true;
      //   this.choosedBestOffer=null;
      //   console.log("bestoffer",this.choosedBestOffer);
      // }
    }
    setAsWinner(){
      $("#chooseOfferModal").modal('hide');
      var current_epoch_time = Math.floor(Date.now() / 1000);
      let data={
        row_id:this.winnerRowid,
        contest_id:this.winnercontestId,
        end_time:current_epoch_time
      }
      console.log("winner_set_data",data);
      this.rfqsService.setRfqContestWinner(data).subscribe(res=>{
        console.log("contest_winner_response",res);
        this.winnerChooseResponse=res;
        if(this.winnerChooseResponse.status == 200){
          this.radioChooseBestOffer=true;
          this.toastr.success(this.winnerChooseResponse.msg);
          this.getContestWinner();
        }else if(this.winnerChooseResponse.status == 400){
          this.toastr.error("Sorry! You already choosen best offer");
        }
      });
    }
    leafDownAnimationWinnerSection(){
      var HeartsBackground = {
        heartHeight: 60,
        heartWidth: 64,
        hearts: [],
        heartImage: 'assets/weed_leaf.png',
        maxHearts: 8,
        minScale: 0.4,
        draw: function() {
          this.setCanvasSize();
          this.ctx.clearRect(0, 0, this.w, this.h);
          for (var i = 0; i < this.hearts.length; i++) {
            var heart = this.hearts[i];
            heart.image = new Image();
            heart.image.style.height = heart.height;
            heart.image.src = this.heartImage;
            this.ctx.globalAlpha = heart.opacity;
            this.ctx.drawImage (heart.image, heart.x, heart.y, heart.width, heart.height);
          }
          this.move();
        },
        move: function() {
          for(var b = 0; b < this.hearts.length; b++) {
            var heart = this.hearts[b];
            heart.y += heart.ys;
            if(heart.y > this.h) {
              heart.x = Math.random() * this.w;
              heart.y = -1 * this.heartHeight;
            }
          }
        },
        setCanvasSize: function() {
          this.canvas.width = window.innerWidth;
          this.canvas.height = window.innerHeight;
          this.w = this.canvas.width;
          this.h = this.canvas.height;
        },
        initialize: function() {
          this.canvas = $('#canvas')[0];
      
          if(!this.canvas.getContext)
            return;
      
          this.setCanvasSize();
          this.ctx = this.canvas.getContext('2d');
      
          for(var a = 0; a < this.maxHearts; a++) {
            var scale = (Math.random() * (1 - this.minScale)) + this.minScale;
            this.hearts.push({
              x: Math.random() * this.w,
              y: Math.random() * this.h,
              ys: Math.random() + 1,
              height: scale * this.heartHeight,
              width: scale * this.heartWidth,
              opacity: scale
            });
          }
      
          setInterval($.proxy(this.draw, this), 30);
        }
      };
      
      $(document).ready(function(){
        HeartsBackground.initialize();
      });
    }
  }
