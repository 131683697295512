// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  production: false,
  //local url
  //  apiBaseUrl: "http://192.168.1.72/weedslimapi/public/api",
  //  imageUrl: "http://192.168.1.72/weedslimapi/public/",
  //  strainBannerUrl: "http://192.168.1.68/weedBuysAPI/public/uploads/",
  //  googleMapkey:"AIzaSyBq9LXuKxmY7uoVoLhr8rnvD_Z6cINcjyY"


  // //server url
  apiBaseUrl: "http://weedbuys.com/api/public/api",
  imageUrl: "http://weedbuys.com/api/public/",
  strainBannerUrl: "http://weedbuys.com/admin/api/public/uploads/",
  googleMapkey:"AIzaSyBq9LXuKxmY7uoVoLhr8rnvD_Z6cINcjyY"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
