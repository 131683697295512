import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { UsersService } from '../services/users.service';

@Directive({
  selector: '[appValueExistsCheck]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ValueExistsCheckDirective,
    multi: true
  }]
})
export class ValueExistsCheckDirective implements Validator{

  constructor( private usersService:UsersService) {};

  validate(control: AbstractControl): { [key: string]: any } | null {
    this.usersService.checkUserExist(control.value).subscribe(data=>{
      if(data['msg']==1){
        return { 'userExist': true };
      }
    })
    return { 'userExist': false };
  }
}
