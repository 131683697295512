import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestOptions, Http,Headers } from '@angular/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntentsService {
  uid="";
  uphone="";
  uemail="";

  constructor(private http: Http) { }

  public submitIntentUrl = environment.apiBaseUrl + "/get_intent";
  public getCustomerPendinOrdersUrl = environment.apiBaseUrl + "/get_customer_pending_orders";
  public getIntentAlreadyRaisedCheckUrl = environment.apiBaseUrl + "/get_intent_already_raised_check";

  submitIntent(raiseIntent){
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(raiseIntent);
    return this.http.post(this.submitIntentUrl,body, options).pipe(map((res: Response) => res.json()));
  }
  getCustomerPendingOrders(customerId) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getCustomerPendinOrdersUrl, { customer_id: customerId }, options).pipe(map((res: Response) => res.json()));
  }

  getIntentAlreadyRaisedCheck(customerId,productName,licenceNo) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getIntentAlreadyRaisedCheckUrl, { customer_id: customerId,product_name:productName,licence_no:licenceNo }, options).pipe(map((res: Response) => res.json()));
  }
}
