import { Component, OnInit } from '@angular/core';
import { Http, Headers, RequestOptions } from "@angular/http";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from '../../environments/environment';
import { Ng2ImgMaxService } from 'ng2-img-max';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dis-edit-strain',
  templateUrl: './dis-edit-strain.component.html',
  styleUrls: ['./dis-edit-strain.component.css']
})
export class DisEditStrainComponent implements OnInit {
  public datas: any;
  public send_data: any;
  public get_strain_fro_update: any;
  public update_strain_res: any;
  public toastMsg: string;
  public strainType: string;
  public unit: string;
  images_array = ["", "", "", "", "", ""];
  update_images_array = [];
  imageSrc1:any = '../assets/pro-image-default.png';//by default image
  imageSrc2:any = '../assets/pro-image-default.png';//by default image
  imageSrc3:any = '../assets/pro-image-default.png';//by default image
  imageSrc4:any = '../assets/pro-image-default.png';//by default image
  imageSrc5:any = '../assets/pro-image-default.png';//by default image
  imageSrc6:any = '../assets/pro-image-default.png';//by default image

  public license_no: string;
  public set_title: string;
  public set_description: string;
  public set_keyword: string;
  public strain_type: string;
  private fieldArray: Array<any> = [];
  private newAttribute: any = {};
  public newRowStrain: string;
  public newRowUnit: string;
  public newRowPrice: string;
  public newRowOfferPrice: string;
  public old_time_stamp: string;
  public old_category: string;
  public old_store_id: string;
  public set_product_title: string;
  public deleted_id: string;
  public deleted_row_array: Array<any> = [];
  display = 'none';
  public deleted_index: any;
  public imgDimensions: any;
  public convertDimensions: any;
  uploadedImage: Blob;

  strain_sell_type = ["Flower", "Concentrates", "Pre-Rolls", "Edibles", "Clones"];
  units = ["Select", "1g", "Gram", "Two Grams", "Eighth", "Quarter", "Half", "Ounce", "1/4g", "1/8th-3.5g", "5g", "1.75"];

  constructor(private http: Http, private router: Router, private toastr: ToastrService, private productsService: ProductsService, private ng2ImgMax: Ng2ImgMaxService, ) { }
  ngOnInit() {
    this.deleted_row_array = [];
    this.newAttribute.id = "new";
    this.license_no = localStorage.getItem('CurrentUserLicense');
    console.log("license_no", this.license_no);
    this.datas = localStorage.getItem("datas");
    if (this.datas.length > 0) {
      this.send_data = JSON.parse(this.datas);
      console.log("get data from existing page:", this.send_data);
    }
    this.getdisdataforupdate(this.send_data.title, this.send_data.productTitle, this.send_data.strainSellType);
  }
  getdisdataforupdate(title, productTitle, strainSellType) {
    let data = {
      license_number: this.license_no,
      //title: title,
      product_title: productTitle,
      strain_sell_type: strainSellType
    };
    //console.log("prepare data to send", data);
    this.productsService.getdistributorstrainforupdate(data).subscribe(res => {
      //console.log(res);
      this.get_strain_fro_update = res;
      this.fieldArray = this.get_strain_fro_update;
      //console.log(this.get_strain_fro_update);//response from server
      console.log("previous_uploaded_products:", this.fieldArray);
      this.set_title = this.get_strain_fro_update[0].title;
      this.set_product_title = this.get_strain_fro_update[0].product_title;
      this.set_description = this.get_strain_fro_update[0].description;
      this.set_keyword = this.get_strain_fro_update[0].keyword;
      this.strainType = this.get_strain_fro_update[0].strain_sell_type;
      this.old_time_stamp = this.get_strain_fro_update[0].time_stamp;
      this.old_category = this.get_strain_fro_update[0].category;
      this.old_store_id = this.get_strain_fro_update[0].store_id;
      //console.log("previous_uploaded_strain_type:",this.strainType);//response from server

      this.newAttribute.strain_sell_type = this.strainType;
      this.newAttribute.unit = "Select";
      this.newAttribute.price = "";
      this.newAttribute.offer_price = "";


      if (this.strainType == "Flower") {//set array first then show the value get from database
        this.units = ["Select", "1g", "Gram", "Two Grams", "Eighth", "Quarter", "Half", "Ounce", "1/4g", "1/8th-3.5g", "5g", "1.75"];
      }
      if (this.strainType == "Concentrates") {
        this.units = ["Select", "1g", "Gram", "1/2Gram", "250mg", "300mg", "500mg", ".3"];
      }
      if (this.strainType == "Pre-Rolls") {
        this.units = ["Select", "One"];
      }
      if (this.strainType == "Edibles") {
        this.units = ["Select", "One", "1Bar"];
      }
      if (this.strainType == "Clones") {
        this.units = ["Select", "One"];
      }

      //show images get from database
      if (this.get_strain_fro_update[0].image != "") {
        this.imageSrc1 = environment.imageUrl + this.get_strain_fro_update[0].image;
        //console.log(this.imageSrc1);
      }
      if (this.get_strain_fro_update[0].image2 != "") {
        this.imageSrc2 = environment.imageUrl + this.get_strain_fro_update[0].image2;
        //console.log(this.imageSrc2);
      }
      if (this.get_strain_fro_update[0].image3 != "") {
        this.imageSrc3 = environment.imageUrl + this.get_strain_fro_update[0].image3;
      }
      if (this.get_strain_fro_update[0].image4 != "") {
        this.imageSrc4 = environment.imageUrl + this.get_strain_fro_update[0].image4;
      }
      if (this.get_strain_fro_update[0].image5 != "") {
        this.imageSrc5 = environment.imageUrl + this.get_strain_fro_update[0].image5;
      }
      if (this.get_strain_fro_update[0].image6 != "") {
        this.imageSrc6 = environment.imageUrl + this.get_strain_fro_update[0].image6;
      }
      this.update_images_array.push(this.get_strain_fro_update[0].image, this.get_strain_fro_update[0].image2, this.get_strain_fro_update[0].image3, this.get_strain_fro_update[0].image4, this.get_strain_fro_update[0].image5, this.get_strain_fro_update[0].image6);
    });
  }

  selecteddUnit = this.units[0];
  onChangeUnit(rowId, newUnit) {
    //console.log(rowId);
    this.fieldArray[rowId].unit = String(newUnit);
    //console.log(this.fieldArray);
  }

  onChangePrice(rowId, newPrice) {
    this.fieldArray[rowId].price = String(newPrice);
    //console.log(this.fieldArray);
  }

  onChangeOfferPrice(rowId, newOfferPrice) {
    this.fieldArray[rowId].offer_price = String(newOfferPrice);
    //console.log(this.fieldArray);
  }


  //for new row which is added with existing data//////////
  onChangenewStrainType(newRowstrain) {
    this.newRowStrain = newRowstrain;
    //console.log(newRowstrain);
  }
  onChangeNewUnit(newRowunit) {
    this.newRowUnit = newRowunit;
    //console.log(newRowunit);
  }

  onChangeNewPrice(newrowPrice) {
    this.newRowPrice = newrowPrice;
    //console.log(newrowPrice);
  }

  onChangeNewOfferPrice(newRowOfferPrice) {
    this.newRowOfferPrice = newRowOfferPrice;
    //console.log(newRowOfferPrice);
  }

  addFieldValue() {
    this.fieldArray.push(this.newAttribute);
    this.newAttribute = {};
    this.newAttribute.id = "new";
    this.newAttribute.strain_sell_type = this.strainType;////on new row add  select in strainType
    this.newAttribute.unit = "Select";
    this.newAttribute.price = "";
    this.newAttribute.offer_price = "";
    console.log("new_added_value_with_previous_array:", this.fieldArray);
  }

  deleteItem(index) {
    //this.display = 'block'
    this.deleted_index = index;//save deleted value index when modal open
    Swal({
      title: 'Are you sure?',
      text: 'You want to delete this item ?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.deleted_id = this.fieldArray[this.deleted_index].id;
        console.log("id_of_the_deleted_index", this.deleted_id);
        this.fieldArray[this.deleted_index].id = "del";
        this.deleted_row_array.push(this.deleted_id);
        if (this.fieldArray.length == 1) {
          this.deleted_row_array=[];
          Swal(
            'Cancelled',
            'You can\'t delete all the items',
            'error'
          )
        } else {
          this.fieldArray.splice(this.deleted_index, 1);
          console.log("id_to_be_deleted_from_db_or_local", this.deleted_row_array);
          console.log("updated_array_send_to_db", this.fieldArray);
          Swal(
            'Deleted!',
            'Your item is in delete queue, update to confirm!.',
            'success'
          )
        }
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal(
          'Cancelled',
          'Deleting item has been cancelled',
          'error'
        )
      }
    })
  }
  onCloseHandled() {
    this.display = 'none';
  }
  getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image()
      i.onload = function () {
        resolved({ w: i.width, h: i.height })
      };
      i.src = file
    })
  }
  imageDimensionValidation(imgDimensions) {
    if (this.imgDimensions.h < 1000) {
      return false;
    } else if (this.imgDimensions.w < 1000) {
      return false;
    } else {
      return true;
    }
  }
  convertImageDimension(imgDimensions) {
    //console.log(Math.min(this.imgDimensions.h, this.imgDimensions.w));
    var minVlaue = Math.min(this.imgDimensions.h, this.imgDimensions.w);
    if (this.imgDimensions.h == minVlaue) {
      this.imgDimensions.h = 1000;
      this.imgDimensions.w = this.imgDimensions.w;
    } else {
      this.imgDimensions.h = this.imgDimensions.h;
      this.imgDimensions.w = 1000;
    }
    //console.log("height:", this.imgDimensions.h, "width:", this.imgDimensions.w);
    return ({ w: this.imgDimensions.w, h: this.imgDimensions.h });
  }
  showImageAcceptError() {
    this.toastr.error("Image height and width must be equal or greater than 1000 * 1000 pixels");
  }
  removeImage(i) {
    console.log(i);
    this.images_array.splice(i, 1, "");

    if (i == 0) {
      this.imageSrc1 = '../assets/pro-image-default.png';
      this.update_images_array.splice(i, 1, "");
    }
    if (i == 1) {
      this.imageSrc2 = '../assets/pro-image-default.png';
      this.update_images_array.splice(i, 1, "");
    }
    if (i == 2) {
      this.imageSrc3 = '../assets/pro-image-default.png';
      this.update_images_array.splice(i, 1, "");
    }
    if (i == 3) {
      this.imageSrc4 = '../assets/pro-image-default.png';
      this.update_images_array.splice(i, 1, "");
    }
    if (i == 4) {
      this.imageSrc5 = '../assets/pro-image-default.png';
      this.update_images_array.splice(i, 1, "");
    }
    if (i == 5) {
      this.imageSrc6 = '../assets/pro-image-default.png';
      this.update_images_array.splice(i, 1, "");
    }
    console.log(this.update_images_array);

  }
  // readImage1(event): void {
  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];

  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       this.imageSrc1 = reader.result;
  //       this.update_images_array.splice(0, 1, e.target.result);
  //     }
  //     reader.readAsDataURL(file);
  //   }
  // }

  readImage1(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc1 = uploadedImagereader.result;
                this.update_images_array.splice(0, 1, uploadedImagereader.result);
                console.log(this.update_images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  readImage2(event): void {
    // if (event.target.files && event.target.files[0]) {
    //   const file = event.target.files[0];

    //   const reader = new FileReader();
    //   reader.onload = (e: any) => {
    //     this.imageSrc2 = reader.result;
    //     this.update_images_array.splice(1, 1, e.target.result);
    //   }
    //   reader.readAsDataURL(file);
    // }
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc2 = uploadedImagereader.result;
                this.update_images_array.splice(1, 1, uploadedImagereader.result);
                console.log(this.update_images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  readImage3(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc3 = uploadedImagereader.result;
                this.update_images_array.splice(2, 1, uploadedImagereader.result);
                console.log(this.update_images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
        }
      }
      reader.readAsDataURL(file);
    }

  }

  readImage4(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc4 = uploadedImagereader.result;
                this.update_images_array.splice(3, 1, uploadedImagereader.result);
                console.log(this.update_images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  readImage5(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc5 = uploadedImagereader.result;
                this.update_images_array.splice(4, 1, uploadedImagereader.result);
                console.log(this.update_images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
        }
      }
      reader.readAsDataURL(file);
    }
  }
  readImage6(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("before_resize_size_in_bytes", file.size);
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        this.imgDimensions = await this.getImageDimensions(reader.result);
        console.log("before_change_dimension:", this.imgDimensions);
        var dimentionAccept = this.imageDimensionValidation(this.imgDimensions);
        console.log("dimension_accept_state:", dimentionAccept);
        if (dimentionAccept == true) {
          this.convertDimensions = this.convertImageDimension(this.imgDimensions);
          console.log("after_change_lowest_side_dimension:", this.convertDimensions);

          this.ng2ImgMax.resizeImage(file, this.convertDimensions.w, this.convertDimensions.h).subscribe(
            result => {
              this.uploadedImage = result;
              console.log("after_resize_size_in_bytes", this.uploadedImage.size);
              const uploadedImagereader = new FileReader();
              uploadedImagereader.onload = (e: any) => {
                this.imageSrc6 = uploadedImagereader.result;
                this.update_images_array.splice(5, 1, uploadedImagereader.result);
                console.log(this.update_images_array);
              }
              uploadedImagereader.readAsDataURL(this.uploadedImage);
            },
            error => {
              console.log('😢 Oh no!', error);
            }
          );
        } else {
          this.showImageAcceptError();
        }
      }
      reader.readAsDataURL(file);
    }
  }

  getValues($event, title, productTitle, desc, keywords) {
    if (desc.value == "") {
      this.toastr.error("Description is blank");
    } else if (keywords.value == "") {
      this.toastr.error("Keywords is blank");
    }
    else {

      let data = {
        deleted_row_array: this.deleted_row_array,
        main_id: this.send_data.id,
        license_number: this.license_no,
        old_time_stamp: this.old_time_stamp,
        old_category: this.old_category,
        old_store_id: this.old_store_id,
        main_title: this.send_data.title,
        product_title: productTitle.value,
        title: title.value,
        description: desc.value,
        keyword: keywords.value,
        updated_product: this.fieldArray,
        image_name: this.update_images_array
      };
      console.log("send_datas", data);

      this.productsService.updatedistributorstrain(data).subscribe(res => {
        //console.log(res);
        this.update_strain_res = res;
        console.log("response_from_db", this.update_strain_res);
        this.toastMsg = res['msg'];
        if (res['status'] == "200") {
          this.toastr.success(this.toastMsg);
          setTimeout(() => {
            this.router.navigate(['existing']);

          },
            4000);
        }
        else if (res['status'] == "400") {
          this.toastr.error(this.toastMsg);
        }
      });
    }
  }
}
