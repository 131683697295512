import { Routes } from "@angular/router";
import { CusPurchaseHistoryComponent } from "../../cus-purchase-history/cus-purchase-history.component";
import { CusRfqsComponent } from "../../cus-rfqs/cus-rfqs.component";
import { CusDashboardComponent } from "../../cus-dashboard/cus-dashboard.component";
import { AuthGuardService as Auth } from '../../services/auth-guard.service';
import { CusProfileManagementComponent } from "../../cus-profile-management/cus-profile-management.component";
import { CusDeliveryAddressComponent } from "../../cus-delivery-address/cus-delivery-address.component";
import { CustomerBidRfqsComponent } from "../../customer-bid-rfqs/customer-bid-rfqs.component";

export const CUSTOM1_ROUTES: Routes = [
    { path: 'cus-dashboard', component: CusDashboardComponent, canActivate: [Auth] },
    { path: 'cus-purchase-history', component: CusPurchaseHistoryComponent, canActivate: [Auth] },
    { path: 'cus-rfqs', component: CusRfqsComponent, canActivate: [Auth] },
    { path: 'customer-bid-rfqs', component: CustomerBidRfqsComponent, canActivate: [Auth] },
    { path: 'cus-profile-management', component: CusProfileManagementComponent, canActivate: [Auth] },
    { path: 'cus-delivery-address', component: CusDeliveryAddressComponent, canActivate: [Auth] }

]
