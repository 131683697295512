import { Injectable } from '@angular/core';
import { Http, RequestOptions, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: Http) { }

  public for_addr="";//set store formatted address
  public strainurl = environment.apiBaseUrl + "/get_all_strain";
  public getStrainsListUrl = environment.apiBaseUrl + "/get_strains_list";
  public getSingleStrainDetailsUrl = environment.apiBaseUrl + "/get_single_strains_details";
  public get_filtered_cannabies_strainurl = environment.apiBaseUrl + "/get_filtered_cannabies_strain";
  public distributor_strain_url = environment.apiBaseUrl + "/set_distributor_strain";
  public get_distributor_strainurl = environment.apiBaseUrl + "/get_distributor_strain";
  public set_suppress_rowurl = environment.apiBaseUrl + "/set_suppress_row";
  public get_suppress_rowurl = environment.apiBaseUrl + "/get_suppress_row";
  public show_suppress_rowurl = environment.apiBaseUrl + "/show_suppress_row";
  public get_distributor_strain_for_updateurl = environment.apiBaseUrl + "/get_dis_strains_for_update";
  public update_distributor_strainurl = environment.apiBaseUrl + "/update_distributor_strain";
  public set_distributor_storeurl = environment.apiBaseUrl + "/set_distributor_store";
  public get_distributor_storeurl = environment.apiBaseUrl + "/get_distributor_store";
  public set_store_timingurl = environment.apiBaseUrl + "/set_store_timing";
  public get_store_timingforUpdateurl = environment.apiBaseUrl + "/get_store_time_for_update";
  public getStoreDetailsByCityNameUrl = environment.apiBaseUrl + "/get_all_distributor_store";
  public getSingleStoreDetailsUrl = environment.apiBaseUrl + "/get_single_distributor_store";
  public getStrainDetailsByLicenseUrl = environment.apiBaseUrl + "/get_strain_details_by_license";
  public getStoreDetailsByTitleUrl = environment.apiBaseUrl + "/get_store_details_by_title";
  public set_store_facilitiesurl = environment.apiBaseUrl + "/set_store_facilities";
  public get_store_facilitiesurl = environment.apiBaseUrl + "/get_store_facilities";
  public set_drop_locationurl = environment.apiBaseUrl + "/set_drop_location";
  public get_drop_locationurl = environment.apiBaseUrl + "/get_saved_drop_location";
  public getStoreNameurl = environment.apiBaseUrl + "/get_store_name";
  public getLineageByStrainShortNameUrl = environment.apiBaseUrl + "/get_lineage_of_strain";
  public getAllProductByIdNameUrl = environment.apiBaseUrl + "/get_distributor_strain_by_id";
  public getDistributorInfoByIdUrl = environment.apiBaseUrl + "/get_single_distributor_by_id";
  // public set_store_locationurl = environment.apiBaseUrl + "/set_store_location";
  //public get_store_coOrdinates = environment.apiBaseUrl + "/get_store_co_ordinates";
  public getDropLocationsByLicenseUrl = environment.apiBaseUrl + "/get_drop_locations_by_license";
  public getSimilarProductsByTitleUrl = environment.apiBaseUrl + "/get_similar_products_by_title";
  public getStoreIdByTitleUrl = environment.apiBaseUrl + "/get_store_id_by_title";
  public getStoreDetailByIdUrl=environment.apiBaseUrl + "/get_store_details_by_id";
  public getDataForBarcodeGenerationUrl=environment.apiBaseUrl + "/get_distributor_strain_for_barcode_generation";

  getAllProducts() {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.strainurl, options).pipe(map((res: Response) => res.json()));
  }

  getFilterdStrains(selectedCategory) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.get_filtered_cannabies_strainurl, { category: selectedCategory }, options).pipe(map((res: Response) => res.json()));
  }

  getStrainsList() {
    // list of strain for public view 
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.get(this.getStrainsListUrl, options).pipe(map((res: Response) => res.json()));
  }

  getSingleStrainDetails(name) {// details of a single strain 
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getSingleStrainDetailsUrl, { name: name }, options).pipe(map((res: Response) => res.json()));
  }

  setDistributorStrain(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.distributor_strain_url, data, options).pipe(map((res: Response) => res.json()));
  }

  getDistributorStrain(license_no) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    //let body = JSON.stringify(license_no);
    return this.http.post(this.get_distributor_strainurl, { license_no: license_no }, options).pipe(map((res: Response) => res.json()));
  }

  setSuppressrow(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.put(this.set_suppress_rowurl, body, options).pipe(map((res: Response) => res.json()));
  }

  getSuppressedRow(license_no) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.get_suppress_rowurl, { license_no: license_no }, options).pipe(map((res: Response) => res.json()));
  }

  showSuppressRow(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.put(this.show_suppress_rowurl, body, options).pipe(map((res: Response) => res.json()));
  }

  getdistributorstrainforupdate(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.get_distributor_strain_for_updateurl, body, options).pipe(map((res: Response) => res.json()));
  }

  updatedistributorstrain(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.update_distributor_strainurl, body, options).pipe(map((res: Response) => res.json()));
  }
  disStoreContent(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.set_distributor_storeurl, body, options).pipe(map((res: Response) => res.json()));
  }
  getdisStoreContent(license_no) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.get_distributor_storeurl, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  }
  setStoreTiming(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.set_store_timingurl, body, options).pipe(map((res: Response) => res.json()));
  }
  getStoreTimingforUpdate(license_no) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.get_store_timingforUpdateurl, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  }
  setStoreFacilities(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.set_store_facilitiesurl, body, options).pipe(map((res: Response) => res.json()));
  }
  setDropLocation(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.set_drop_locationurl, body, options).pipe(map((res: Response) => res.json()));
  }
  getDropLocation(data) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.get_drop_locationurl, body, options).pipe(map((res: Response) => res.json()));
  }

  // setStoreLocation(data) {
  //   var token = localStorage.getItem('CurrentToken');
  //   let access_token = JSON.parse(token);
  //   let headers = new Headers({ 'Content-Type': 'application/json' });
  //   headers.append('Authorization', 'Bearer ' + access_token['token']);
  //   let options = new RequestOptions({ headers: headers });
  //   let body = JSON.stringify(data);
  //   return this.http.post(this.set_store_locationurl, body, options).pipe(map((res: Response) => res.json()));
  // }
  getStoreFacilities(license_no) {
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.get_store_facilitiesurl, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  }

  getStoreDetailsByCityName(cityName) {//to get details of all stores by city name 
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getStoreDetailsByCityNameUrl, { city: cityName }, options).pipe(map((res: Response) => res.json()));
  }

  getSingleStoreDetails(storeName) {//to get details of single store by store name 
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getSingleStoreDetailsUrl, { store: storeName }, options).pipe(map((res: Response) => res.json()));
  }

  getStrainDetailsByLicense(license_no) {//to get details of strain by license number
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getStrainDetailsByLicenseUrl, { license_no: license_no }, options).pipe(map((res: Response) => res.json()));
  }

  getStoreDetailsByTitle(title) {//to get details of strain by license number
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getStoreDetailsByTitleUrl, { title: title }, options).pipe(map((res: Response) => res.json()));
  }

  getStoreName(license_no){
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getStoreNameurl, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  }

  // userDesignationCheck(license){
  //   var token = localStorage.getItem('CurrentToken');
  //   let access_token = JSON.parse(token);
  //   let headers = new Headers({ 'Content-Type': 'application/json' });
  //   headers.append('Authorization', 'Bearer ' + access_token['token']);
  //   let options = new RequestOptions({ headers: headers });
  //   return this.http.post(this.getStoreNameurl, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  // }
  getLineageByStrainShortName(shortName) {//to get lineage by strain short name
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getLineageByStrainShortNameUrl, { strainShortName: shortName }, options).pipe(map((res: Response) => res.json()));
  }
  getAllProductByIdName(id,title) {//to get all related products by id and title
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getAllProductByIdNameUrl, { product_title: title,store_id:id }, options).pipe(map((res: Response) => res.json()));
  }
  getDistributorInfoById(index) {//to get distributor info by license number
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getDistributorInfoByIdUrl, { id: index}, options).pipe(map((res: Response) => res.json()));
  }
  // getStoreCoOrdinates(license_no){
  //   var token = localStorage.getItem('CurrentToken');
  //   let access_token = JSON.parse(token);
  //   let headers = new Headers({ 'Content-Type': 'application/json' });
  //   headers.append('Authorization', 'Bearer ' + access_token['token']);
  //   let options = new RequestOptions({ headers: headers });
  //   return this.http.post(this.get_store_coOrdinates, { license_number: license_no }, options).pipe(map((res: Response) => res.json()));
  // }
  getDropLocationsByLicense(license) { //get all drop locations by license number
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getDropLocationsByLicenseUrl, { license: license}, options).pipe(map((res: Response) => res.json()));
  }
  getSimilarProductsByTitle(title) { //get all related products by title
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getSimilarProductsByTitleUrl, { title: title}, options).pipe(map((res: Response) => res.json()));
  }
  getStoreIdByTitle(title) { //get all store ids by title
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.getStoreIdByTitleUrl, { title: title}, options).pipe(map((res: Response) => res.json()));
  }
  getStoreDetailById(storeIdsArr) { //get all store detail by id
    let headers = new Headers({ 'Content-Type': 'application/json' });
    let options = new RequestOptions({ headers: headers });
    let body=JSON.stringify(storeIdsArr);
    return this.http.post(this.getStoreDetailByIdUrl, { storeIds: body}, options).pipe(map((res: Response) => res.json()));
  }
  getDataForBarcodeGeneration(data){
    var token = localStorage.getItem('CurrentToken');
    let access_token = JSON.parse(token);
    let headers = new Headers({ 'Content-Type': 'application/json' });
    headers.append('Authorization', 'Bearer ' + access_token['token']);
    let options = new RequestOptions({ headers: headers });
    let body = JSON.stringify(data);
    return this.http.post(this.getDataForBarcodeGenerationUrl, body, options).pipe(map((res: Response) => res.json()));
  }
}
